import { MAD } from '../../../../index';
import { NProgram } from '../../../dataStore/types';

export const anthemFetchOptions = (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body?: object
): RequestInit => ({
  method,
  credentials: 'include',
  headers: {
    'Content-Type': body ? 'application/json' : '',
  },
  body: JSON.stringify(body),
});

export const anthemApi = {
  anthemAuthentication: () => {
    return fetch(`${process.env.REACT_APP_ROOT_DOMAIN}music/api/authentication/token`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ workgroupId: MAD.workgroupId, userId: MAD.userId }),
    });
  },
  anthemChangeWorkgroup: (newWorkgroupId: number) => {
    if (newWorkgroupId) {
      return fetch(
        `${process.env.REACT_APP_ROOT_DOMAIN}music/api/authentication/workgroup/${newWorkgroupId}`,
        anthemFetchOptions('POST')
      );
    }
  },
  anthemGetAllPrograms: async (): Promise<NProgram[]> => {
    const result = await fetch(
      `${process.env.REACT_APP_ROOT_DOMAIN}music/api/program`,
      anthemFetchOptions('GET')
    );
    return result.json();
  },
  anthemGetMediaLibrary: async (): Promise<Array<{ programId: number; assetId: number }>> => {
    const result = await fetch(
      `${process.env.REACT_APP_ROOT_DOMAIN}music/api/library/programs`,
      anthemFetchOptions('GET')
    );
    return result.json();
  },
  assignStationToDevice: async (
    playerId: number,
    stationId: number,
    serial: string,
    channelId: number
  ): Promise<void> => {
    const result = await fetch(
      `${process.env.REACT_APP_ROOT_DOMAIN}music/api/device/station`,
      anthemFetchOptions('PUT', {
        playerId,
        stationId,
        serial,
        channelId,
      })
    );

    if (!result.ok) {
      throw new Error('Could not assign station to device');
    }
  },

  assignZonePlaylistToDevice: async (
    playerId: number,
    leftZone: number,
    leftZonePlaylist: number,
    rightZone: number,
    rightZonePlaylist: number,
    serial: string
  ): Promise<void> => {
    const result = await fetch(
      `${process.env.REACT_APP_ROOT_DOMAIN}music/api/device/assignPlaylistToZones`,
      anthemFetchOptions('PUT', {
        playerId,
        leftZone,
        leftZonePlaylist,
        rightZone,
        rightZonePlaylist,
        serial,
      })
    );

    if (!result.ok) {
      throw new Error('Could not assign station to device');
    }
  },
};
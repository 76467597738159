import { NewnityEntityType } from 'app/dataStore/types';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  withRegisterBlade,
  useDefaultDeviceSettingFns,
  deviceSettingsActions,
  EntityType,
} from 'react-tools';

import { DataStoreActions, DataStoreSelectors, NCompany } from '../../../../dataStore';
import { cleanupCompanyEdit } from '../../../duck/actions';
import {
  selectCompanyDataIsFetching,
  selectCompanyId,
  selectCompanySavingState,
  selectDefaultParentWorkgroupId,
  selectParentWorkgroupIsFetching,
} from '../../../duck/selectors';
import { saveCompanyInfo } from '../../../duck/thunks';
import { CompanyInfo } from './company';

interface CompanyContainerProps {
  title: string;
}

const getEmptyCompany = (): NCompany => ({
  id: 0,
  name: '',
  number: '',
  parentId: selectDefaultParentWorkgroupId(),
  country: '',
  city: '',
  state: '',
  zipCode: '',
  streetAddress: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  templateLibraries: [],
});

const Container = ({ title, ...bladeProps }: CompanyContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => selectCompanyId(state));
  const isFetching = useSelector(
    (state) => selectCompanyDataIsFetching(state) || selectParentWorkgroupIsFetching(state)
  );
  const savingData = useSelector(selectCompanySavingState);

  let company: NCompany | null = useSelector((state) =>
    companyId ? DataStoreSelectors.NCompany.selectCompanyById(state, companyId) : null
  );

  if (companyId) {
    if (!company) {
      company = getEmptyCompany();
    }
  } else {
    company = getEmptyCompany();
  }

  const clearDeviceSettings = () => {
    dispatch(deviceSettingsActions.clearDeviceSettings());
  };

  const closeBlade = useCallback(
    (bladeId: string) => {
      dispatch<any>(BladeActions.closeBlade(bladeId));
      dispatch(cleanupCompanyEdit());
    },
    [dispatch]
  );

  const saveCompany = useCallback(
    (companyData: NCompany) => dispatch<any>(saveCompanyInfo(companyData)),
    []
  );

  const upsertEntity = useCallback(
    (company: NCompany) =>
      dispatch<any>(DataStoreActions.entityUpsert(NewnityEntityType.NCompany, company)),
    []
  );

  const { fetchDevicesSettings, openDeviceSettings } = useDefaultDeviceSettingFns(
    bladeProps.bladeId,
    company.parentId
      ? {
          entityId: company.parentId,
          entityType: EntityType.Workgroup,
        }
      : null
  );

  const openDeviceSettingsBlade = useCallback(
    () => dispatch(BladeActions.closeChildrenBlades(bladeProps.bladeId, openDeviceSettings)),
    [openDeviceSettings, bladeProps.bladeId]
  );

  return (
    <CompanyInfo
      company={company}
      isFetching={isFetching}
      savingData={savingData}
      closeBlade={closeBlade}
      saveCompany={saveCompany}
      upsertEntity={upsertEntity}
      fetchDevicesSettings={fetchDevicesSettings}
      openDeviceSettings={openDeviceSettingsBlade}
      clearDeviceSettings={clearDeviceSettings}
      {...bladeProps}
    />
  );
};

export const CompanyBladeType = 'COMPANY_INFO';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 800 },
  bladeType: CompanyBladeType,
  id: () => CompanyBladeType,
  title: (ownProps: CompanyContainerProps & BladeProps) => ownProps.title,
  allowMultipleInstances: false,
};

export const CompanyBladeContainer = withRegisterBlade<CompanyContainerProps & BladeProps>(
  bladeConfig
)(Container);

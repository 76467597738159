import { DataStoreSelectors, EntityType, Visual } from 'app/dataStore';
import { deleteVisual, fetchVisuals } from 'app/newnity/duck/thunks';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  BladeSelectors,
  openBlade,
  withRegisterBlade,
} from 'react-tools';

import { selectIsEditingChannel } from '../../../duck/selectors';
import { VisualsEditBladeType } from '../edit/visualsEditContainer';
import { VisualsList } from './visualsList';

interface VisualsListContainerProps {
  workgroupId: number;
  workgroupName: string;
}

export const VisualsListBladeType = 'NEWNITY_VISUALS_LIST';

const bladeConfig = {
  size: { defaultWidth: 520, minWidth: 320 },
  bladeType: VisualsListBladeType,
  id: (_: any) => VisualsListBladeType,
  title: (_: any) => i18next.t('newnity.visuals.list.blade.title'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroupId,
  workgroupName,
  ...bladeProps
}: VisualsListContainerProps & BladeProps) => {
  const dispatch = useDispatch();
  const visuals = useSelector(DataStoreSelectors.ChannelSelectors.selectVisualItems);
  const fetchStatus = useSelector((state) =>
    DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.Channel)
  );

  const isEditing = useSelector(selectIsEditingChannel);

  const fetchVisualsForCompany: (workgroupId: number) => any = useCallback(
    (workgroupId: number) => dispatch<any>(fetchVisuals(workgroupId)),
    [workgroupId, dispatch]
  );

  const createVisual = useCallback(
    () =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, VisualsEditBladeType, {
            visualId: 0,
            visualName: '',
            companyId: workgroupId,
            companyName: workgroupName,
          })
        )
      ),
    [dispatch, workgroupId, workgroupName]
  );

  const editVisual = useCallback(
    (visualId: number, visualName: string, workgroupId: number) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, VisualsEditBladeType, {
            visualId,
            visualName,
            companyId: workgroupId,
          })
        )
      ),
    [dispatch, workgroupId]
  );

  const deleteVisualById = useCallback(
    (visualId: number, visualName: string) => dispatch<any>(deleteVisual(visualId, visualName)),
    [dispatch]
  );

  return (
    <VisualsList
      workgroupId={workgroupId}
      visuals={visuals as Visual[]}
      fetchStatus={fetchStatus}
      createVisual={createVisual}
      editVisual={editVisual}
      fetchVisuals={fetchVisualsForCompany}
      isEditing={isEditing}
      deleteVisual={deleteVisualById}
    />
  );
};

export const VisualsListContainer = withRegisterBlade<VisualsListContainerProps & BladeProps>(
  bladeConfig
)(ContainerComponent);

import { Schedule } from 'app/newnity/duck/types';

import { ProgramScheduleProps } from './programSchedule';

export const mapScheduleToProps = (schedule: Schedule): ProgramScheduleProps => {
  return {
    startDate: new Date(schedule.startDate),
    endDate: new Date(schedule.endDate),
    startTime: {
      hour: schedule.startTime.getHours(),
      minute: schedule.startTime.getMinutes(),
      second: schedule.startTime.getSeconds(),
    },
    endTime: {
      hour: schedule.endTime.getHours(),
      minute: schedule.endTime.getMinutes(),
      second: schedule.endTime.getSeconds(),
    },
    days: {
      monday: (schedule.diffusionDays & 64) !== 0,
      tuesday: (schedule.diffusionDays & 32) !== 0,
      wednesday: (schedule.diffusionDays & 16) !== 0,
      thursday: (schedule.diffusionDays & 8) !== 0,
      friday: (schedule.diffusionDays & 4) !== 0,
      saturday: (schedule.diffusionDays & 2) !== 0,
      sunday: (schedule.diffusionDays & 1) !== 0,
    },
    originalSchedule: schedule,
  };
};

export const mapPropsToSchedule = (props: ProgramScheduleProps): Schedule => {
  const startTime = new Date(props.originalSchedule.startTime);
  startTime.setHours(props.startTime.hour);
  startTime.setMinutes(props.startTime.minute);
  startTime.setSeconds(props.startTime.second);

  const endTime = new Date(props.originalSchedule.endTime);
  endTime.setHours(props.endTime.hour);
  endTime.setMinutes(props.endTime.minute);
  endTime.setSeconds(props.endTime.second);

  let diffusionDays = 127;
  
  if (!props.days.monday) {
    diffusionDays = diffusionDays & ~(64);
  }

  if (!props.days.tuesday) {
    diffusionDays = diffusionDays & ~(32);
  }

  if (!props.days.wednesday) {
    diffusionDays = diffusionDays & ~(16);
  }

  if (!props.days.thursday) {
    diffusionDays = diffusionDays & ~(8);
  }

  if (!props.days.friday) {
    diffusionDays = diffusionDays & ~(4);
  }

  if (!props.days.saturday) {
    diffusionDays = diffusionDays & ~(2);
  }

  if (!props.days.sunday) {
    diffusionDays = diffusionDays & ~(1);
  }

  return {
    id: props.originalSchedule.id,
    name: props.originalSchedule.name,
    startDate: props.startDate,
    endDate: props.endDate,
    startTime,
    endTime,
    diffusionDays,
    channelMediaRestrictions: []
  };
};
import './localization/i18n';

import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import {
  bladeAreaName,
  bladeReducer,
  notificationsAreaName,
  notificationsReducer,
  deviceSettingsTypes,
  deviceSettingsReducer,
} from 'react-tools';
import { applyMiddleware, combineReducers, createStore, Unsubscribe } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import * as App from './app';
import { initObservers as initNewnityObservers } from './app/stateObservers/newnity';
import IMVisionAdmin from './app/v6/types';
import * as serviceWorker from './serviceWorker';

const DEV_ENV_NEWNITY_ENABLED = true;

const createDefaultStore = (newnityEnabled: boolean) => {
  App.Newnity.setInitialState(newnityEnabled);

  return createStore(
    combineReducers({
      [App.DataStore.areaName]: App.DataStore.reducer,
      [App.Newnity.areaName]: App.Newnity.reducer,
      [App.Users.areaName]: App.Users.reducer,
      [notificationsAreaName]: notificationsReducer,
      [bladeAreaName]: bladeReducer,
      [deviceSettingsTypes.areaName]: deviceSettingsReducer,
    }),
    composeWithDevTools(applyMiddleware(thunk))
  );
};

const defaultStore = createDefaultStore(DEV_ENV_NEWNITY_ENABLED);
let newnityUnsubscribe: Unsubscribe;

export const MAD: IMVisionAdmin = {
  open: (rootNodeId: string = 'root', newnityEnabled: boolean = false) => {
    const store = createDefaultStore(newnityEnabled);
    startReact(rootNodeId, store);
  },
  unmount: (rootNodeId: string) => {
    unmountComponentAtNode(document.getElementById(rootNodeId) as Element);
  },
  homeFn: () => {},
  userId: 2500,
  workgroupId: 7047,
};

// wrapper for ReactDOM.render() call
const startReact = (rootNode = 'root', appStore = defaultStore) => {
  // unsubscribe from state listeners just in case...
  if (newnityUnsubscribe) {
    newnityUnsubscribe();
  }

  newnityUnsubscribe = initNewnityObservers(appStore);
  ReactDOM.render(
    <Provider store={appStore}>
      <App.AppComponent />
    </Provider>,
    document.getElementById(rootNode)
  );
};

// we can remove this after we migrate mvision-html/MVision.UI to webpack
(() => {
  interface IWindow extends Window {
    mvision: { admin: { MAD_REQUIRED: boolean } };
    MAD: IMVisionAdmin;
  }

  const localWindow = window as any;

  const setGlobalMAD = () => {
    // set AP as a global property
    localWindow.MAD = MAD;
  };

  const onDOMContentLoaded = (event: Event) => {
    // remove the event listener
    document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);

    const mvisionLoaded: boolean =
      typeof localWindow.mvision === 'object' &&
      typeof localWindow.mvision.admin === 'object' &&
      localWindow.mvision.admin.MAD_REQUIRED;

    if (!mvisionLoaded) {
      // fallback to the "natural" react code
      startReact();

      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: http://bit.ly/CRA-PWA
      serviceWorker.unregister();
      return;
    }

    setGlobalMAD();
  };

  /**
   * If the document and the mvision.services namespace are already loaded
   * we set the global AP object...
   */
  if (document.readyState === 'complete') {
    const mvisionLoaded: boolean =
      typeof localWindow.mvision === 'object' &&
      typeof localWindow.mvision.admin === 'object' &&
      localWindow.mvision.admin.MAD_REQUIRED;

    if (mvisionLoaded) {
      setGlobalMAD();
    }
  } else {
    // ...otherwise we listen for the document to be ready
    document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }
})();

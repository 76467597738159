import { createSelector } from 'reselect';

import { NewnityEntityType, NDeviceSearchResult } from '../types';
import { getDataStore } from './genericSelectors';

export const selectSearchDevices = (state: any) =>
  getDataStore(state)[NewnityEntityType.NDeviceSearchResult];

export const selecSearchDeviceItems = createSelector(
  [selectSearchDevices],
  devices => {
    return Object.keys(devices.items).map<NDeviceSearchResult>(
      key => devices.items[Number(key)]
    );
  }
);

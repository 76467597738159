import { createSelector } from 'reselect';

import { NormalizedIdNames } from '../';
import { denormalize, Normalized } from '../../utils/extensions';
import { areaName, DataStoreState, NewnityEntityType, IdName } from '../types';

export const getDataStore = (state: any) => state[areaName] as DataStoreState;

export const getDataStoreItems = (state: any, entityType: NewnityEntityType) => {
  return (state[areaName] as DataStoreState)[entityType as keyof DataStoreState]
    .items as NormalizedIdNames<IdName>;
};

export const getDataStoreCollection = (entityType: NewnityEntityType) => {
  return createSelector([getDataStore], (state: DataStoreState) =>
    denormalize<IdName>(state[entityType as keyof DataStoreState].items as Normalized<IdName>)
  );
};

export const getDataStoreCollectionStatus = (entityType: NewnityEntityType) => {
  return createSelector<any, DataStoreState, boolean>(
    [getDataStore],
    (state: DataStoreState) => state[entityType as keyof DataStoreState].itemsFetching.isFetching
  );
};

export const getDataStoreItem = (state: any, entityType: NewnityEntityType, entityId: number) => {
  return getDataStoreItems(state, entityType)[entityId];
};

export const getDataStoreItemsFetching = (state: any, entityType: NewnityEntityType) => {
  const key = entityType as keyof DataStoreState;
  if ((state[areaName] as DataStoreState)[key]) {
    return (state[areaName] as DataStoreState)[key].itemsFetching;
  } else {
    return {
      isFetching: false,
      error: '',
      fetchComplete: true,
    };
  }
};

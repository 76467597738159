import React, { useCallback } from 'react';
import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import {
  BladeActions,
  BladeProps,
  BladeSelectors,
  openBlade,
  withRegisterBlade,
} from 'react-tools';
import { selectCompanyId, selectIsEditingChannel } from '../../../duck/selectors';
import { ZoneEditBladeType } from '../edit/zoneEditContainer';
import { ZoneList } from './zoneList';
import { anthemChangeWorkgroup, deleteZone, fetchZones } from 'app/newnity/duck/thunks';

interface ZoneListContainerProps {
  workgroupId: number;
  workgroupName: string;
}

export const ZoneListBladeType = 'NEWNITY_ZONE_LIST';

const bladeConfig = {
  size: { defaultWidth: 520, minWidth: 320 },
  bladeType: ZoneListBladeType,
  id: (_: any) => ZoneListBladeType,
  title: (_: any) => i18next.t('newnity.zone.list.blade.title'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroupId,
  workgroupName,
  ...bladeProps
}: ZoneListContainerProps & BladeProps) => {
  const dispatch = useDispatch();
  const zones = useSelector(DataStoreSelectors.ChannelSelectors.selectZoneItems);
  const fetchStatus = useSelector((state) =>
    DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.Channel)
  );

  const isEditing = useSelector(selectIsEditingChannel);
  const companyId = useSelector(selectCompanyId);

  const fetchZonesForCompany: (workgroupId: number) => any = useCallback(
    (workgroupId: number) => dispatch<any>(fetchZones(workgroupId)),
    [workgroupId, dispatch]
  );

  const createZone = useCallback(
    () =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, ZoneEditBladeType, {
            zoneId: 0,
            zoneName: '',
            companyId: workgroupId,
            workgroupId,
            companyName: workgroupName,
          })
        )
      ),
    [dispatch, workgroupId, workgroupName]
  );

  const editZone = useCallback(
    (zoneId: number, zoneName: string, workgroupId: number) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, ZoneEditBladeType, {
            zoneId,
            zoneName,
            companyId,
            workgroupId,
          })
        )
      ),
    [dispatch, workgroupId]
  );

  const deleteZoneById = useCallback(
    (zoneId: number, zoneName: string) =>
      dispatch<any>(deleteZone(zoneId, zoneName, bladeProps.bladeId)),
    [dispatch]
  );

  const onBladeClose = useCallback(() => {
    dispatch(anthemChangeWorkgroup(companyId));
  }, [dispatch]);

  return (
    <ZoneList
      workgroupId={workgroupId}
      zones={zones}
      fetchStatus={fetchStatus}
      createZone={createZone}
      editZone={editZone}
      fetchZones={fetchZonesForCompany}
      isEditing={isEditing}
      deleteZone={deleteZoneById}
      onBladeClose={onBladeClose}
      {...bladeProps}
    />
  );
};

export const ZoneListContainer = withRegisterBlade<ZoneListContainerProps & BladeProps>(
  bladeConfig
)(ContainerComponent);

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deviceSettingsActions } from 'react-tools';

export const useSetEditableDeviceSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deviceSettingsActions.setAcceptedSettings(editableDeviceSettings));
  }, []);
};

export const editableDeviceSettings: string[] = [
  'playbackSettings.audioSettings.volumePercentFactorZoneLeft',
  'playbackSettings.audioSettings.volumePercentFactorZoneRight',
  'soundSettings.volumeLevels',
  'networkSettings.wifiSettings.allowConfigurationChange',
  'networkSettings.wifiSettings.wifiAccessPointSettings.ssid',
  'networkSettings.wifiSettings.wifiAccessPointSettings.password',
  'networkSettings.wifiSettings.wifiAccessPointSettings.authenticationType',
  'networkSettings.wifiSettings.dhcpEnabled',
  'networkSettings.wifiSettings.networkLinkSettings.dns1',
  'networkSettings.wifiSettings.networkLinkSettings.dns2',
  'networkSettings.wifiSettings.networkLinkSettings.gateway',
  'networkSettings.wifiSettings.networkLinkSettings.ipAddress',
  'networkSettings.wifiSettings.networkLinkSettings.netmask',
  'networkSettings.wifiSettings.interfaceEnabled',
  'networkSettings.wifiSettings.vlanConfigurations',
  'networkSettings.httpProxySettings.port',
  'networkSettings.httpProxySettings.host',
  'networkSettings.httpProxySettings.proxyEnabled',
  'networkSettings.httpProxySettings.excludedHostsList',
  'networkSettings.httpProxySettings.password',
  'networkSettings.httpProxySettings.username',
  'networkSettings.ethernetSettings.dhcpEnabled',
  'networkSettings.ethernetSettings.vlanConfigurations',
  'networkSettings.ethernetSettings.allowConfigurationChange',
  'networkSettings.ethernetSettings.interfaceEnabled',
  'networkSettings.ethernetSettings.networkLinkSettings.dns1',
  'networkSettings.ethernetSettings.networkLinkSettings.dns2',
  'networkSettings.ethernetSettings.networkLinkSettings.gateway',
  'networkSettings.ethernetSettings.networkLinkSettings.ipAddress',
  'networkSettings.ethernetSettings.networkLinkSettings.netmask',
  'networkSettings',
  'wifiSettings',
  'httpProxySettings',
  'ethernetSettings',
];

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    headerButton: {
      marginRight: theme.spacing(1),
    },
    headerButtonIconSmall: {
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
    loadingContainer: {
      position: 'absolute',
      height: '100%',
      width: '100%',
    },
    loading: {
      margin: 'auto',
    },
  });

export const useStyles = makeStyles(styles);

import React from 'react';

import { Tab } from '@material-ui/core';
import { CompanySearchFields } from 'app/newnity/duck/types';
import { useTranslation } from 'react-i18next';
import { TabLabel } from '../tabLabel';
import { useStyles } from '../tabs.jss';
import {
  countNonEmptyAddressFields,
  countNonEmptyCompanyInfoFields,
  countNonEmptyPhoneFields,
} from '../utils';

interface CompanyTabLabelsProps {
  company: CompanySearchFields;
  currentTabIndex: number;
  onTabChanged: (event: React.ChangeEvent<any>, newTabIndex: number) => void;
}

export const CompanyTabLabels = (props: CompanyTabLabelsProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <Tab
        value={0}
        onChange={props.onTabChanged}
        selected={props.currentTabIndex === 0}
        key="company.info"
        classes={{
          root: classes.tabRoot,
          selected: classes.tabFirstSelected,
          wrapper: classes.tabWrapper,
        }}
        label={
          <TabLabel
            label={t<string>('newnity.company')}
            numberOfNonEmptyFields={countNonEmptyCompanyInfoFields(props.company)}
          />
        }
      />
      <Tab
        value={1}
        onChange={props.onTabChanged}
        selected={props.currentTabIndex === 1}
        key="company.address"
        classes={{
          root: classes.tabRoot,
          selected: classes.tabSelected,
          wrapper: classes.tabWrapper,
        }}
        label={
          <TabLabel
            label={t<string>('newnity.search.tab.company.address')}
            numberOfNonEmptyFields={countNonEmptyAddressFields(props.company)}
          />
        }
      />
      <Tab
        value={2}
        onChange={props.onTabChanged}
        selected={props.currentTabIndex === 2}
        key="company.phone"
        classes={{
          root: classes.tabRoot,
          selected: classes.tabLastSelected,
          wrapper: classes.tabWrapper,
        }}
        label={
          <TabLabel
            label={t<string>('newnity.phone')}
            numberOfNonEmptyFields={countNonEmptyPhoneFields(props.company)}
          />
        }
      />
    </>
  );
};

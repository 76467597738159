import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeSelectors, denormalize, openBlade } from 'react-tools';

import { DataStoreSelectors, EntityType, IdName } from '../../../../dataStore';
import { entityListClear } from '../../../../dataStore/actions';
import { setInCompanyMode, setSelectedDevices, setSelectedLocations } from '../../../duck/actions';
import { openCompanyEditBlade, setCurrentCompany } from '../../../duck/thunks';
import { SearchState } from '../../../duck/types';
import { SearchBladeType } from '../searchContainer';
import { SearchResults, SearchResultsActions, SearchResultsProps } from './results';

const mapStateToProps: MapStateToProps<SearchResultsProps, any, any> = (state) => {
  const searchedEntity = (state.newnity.search as SearchState).searchedEntity;

  if (!searchedEntity) {
    return {
      isFetching: false,
      items: [],
    };
  }

  let items: IdName[] = [];

  switch (searchedEntity) {
    case EntityType.NCompanySearchResult:
      items = DataStoreSelectors.NSearchCompanyResult.selecSearchCompanyItems(state);
      break;
    case EntityType.NDeviceSearchResult:
      items = DataStoreSelectors.NDeviceSearchResult.selecSearchDeviceItems(state);
      break;
    case EntityType.NLocationSearchResult:
      items = DataStoreSelectors.NLocationSearchResult.selectSearchLocationItems(state);
      break;
  }

  const searchBlade = denormalize(BladeSelectors.selectActiveBlades(state)).find(
    (blade) => blade.id.indexOf(SearchBladeType) !== -1
  );

  return {
    searchedEntity,
    isFetching: DataStoreSelectors.getDataStoreItemsFetching(state, searchedEntity).isFetching,
    items,
    searchBladeId: searchBlade ? searchBlade.id : '',
  };
};

const mapDispatchToProps: MapDispatchToProps<SearchResultsActions, any> = (dispatch) => {
  return {
    enterCompanyMode: (id, searchBladeId) => {
      dispatch<any>(setCurrentCompany(id));
      dispatch(entityListClear(EntityType.Zone));
      dispatch(setInCompanyMode(true));
      dispatch(BladeActions.closeBlade());
    },
    openCompanyEditBlade: (id, name) => dispatch<any>(openCompanyEditBlade(id, name)),
    openBlade: (parent, type, props) => dispatch(openBlade(parent, type, props)),
    setSelectedLocations: (items) => dispatch(setSelectedLocations(items)),
    setSelectedDevices: (items) => dispatch(setSelectedDevices(items)),
  };
};

export const SearchResultsContainer = connect(mapStateToProps, mapDispatchToProps)(SearchResults);

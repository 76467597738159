import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BladeProps, Footer, FooterSubmit, useBladeClosing } from 'react-tools';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';

import { GroupBox } from '../../../../components/groupBox/groupBox';
import { IdName, NLocation, NStation, Zone } from '../../../../dataStore';
import { DataItemsFetchStatus, NProgram } from '../../../../dataStore/types';
import { FetchState } from '../../../duck/types';
import { useStyles } from './zoneEdit.jss';

import FadeIn from 'react-fade-in';
import { isEqual } from 'lodash';

export interface SlotAssetMapping {
  [slotLocalId: number]: NProgram | undefined;
}

export interface ZoneEditProps {
  companyId: number;
  companyName: string;
  zone: Zone;
  locations: NLocation[];
  stations: NStation[];
  savingData: FetchState;
  fetchingData: FetchState;
  fetchingLocations: DataItemsFetchStatus;
  editBladeId?: string;
  stationEditBladeId?: string;
}

export interface ZoneEditActions {
  createZone: (zone: Zone) => void;
  fetchZone: (zoneId: number) => void;
  closeBlade: () => void;
  fetchLocations: (workgroupId: number) => void;
  createStation: (zoneId: number, editBladeId?: string) => void;
  editStation: (id: number, name: string, editBladeId?: string) => void;
  deleteStation: (
    stationId: number,
    stationName: string,
    companyId: number,
    stationEditBladeId?: string
  ) => void;
}

type Props = ZoneEditProps & ZoneEditActions & BladeProps;

export const ZoneEdit: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [zoneName, setZoneName] = useState(props.zone.name);
  const [zoneWorkgroup, setZoneWorkgroup] = useState<IdName>({
    id: Number(props.zone.workgroupId),
    name: props.zone.workgroupName,
  });
  const [locationLevelEnabled, enableLocationLevel] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<NLocation | undefined>();
  const createMode = props.zone.id === 0;
  const [submitPressed, setSubmitPressed] = useState(false);
  const { zone, stations, fetchZone, setDirty, savingData, locations } = props;

  // load zone data when blade opens
  useEffect(() => {
    if (props.zone.id) {
      fetchZone(props.zone.id);
    }
  }, [props.zone.id]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  const locationLevelToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      enableLocationLevel(checked);
      setSubmitPressed(false);
      if (!checked) {
        setSelectedLocation(undefined);
        setZoneWorkgroup({
          id: props.companyId,
          name: props.companyName,
        });
        return;
      }
      if (
        checked &&
        !(props.fetchingLocations.fetchComplete || props.fetchingLocations.isFetching)
      ) {
        props.fetchLocations(zone.workgroupId);
      }
    },
    [enableLocationLevel]
  );

  useEffect(() => {
    if (savingData.fetchCompleted && savingData.fetchError === '') {
      setDirty(false);
    }
  }, [savingData.fetchError, savingData.fetchCompleted]);

  //TODO: dirty
  useEffect(() => {
    if (
      props.fetchingData.isFetching ||
      props.savingData.isFetching ||
      props.savingData.fetchCompleted
    ) {
      return;
    }

    const nextDirty = !isEqual(
      {
        zoneName,
      },
      {
        zoneName: props.zone.name,
      }
    );

    if (nextDirty !== props.isDirty) {
      setDirty(nextDirty);
    }
  }, [
    zoneName,
    props.zone.name,
    props.isDirty,
    props.fetchingData.isFetching,
    props.savingData.isFetching,
    props.savingData.fetchCompleted,
    setDirty,
  ]);

  const isLocationValid = useCallback(() => {
    if (!locationLevelEnabled) {
      return true;
    }

    return !!selectedLocation;
  }, [locationLevelEnabled, selectedLocation]);

  const canSaveZone = useCallback(() => zoneName && isLocationValid(), [zoneName, isLocationValid]);

  const onSubmit = () => {
    if (canSaveZone()) {
      props.createZone({
        ...zone,
        name: zoneName,
        workgroupId: zoneWorkgroup.id,
        workgroupName: zoneWorkgroup.name,
      });
    } else {
      setSubmitPressed(true);
    }
  };

  const onStationEdit = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const stationId = Number(e.currentTarget.getAttribute('data-id'));
      const station = stations.find((s) => s.id === stationId);
      if (station) {
        props.editStation(station.id, station.name, props.editBladeId);
      }
    },
    [stations, props.editBladeId]
  );

  const onStationCreate = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      props.createStation(props.zone.id, props.editBladeId);
    },
    [props.editBladeId]
  );
  const onStationDelete = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const stationId = Number(e.currentTarget.getAttribute('data-id'));
      const station = stations.find((s) => s.id === stationId);
      if (station && !station.isDefault) {
        props.deleteStation(station.id, station.name, props.companyId, props.stationEditBladeId);
      }
    },
    [stations, props.companyId, props.stationEditBladeId]
  );

  const onZoneNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitPressed(false);
    setZoneName(e.currentTarget.value);
  }, []);

  const onZoneLocationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSubmitPressed(false);
      const id = Number(e.target.value);
      const location = locations.find((l: NLocation) => l.workgroupId === id);
      setSelectedLocation(location);
      if (location) {
        setZoneWorkgroup({ id, name: location.name });
      }
    },
    [locations]
  );

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <div className={classes.form}>
          <div className={classes.formContent}>
            <GroupBox title={t('newnity.edit.zone.blade.create.header')} direction="column">
              <TextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                label={t('newnity.edit.zone.blade.create.name')}
                value={zoneName}
                onChange={onZoneNameChange}
                margin="normal"
                className={classes.field}
                error={submitPressed && !zoneName}
                helperText={
                  submitPressed && !zoneName && t('newnity.edit.zone.blade.create.nameError')
                }
                inputProps={{ maxLength: 190 }}
              />
              {!zone.id ? (
                <>
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          onChange={locationLevelToggle}
                          checked={locationLevelEnabled}
                          inputProps={{
                            name: 'changeLogin',
                            id: 'changeLogin',
                          }}
                        />
                        {props.fetchingLocations.isFetching && (
                          <CircularProgress variant="indeterminate" />
                        )}
                      </>
                    }
                    label={t('newnity.edit.zone.blade.saveAtLocationLevel')}
                  />
                  {locationLevelEnabled && props.fetchingLocations.fetchComplete && (
                    <TextField
                      variant="filled"
                      label={t<string>('newnity.edit.zone.blade.locationLabel')}
                      select
                      value={zoneWorkgroup.id}
                      error={submitPressed && !isLocationValid()}
                      helperText={
                        submitPressed &&
                        !isLocationValid() &&
                        t('newnity.edit.zone.blade.create.locationError')
                      }
                      onChange={onZoneLocationChange}
                    >
                      {locations.map((l: NLocation) => (
                        <MenuItem key={l.id} value={l.workgroupId}>
                          <span className={classes.locationNumber}>{l.oracleNumber}</span>
                          <span className={classes.locationName}>{l.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </>
              ) : (
                <></>
              )}
            </GroupBox>
            {zone.id ? (
              <GroupBox
                title={t('newnity.edit.zone.blade.playlists')}
                headerContent={
                  <IconButton
                    className={classes.stationAddButton}
                    onClick={onStationCreate}
                    color="secondary"
                  >
                    <AddIcon />
                  </IconButton>
                }
              >
                {!props.fetchingData.isFetching && (
                  <List className={classes.stationList}>
                    {stations.map((s: any) => (
                      <ListItem data-id={s.id} divider key={s.id} button onClick={onStationEdit}>
                        <ListItemIcon>{s.isDefault && <StarIcon />}</ListItemIcon>
                        <ListItemText primary={s.name} />
                        {
                          !s.isDefault && 
                          <ListItemSecondaryAction>
                            <IconButton data-id={s.id} onClick={onStationDelete}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        }
                      </ListItem>
                    ))}
                  </List>
                )}
              </GroupBox>
            ) : (
              <></>
            )}
          </div>
          <Footer>
            <FooterSubmit
              hasCancelButton={true}
              cancelButtonLabel={t('footer.cancel')}
              cancel={props.closeBlade}
              submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
              submit={onSubmit}
              submitInProgress={props.savingData.isFetching}
            />
          </Footer>
        </div>
      </FadeIn>
    </div>
  );
};

import { NewnityApi } from 'app/newnity/duck/api';
import i18n from 'i18next';
import { Dispatch } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  BladeSelectors,
  openBlade,
  withRegisterBlade,
} from 'react-tools';

import { DataStoreSelectors, EntityType, Zone } from '../../../../dataStore';
import { stationStartEdit } from '../../../duck/actions';
import {
  selectCompanyId,
  selectCreatingSavingChannelData,
  selectEditingSavingChannelData,
  selectChannelFetchingData,
  selectZoneStations,
} from '../../../duck/selectors';
import { deleteStation, fetchLocations, fetchZone, saveZoneData } from '../../../duck/thunks';
import { StationEditBladeType } from '../station/edit/stationEditContainer';
import { ZoneEdit, ZoneEditActions, ZoneEditProps } from './zoneEdit';

export interface ZoneEditContainerProps extends BladeProps {
  companyId: number;
  companyName: string;
  zoneId: number;
  zoneName: string;
  workgroupId: number;
}

const getEmptyZone = (companyId: number, companyName: string): Zone => {
  return {
    id: 0,
    name: '',
    isExplicit: false,
    workgroupId: companyId,
    workgroupName: companyName,
    channelType: 2,
    rowVersion: '',
  };
};

const getZone = (state: any, companyId: number, companyName: string, id: number | undefined) => {
  if (!id) {
    return getEmptyZone(companyId, companyName);
  }

  let zone = DataStoreSelectors.ChannelSelectors.selectZoneById(state, id);

  if (!zone) {
    zone = getEmptyZone(companyId, companyName);
    zone.id = id;
  }

  return zone;
};

const mapStateToProps: MapStateToProps<ZoneEditProps, ZoneEditContainerProps, any> = (
  state,
  ownProps
) => {
  const companyId = selectCompanyId(state);
  const zone = getZone(state, companyId, ownProps.companyName, ownProps.zoneId);

  const editBlade = BladeSelectors.selectActiveBladesDenormalized(state).find(
    (e) => e.type === ZoneEditBladeType
  );

  const stationEditBlade = BladeSelectors.selectActiveBladesDenormalized(state).find(
    (e) => e.type === StationEditBladeType
  );

  return {
    companyId,
    companyName: ownProps.companyName,
    zone,
    locations: zone.id ? [] : DataStoreSelectors.NLocation.selectLocationItems(state),
    stations: selectZoneStations(state),
    savingData: ownProps.zoneId
      ? selectEditingSavingChannelData(state)
      : selectCreatingSavingChannelData(state),
    fetchingData: selectChannelFetchingData(state),
    fetchingLocations: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocation),
    editBladeId: editBlade ? editBlade.id : undefined,
    stationEditBladeId: stationEditBlade ? stationEditBlade.id : undefined,
  };
};

const openEditBlade = (
  bladeId: string,
  props: any,
  dispatch: Dispatch<any>,
  editBladeId?: string
) => {
  if (editBladeId) {
    dispatch(
      BladeActions.closeChildrenBlades(editBladeId, [
        openBlade(bladeId, StationEditBladeType, props),
        stationStartEdit(),
      ])
    );
  } else {
    dispatch(openBlade(bladeId, StationEditBladeType, props));
    dispatch(stationStartEdit());
  }
};

const mapDispatchToProps: MapDispatchToProps<ZoneEditActions, ZoneEditContainerProps> = (
  dispatch,
  ownProps: ZoneEditContainerProps & BladeProps
) => {
  return {
    createZone: (zone: Zone) => {
      dispatch<any>(saveZoneData(ownProps.companyId, zone));
    },
    fetchZone: (zoneId: number) => {
      dispatch<any>(fetchZone(zoneId, ownProps.workgroupId));
    },
    fetchLocations: (workgroupId: number) => {
      dispatch<any>(fetchLocations(workgroupId));
    },
    createStation: (zoneId: number, editBladeId?: string) => {
      openEditBlade(ownProps.bladeId, { zoneId }, dispatch, editBladeId);
    },
    editStation: (id: number, name: string, editBladeId?: string) => {
      openEditBlade(
        ownProps.bladeId,
        {
          stationId: id,
          stationName: name,
          zoneId: ownProps.zoneId,
        },
        dispatch,
        editBladeId
      );
    },
    deleteStation: (
      stationId: number,
      stationName: string,
      companyId: number,
      editStationBladeId?: string
    ) => dispatch<any>(deleteStation(stationId, stationName, ownProps.zoneId, editStationBladeId)),
    closeBlade: () => {
      dispatch(BladeActions.closeBlade(ownProps.bladeId));
    },
  };
};

export const ZoneEditBladeType = 'NEWNITY_ZONE_EDIT';

const bladeId = (props: ZoneEditContainerProps) =>
  props.zoneId ? ZoneEditBladeType : 'NEWNITY_ZONE_CREATE';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 300 },
  bladeType: ZoneEditBladeType,
  id: bladeId,
  title: (ownProps: ZoneEditContainerProps) =>
    ownProps.zoneName ? ownProps.zoneName : i18n.t('newnity.edit.zone.blade.create.title'),
  allowMultipleInstances: false,
};

export const ZoneEditContainer = withRegisterBlade<any>(
  bladeConfig
)(connect(mapStateToProps, mapDispatchToProps)(ZoneEdit));

import { createSelector } from 'reselect';

import { NewnityEntityType, NCompanySearchResult } from '../types';
import { getDataStore } from './genericSelectors';

export const selectSearchCompanies = (state: any) =>
  getDataStore(state)[NewnityEntityType.NCompanySearchResult];

export const selecSearchCompanyItems = createSelector(
  [selectSearchCompanies],
  companies => {
    var x = Object.keys(companies.items).map<NCompanySearchResult>(
      key => companies.items[Number(key)]
    );
    return x;
  }
);

import { VirtualizedSelect } from 'app/components/forms/virtualizedSelect';
import { DeviceMode, StreamAudioOutput } from 'app/newnity/duck/types';
import { FastField, Field, FieldProps, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';

import { GroupBox } from '../../../../components';
import { PlayerType } from '../../../../dataStore';
import {
  ChannelType,
  NDevice,
  NLocation,
  NStation,
  Visual,
  Zone,
} from '../../../../dataStore/types';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { useStyles } from './device.jss';
import {
  useLocationSelectRenderValue,
  useLocationSelectRowRenderer,
  useStationSelect,
  useZonePlaylistSelect,
} from './hooks';

export interface DeviceDetailsProps {
  locations: NLocation[];
  leftZonePlaylists: NStation[];
  rightZonePlaylists: NStation[];
  zonePlaylists: NStation[];
  openedFromLocation?: number;
  deviceSetLocation: (location: NLocation) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  zones: Zone[];
  visuals: Visual[];
  values: NDevice;
  companyId: number;
  fetchZonePlaylists: (
    zoneId: number,
    companyId: number,
    leftOrRight: 'left' | 'right' | undefined
  ) => void;
}

const emptyChannel = {
  channelType: 0,
  id: 0,
  name: '',
  workgroupName: '',
  workgroupId: 0,
  isExplicit: false,
  rowVersion: '',
};

const emptyLocation = {
  id: 0,
  name: '',
  workgroupId: 0,
  oracleNumber: '',
  clientSiteId: '',
  timezone: '',
  country: '',
  city: '',
  phoneNumber: '',
  state: '',
  zipCode: '',
  address: '',
};

export const DeviceDetails = (props: DeviceDetailsProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [editableSerial, setEditableSerial] = useState(false);
  const [presetStation, setPresetStation] = useState(false);
  const [musicChannel, setMusicChannel] = useState(emptyChannel);
  const [leftMusicChannel, setLeftMusicChannel] = useState(emptyChannel);
  const [rightMusicChannel, setRightMusicChannel] = useState(emptyChannel);

  const [rightZonePresetStation, setRightZonePresetStation] = useState(false);
  const [leftZonePresetStation, setLeftZonePresetStation] = useState(false);
  const [leftZonePlaylists, setLeftZonePlaylists] = useState<NStation[]>([]);
  const [rightZonePlaylists, setRightZonePlaylists] = useState<NStation[]>([]);
  const [playlists, setPlaylists] = useState<NStation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<NLocation>(emptyLocation);

  const channelTypeToName = useCallback((type: ChannelType) => {
    const channelTypeMap = new Map<ChannelType, string>([
      [ChannelType.Music, t('newnity.device.edit.channelType.music')],
      [ChannelType.MusicMessages, t('newnity.device.edit.channelType.musicMessage')],
      [ChannelType.VOD, t('newnity.device.edit.channelType.vod')],
      [ChannelType.Video, t('newnity.device.edit.channelType.video')],
      [ChannelType.OnHold, t('newnity.device.edit.channelType.onHold')],
    ]);

    return channelTypeMap.get(type);
  }, []);

  useEffect(() => {
    const location = props.locations.find((location) => location.id === props.values.locationId);

    if (location) {
      setSelectedLocation(location);
    } else {
      setSelectedLocation(emptyLocation);
    }
  }, [props.locations, props.values.locationId]);

  useEffect(() => {
    setLeftZonePlaylists(props.leftZonePlaylists);
  }, [props.leftZonePlaylists]);

  useEffect(() => {
    setRightZonePlaylists(props.rightZonePlaylists);
  }, [props.rightZonePlaylists]);

  useEffect(() => {
    setPlaylists(props.zonePlaylists);
  }, [props.zonePlaylists]);

  useEffect(() => {
    const leftZone = props.zones.find((zone) => zone.id === props.values.leftZone);

    if (leftZone) {
      setLeftMusicChannel(leftZone);
    }
  }, [props.values.leftZone]);

  useEffect(() => {
    const rightZone = props.zones.find((zone) => zone.id === props.values.rightZone);

    if (rightZone) {
      setRightMusicChannel(rightZone);
    }
  }, [props.values.rightZone]);

  useEffect(() => {
    const channel = props.zones.find((zone) => zone.id === props.values.channelId);

    if (channel) {
      setMusicChannel(channel);
    }
  }, [props.values.channelId]);

  const zones = useMemo(() => {
    // filter out zones which are not part of the company workgroup or the selected location workgroup
    const workgroupIds = [props.companyId, selectedLocation.workgroupId];
    const filteredZones = props.zones.filter((zone) => workgroupIds.includes(zone.workgroupId));

    return filteredZones.sort((a, b) => {
      const aText = a.name.toUpperCase();
      const bText = b.name.toUpperCase();
      return aText < bText ? -1 : aText > bText ? 1 : 0;
    });
  }, [props.zones, selectedLocation]);

  const visuals = useMemo(
    () =>
      props.visuals.sort((a, b) => {
        const aText = a.name.toUpperCase();
        const bText = b.name.toUpperCase();
        return aText < bText ? -1 : aText > bText ? 1 : 0;
      }),
    [props.visuals]
  );

  const StationSelect = useStationSelect(playlists, presetStation, setPresetStation, musicChannel);

  const LeftZoneSelect = useZonePlaylistSelect(
    leftZonePlaylists,
    leftZonePresetStation,
    setLeftZonePresetStation,
    'newnity.device.edit.leftZonePlaylist',
    leftMusicChannel
  );

  const RightZoneSelect = useZonePlaylistSelect(
    rightZonePlaylists,
    rightZonePresetStation,
    setRightZonePresetStation,
    'newnity.device.edit.rightZonePlaylist',
    rightMusicChannel
  );

  const onLocationChange = useCallback(
    (location: NLocation) => {
      setPlaylists([]);
      setLeftZonePlaylists([]);
      setRightZonePlaylists([]);
      setMusicChannel(emptyChannel);
      setLeftMusicChannel(emptyChannel);
      setRightMusicChannel(emptyChannel);
      props.deviceSetLocation(location);
      setSelectedLocation(location);
    },
    [props.deviceSetLocation, props.locations]
  );

  const locationSelectRowRenderer = useLocationSelectRowRenderer(onLocationChange, props);
  const locationSelectRenderValue = useLocationSelectRenderValue();

  return (
    <GroupBox title="Device">
      <div className={classes.tabContent}>
        {!props.openedFromLocation && (
          <Field
            name="locationId"
            render={({ field, form }: FieldProps) => (
              <FormControl className={classes.selectMargin} style={{ flexGrow: 1 }}>
                <VirtualizedSelect
                  name={'locationId'}
                  classes={{ select: classes.locationSelect }}
                  value={field.value}
                  placeholder={t<string>('newnity.device.edit.location')}
                  autoComplete="something"
                  MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
                  maxHeight={250}
                  items={props.locations}
                  itemSize={56}
                  renderValue={locationSelectRenderValue}
                  rowRenderer={locationSelectRowRenderer({ field, form, meta: {} as any })}
                ></VirtualizedSelect>

                <FormHelperText error={validateEmptyField(form, field)}>
                  {helperText(form, field)}
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
        <Field name="deviceMode">
          {({ field, form }: FieldProps) => (
            <FormControl fullWidth style={{ width: '100%' }}>
              <InputLabel>{t<string>('newnity.device.edit.deviceMode')}</InputLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  form.setFieldValue('stationId', 0);
                  form.setFieldValue('channelId', 0);
                  form.setFieldValue('leftZone', 0);
                  form.setFieldValue('leftZonePlaylist', 0);
                  form.setFieldValue('rightZone', 0);
                  form.setFieldValue('rightZonePlaylist', 0);
                  setPlaylists([]);
                  setLeftZonePlaylists([]);
                  setRightZonePlaylists([]);
                  setMusicChannel(emptyChannel);
                  setRightMusicChannel(emptyChannel);
                  setLeftMusicChannel(emptyChannel);
                }}
              >
                <MenuItem value={DeviceMode.SingleZone}>{t('deviceMode.singleZone')}</MenuItem>
                <MenuItem value={DeviceMode.DualZone}>{t('deviceMode.dualZone')}</MenuItem>
              </Select>
            </FormControl>
          )}
        </Field>

        {props.values.deviceMode === DeviceMode.DualZone && (
          <div className={classes.zonesContainer}>
            <div>
              <Field name="leftZone">
                {({ field, form }: FieldProps) => (
                  <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
                    <InputLabel>{t<string>('newnity.device.edit.leftZone')}</InputLabel>
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        const channelId: number = e.target.value as number;
                        const channel = zones.find((e) => e.id === channelId);
                        if (channel) {
                          if (channel.channelType === ChannelType.Music) {
                            setLeftZonePresetStation(true);
                            props.fetchZonePlaylists(
                              channelId,
                              channel ? channel.workgroupId : props.companyId,
                              'left'
                            );
                          } else {
                            setLeftZonePresetStation(false);
                            setLeftZonePlaylists([]);
                            form.setFieldValue('leftZonePlaylist', 0);
                          }
                          setLeftMusicChannel(channel);
                        } else {
                          setLeftZonePlaylists([]);
                          form.setFieldValue('leftZonePlaylist', 0);
                          setLeftMusicChannel(emptyChannel);
                        }
                      }}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {zones.map((zone) => (
                        <MenuItem key={zone.id} value={zone.id}>
                          <span className={classes.channelName}>{zone.name}</span>
                          <span className={classes.channelType}>
                            {channelTypeToName(zone.channelType)}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Field name="leftZonePlaylist">{LeftZoneSelect}</Field>
            </div>

            <div>
              <Field name="rightZone">
                {({ field, form }: FieldProps) => (
                  <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
                    <InputLabel>{t<string>('newnity.device.edit.rightZone')}</InputLabel>
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        const channelId: number = e.target.value as number;
                        const channel = zones.find((e) => e.id === channelId);
                        if (channel) {
                          if (channel.channelType === ChannelType.Music) {
                            setRightZonePresetStation(true);
                            props.fetchZonePlaylists(
                              channelId,
                              channel ? channel.workgroupId : props.companyId,
                              'right'
                            );
                          } else {
                            setRightZonePresetStation(false);
                            setRightZonePlaylists([]);
                            form.setFieldValue('rightZonePlaylist', 0);
                          }
                          setRightMusicChannel(channel);
                        } else {
                          setRightZonePlaylists([]);
                          form.setFieldValue('rightZonePlaylist', 0);
                          setRightMusicChannel(emptyChannel);
                        }
                      }}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {zones.map((zone) => (
                        <MenuItem key={zone.id} value={zone.id}>
                          <span className={classes.channelName}>{zone.name}</span>
                          <span className={classes.channelType}>
                            {channelTypeToName(zone.channelType)}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Field name="rightZonePlaylist">{RightZoneSelect}</Field>
            </div>
          </div>
        )}

        {props.values.deviceMode === DeviceMode.SingleZone && (
          <>
            <Field name="channelId">
              {({ field, form }: FieldProps) => (
                <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
                  <InputLabel>{t<string>('newnity.device.edit.zone')}</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      const channelId: number = e.target.value as number;
                      const channel = zones.find((e) => e.id === channelId);
                      if (channel) {
                        if (channel.channelType === ChannelType.Music) {
                          setPresetStation(true);
                          props.fetchZonePlaylists(
                            channelId,
                            channel ? channel.workgroupId : props.companyId,
                            undefined
                          );
                        } else {
                          setPresetStation(false);
                          setPlaylists([]);
                          form.setFieldValue('stationId', 0);
                        }

                        setMusicChannel(channel);
                      } else {
                        setPlaylists([]);
                        form.setFieldValue(field.name, 0);
                        const { setValue } = form.getFieldHelpers('stationId');
                        setValue(0);
                        setMusicChannel(emptyChannel);
                      }
                    }}
                  >
                    <MenuItem value={0}>None</MenuItem>
                    {zones.map((zone) => (
                      <MenuItem key={zone.id} value={zone.id}>
                        <span className={classes.channelName}>{zone.name}</span>
                        <span className={classes.channelType}>
                          {channelTypeToName(zone.channelType)}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>

            <Field name="stationId">{StationSelect}</Field>
          </>
        )}
        <Field name="visualChannelId">
          {({ field, form }: FieldProps) => (
            <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
              <InputLabel>{t<string>('newnity.device.edit.visual')}</InputLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  const channelId: number = e.target.value as number;

                  if (channelId && form.values.audioOutputForVisuals < 0) {
                    form.setFieldValue('audioOutputForVisuals', StreamAudioOutput.LeftZone);
                  }
                }}
              >
                <MenuItem value={0}>
                  <span className={classes.channelName}>{t('None')}</span>
                </MenuItem>
                {visuals.map((visual) => (
                  <MenuItem key={visual.id} value={visual.id}>
                    <span className={classes.channelName}>{visual.name}</span>
                    <span className={classes.channelType}>
                      {channelTypeToName(visual.channelType)}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
        <Field name="audioOutputForVisuals">
          {({ field, form }: FieldProps) => (
            <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
              <InputLabel>{t<string>('newnity.device.edit.visual.audio.output')}</InputLabel>
              {form.values.visualChannelId === 0 ? (
                <Select {...field} disabled={true} value={-1}>
                  <MenuItem value={-1}>{t('None')}</MenuItem> :
                </Select>
              ) : (
                <Select {...field}>
                  <MenuItem key={StreamAudioOutput.AllZones} value={StreamAudioOutput.AllZones}>
                    {t('newnity.device.edit.audioOutputForVideo.all')}
                  </MenuItem>
                  <MenuItem key={StreamAudioOutput.LeftZone} value={StreamAudioOutput.LeftZone}>
                    {t('newnity.device.edit.audioOutputForVideo.left')}
                  </MenuItem>
                  <MenuItem key={StreamAudioOutput.RightZone} value={StreamAudioOutput.RightZone}>
                    {t('newnity.device.edit.audioOutputForVideo.right')}
                  </MenuItem>
                </Select>
              )}
            </FormControl>
          )}
        </Field>
        <FastField
          name="id"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              disabled
              label={t<string>('newnity.device.edit.number')}
              margin="normal"
            />
          )}
        />
        <FastField
          name="name"
          render={({ field, form }: FieldProps) => (
            <TextField
              {...field}
              label={t<string>('newnity.device.edit.name')}
              margin="normal"
              error={form.errors[field.name] !== undefined}
              helperText={form.errors[field.name]}
            />
          )}
        />
        <FastField
          name="deviceTypeId"
          render={({ field }: FieldProps) => (
            <FormControl className={classes.selectMargin}>
              <InputLabel>{t<string>('newnity.device.edit.deviceTypeId')}</InputLabel>
              <Select {...field}>
                <MenuItem value={PlayerType.Android}>{t<string>('newnity.android')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Field
          name="serialNumber"
          render={({ field, form }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 180 }}
              label={t<string>('newnity.device.edit.serialNumber')}
              margin="normal"
              disabled={!editableSerial}
              error={form.errors[field.name] !== undefined}
              helperText={form.errors[field.name]}
              onChange={(e) => {
                form.setFieldValue(field.name, e.currentTarget.value.toUpperCase());
              }}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t<string>(
                        editableSerial
                          ? 'newnity.device.edit.serialNumber.unlocked.tooltip'
                          : 'newnity.device.edit.serialNumber.locked.tooltip'
                      )}
                    >
                      <IconButton
                        onClick={() => {
                          setEditableSerial(!editableSerial);

                          if (editableSerial) {
                            form.setFieldValue(field.name, form.initialValues[field.name]);
                            form.setFieldTouched(field.name, true);
                          }
                        }}
                      >
                        {editableSerial ? <LockOpen /> : <Lock />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <FastField
          name="salesOrderNumber"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 255 }}
              label={t<string>('newnity.device.edit.salesOrderNumber')}
              margin="normal"
            />
          )}
        />
        <FastField
          name="description"
          render={({ field }: FieldProps) => (
            <TextField
              {...field}
              inputProps={{ maxLength: 255 }}
              label={t<string>('newnity.device.edit.description')}
              multiline={true}
              margin="normal"
            />
          )}
        />
      </div>
    </GroupBox>
  );
};

import * as DataStoreActions from './actions';
import * as DataStoreActionTypes from './actionTypes';
import * as GenericSelectors from './selectors/genericSelectors';
import * as NCompanySelectors from './selectors/newnityCompanies';
import * as DeviceSelectors from './selectors/newnityDevices';
import * as NLocationSelectors from './selectors/newnityLocations';
import * as NProgramSelectors from './selectors/newnityPrograms';
import * as NCompanySearchResult from './selectors/newnitySearchCompanies';
import * as NDeviceSearchResult from './selectors/newnitySearchDevices';
import * as NLocationSearchResult from './selectors/newnitySearchLocations';
import * as NStationSelectors from './selectors/newnityStations';
import * as PlayerSelectors from './selectors/playerSelectors';
import * as SiteSelectors from './selectors/siteSelectors';
import * as UserSelectors from './selectors/userSelectors';
import * as WorkgroupSelectors from './selectors/workgroupSelectors';
import * as ChannelSelectors from './selectors/channelSelectors';
export type {
  Brand,
  Channel,
  CountryInfo,
  IdName,
  ImportResult,
  ImportSkippedRow,
  NCompany,
  NDevice,
  NLocation,
  NormalizedIdNames,
  NStation,
  Player,
  Site,
  Stream,
  TemplateLibrary,
  OnHold,
  User,
  Visual,
  Workgroup,
  Zone,
} from './types';

export { areaName, NewnityEntityType as EntityType, PlayerType } from './types';

export const DataStoreSelectors = {
  ...GenericSelectors,
  User: { ...UserSelectors },
  Site: { ...SiteSelectors },
  Player: { ...PlayerSelectors },
  ChannelSelectors: { ...ChannelSelectors },
  Workgroup: { ...WorkgroupSelectors },
  NLocation: { ...NLocationSelectors },
  NCompany: { ...NCompanySelectors },
  NDevice: { ...DeviceSelectors },

  NStation: { ...NStationSelectors },
  NProgram: { ...NProgramSelectors },

  NSearchCompanyResult: { ...NCompanySearchResult },
  NDeviceSearchResult: { ...NDeviceSearchResult },
  NLocationSearchResult: { ...NLocationSearchResult },
};
export { DataStoreActionTypes };
export { dataStoreReducer as reducer, initializeEntityState } from './reducer';
export { DataStoreActions };

import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceOutputConfirm, useSubmitForm } from './hooks';
import {
  BladeProps,
  Entity,
  EntityType,
  Footer,
  FooterSubmit,
  useBladeButtons,
  useBladeClosing,
} from 'react-tools';

import { NDevice, NLocation, NStation, Visual, Workgroup, Zone } from '../../../../dataStore';
import { useStyles } from './device.jss';
import { DeviceDetails } from './deviceDetails';
import { DeviceMode, StreamAudioOutput } from 'app/newnity/duck/types';

import FadeIn from 'react-fade-in';
import SettingIcon from '@material-ui/icons/Settings';
import { useSelector } from 'react-redux';
import { selectOrderedStationItems } from 'app/dataStore/selectors/newnityStations';

export interface DeviceProps {
  companyId: number;
  device: NDevice;
  locations: NLocation[];
  leftZonePlaylists: NStation[];
  rightZonePlaylists: NStation[];
  zonePlaylists: NStation[];
  isSaving: boolean;
  isLoading: boolean;
  saved: boolean;
  openedFromLocation?: number;
  zones: Zone[];
  visuals: Visual[];
  workgroup: Workgroup;
}

export interface DeviceActions {
  saveDevice: (device: NDevice) => void;
  setDeviceSaved: (value: boolean) => void;
  fetchDevice: (deviceId: number) => void;
  fetchLocations: (companyId: number) => void;
  fetchStations: () => void;
  fetchStream: (streamId: number) => void;
  fetchChannels: (companyId: number) => void;
  fetchZonePlaylists: (
    zoneId: number,
    companyId: number,
    leftOrRight: 'left' | 'right' | undefined
  ) => void;
  deviceSetLocation: (location: NLocation) => void;
  forceCloseBlade: () => void;
  onCloseBlade: () => void;
  openDeviceSettings: () => void;
  fetchDevicesSettings: (entity: Entity, parentEntity: Entity | null) => void;
  clearDeviceSettings: () => void;
}

type Props = DeviceActions & DeviceProps & BladeProps;

export const Device = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formRef = useRef(null);
  const [audioOutputClashing, setAudioOutputClashing] = useState(false);

  const {
    fetchLocations,
    fetchStations,
    fetchDevice,
    fetchChannels,
    setDirty,
    forceCloseBlade,
    setDeviceSaved,
  } = props;

  useEffect(() => props.clearDeviceSettings, []);

  useEffect(() => {
    fetchLocations(props.companyId);
    fetchChannels(props.companyId);
    fetchStations();

    if (props.device.id) {
      fetchDevice(props.device.id);
    }
  }, [props.companyId, props.device.id]);

  useEffect(() => {
    if (props.device.channelId) {
      const workgroupId = props.device.channelIdWorkgroup
        ? props.device.channelIdWorkgroup
        : props.companyId;
      props.fetchZonePlaylists(props.device.channelId, workgroupId, undefined);
    }
  }, [props.device.stationId]);

  useEffect(() => {
    if (props.device.leftZone) {
      const workgroupId = props.device.leftZoneWorkgroup
        ? props.device.leftZoneWorkgroup
        : props.companyId;
      props.fetchZonePlaylists(props.device.leftZone, workgroupId, 'left');
    }
  }, [props.device.leftZone]);

  useEffect(() => {
    if (props.device.rightZone) {
      const workgroupId = props.device.rightZoneWorkgroup
        ? props.device.rightZoneWorkgroup
        : props.companyId;
      props.fetchZonePlaylists(props.device.rightZone, workgroupId, 'right');
    }
  }, [props.device.rightZone]);

  useEffect(() => {
    if (props.device.visualStreamId) {
      props.fetchStream(props.device.visualStreamId);
    }
  }, [props.device.visualStreamId]);

  const ConfirmationDialog = useDeviceOutputConfirm(
    'newnity.device.edit.audioOverlap',
    'warning',
    audioOutputClashing,
    () => {
      setAudioOutputClashing(false);
    }
  );

  useEffect(() => {
    if (props.saved) {
      setDirty(false);
      forceCloseBlade();
      setDeviceSaved(false);
    }
  }, [props.saved, setDirty, forceCloseBlade, setDeviceSaved]);

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.onCloseBlade()
  );

  const submitForm = useCallback((values: NDevice, formikHelpers: FormikHelpers<NDevice>) => {
    setAudioOutputClashing(false);
    props.saveDevice(values);
  }, []);

  const getSubmitForm = useSubmitForm(
    setAudioOutputClashing,
    props.zonePlaylists,
    props.leftZonePlaylists,
    props.rightZonePlaylists
  );

  const fetchDevicesSettings = useCallback(() => {
    if (props.device && props.device.id) {
      props.fetchDevicesSettings(
        { entityId: props.device.id, entityType: EntityType.Hardware },
        { entityId: props.device.locationId, entityType: EntityType.Site }
      );
    }
  }, [props.device, props.fetchDevicesSettings]);

  const buttons = useMemo(
    () => [
      {
        onClick: () => {
          props.openDeviceSettings();
          fetchDevicesSettings();
        },
        icon: () => <SettingIcon />,
        disabled: !props.device || !props.device.id,
        tooltip: t('deviceSettings.edit').toString(),
      },
    ],
    [props.openDeviceSettings, fetchDevicesSettings, props.device]
  );

  useBladeButtons(buttons, [buttons]);

  const validateForm = useCallback(
    (values: NDevice) => {
      const errors: FormikErrors<NDevice> = {};
      if (!props.openedFromLocation && !values.locationName) {
        errors.locationId = t('newnity.device.required.location');
      }

      if (values.serialNumber._containsAny(['\\', '/', ':', '*', '?', '"', '<', '>', '|', '.'])) {
        errors.serialNumber = t('newnity.device.serial.characters');
      }

      if (!values.name.trim()) {
        errors.name = t('newnity.device.required.name');
      }

      return errors;
    },
    [t, setDirty, props.openedFromLocation]
  );

  const onFormChanged = useCallback(
    (dirty: boolean) => () => props.setDirty(dirty),
    [props.setDirty]
  );

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={props.device}
          onSubmit={submitForm}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validate={validateForm}
          ref={formRef}
        >
          {({ submitForm, values, handleChange, dirty }: FormikValues) => (
            <form
              onSubmit={(e) => e.preventDefault()}
              className={classes.form}
              onChange={onFormChanged(dirty)}
            >
              <div className={classes.formContent}>
                <DeviceDetails
                  zonePlaylists={props.zonePlaylists}
                  fetchZonePlaylists={props.fetchZonePlaylists}
                  leftZonePlaylists={props.leftZonePlaylists}
                  rightZonePlaylists={props.rightZonePlaylists}
                  zones={props.zones}
                  visuals={props.visuals}
                  values={values}
                  handleChange={handleChange}
                  locations={props.locations}
                  openedFromLocation={props.openedFromLocation}
                  deviceSetLocation={props.deviceSetLocation}
                  companyId={props.companyId}
                />
              </div>
              <ConfirmationDialog onContinue={submitForm} />
              <Footer>
                <FooterSubmit
                  onSubmitPress={getSubmitForm(values, submitForm)}
                  hasCancelButton={true}
                  cancelButtonLabel={t<string>('footer.cancel')}
                  cancel={props.forceCloseBlade}
                  submitButtonLabel={t<string>('footer.save')}
                  submitInProgress={props.isSaving}
                />
              </Footer>
            </form>
          )}
        </Formik>
      </FadeIn>
    </div>
  );
};

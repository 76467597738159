import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BladeActions, BladeProps, openBlade, withRegisterBlade } from 'react-tools';

import { DataStoreSelectors } from '../../../../dataStore';
import { deviceReset, deviceStartEdit } from '../../../duck/actions';
import { selectCompanyId, selectIsEditingDevice } from '../../../duck/selectors';
import { deleteDevice, fetchDevices } from '../../../duck/thunks';
import { NewnityState } from '../../../duck/types';
import { DeviceBladeType } from '../edit/deviceContainer';
import { DeviceList } from './deviceList';

interface DevicesListContainerProps {
  openedFromLocation?: number;
}
export const DeviceListBladeType = 'NEWNITY_DEVICE_LIST';

const bladeConfig = {
  size: { defaultWidth: 900, minWidth: 900 },
  bladeType: DeviceListBladeType,
  id: (_: any) => DeviceListBladeType,
  title: (_: any) => 'Devices',
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  openedFromLocation,
  ...bladeProps
}: DevicesListContainerProps & BladeProps) => {
  const dispatch = useDispatch();
  const companyId = useSelector(selectCompanyId);
  const deletingDevice = useSelector(
    (state: any) => (state.newnity as NewnityState).currentDevice.deletingDevice
  );
  const isEditing = useSelector(selectIsEditingDevice);
  const temp = useSelector(DataStoreSelectors.NDevice.selectDeviceItems);
  const items = useMemo(
    () =>
      openedFromLocation ? temp.filter((a) => a && a.locationId === openedFromLocation) : temp,
    [temp, openedFromLocation]
  );

  const fetchDevicesForCompany = useCallback(
    (companyId) => dispatch<any>(fetchDevices(companyId)),
    [dispatch]
  );

  const createDevice = useCallback(() => {
    dispatch(
      BladeActions.closeChildrenBlades(
        bladeProps.bladeId,
        [
          deviceReset(),
          openBlade(bladeProps.bladeId, DeviceBladeType, {
            openedFromLocation,
          })
        ]
      )
    );
  }, []);

  const editDevice = useCallback((deviceId: number) => {
    dispatch(
      BladeActions.closeChildrenBlades(
        bladeProps.bladeId,
        [
          deviceReset(),
          openBlade(bladeProps.bladeId, DeviceBladeType, {
            deviceId,
            openedFromLocation,
          }),
        ],
        dispatch<any>(deviceStartEdit())
      )
    );
  }, []);

  const deleteDeviceById: (deviceId: number) => any = useCallback(
    (deviceId: number) => dispatch<any>(deleteDevice(deviceId, DeviceListBladeType)),
    [dispatch]
  );

  return (
    <DeviceList
      companyId={companyId}
      items={items}
      deletingDevice={deletingDevice}
      isEditing={isEditing}
      createDevice={createDevice}
      deleteDevice={deleteDeviceById}
      editDevice={editDevice}
      fetchDevices={fetchDevicesForCompany}
    />
  );
};

export const DeviceListContainer = withRegisterBlade<DevicesListContainerProps & BladeProps>(
  bladeConfig
)(ContainerComponent);

import { IdName } from 'app/dataStore';
import { NProgram } from 'app/dataStore/types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  ColumnSettings,
  DataGrid,
  DataGridSelectionType,
  Footer,
  FooterSubmit,
} from 'react-tools';

import { useStyles } from './programsList.jss';

export interface ProgramsListProps {
  stationId: number;
  defaultSlot: boolean;
  items: NProgram[];
  itemsFetching: boolean;
}

export interface ProgramsListActions {
  forceCloseBlade: () => void;
  addPrograms: (items: IdName[], stationId: number, defaultSlot: boolean) => void;
}

type Props = ProgramsListActions & ProgramsListProps & BladeProps;

const columnSettings: ColumnSettings[] = [
  {
    name: 'name',
    width: 200,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
    filter: 'agTextColumnFilter',
  },
  {
    name: 'genre',
    width: 150,
    filter: 'agTextColumnFilter',
  },
];

export const ProgramsList: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const selectedRef = React.useRef<IdName[]>([]);
  const onSelect = useCallback((selectedIds: IdName[]) => (selectedRef.current = selectedIds), []);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'programs'}
        onSelect={onSelect}
        items={props.items}
        showSelectionColumn={true}
        rowClass={classes.alignedRow}
        selectionType={
          props.defaultSlot ? DataGridSelectionType.Single : DataGridSelectionType.MultipleOrNone
        }
        columnSettings={columnSettings}
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />

      <Footer>
        <FooterSubmit
          hasCancelButton={true}
          cancelButtonLabel={t<string>('footer.cancel')}
          cancel={() => {
            props.forceCloseBlade();
          }}
          submitButtonLabel={t<string>('footer.save')}
          submit={() => {
            props.addPrograms(selectedRef.current, props.stationId, props.defaultSlot);
            props.forceCloseBlade();
          }}
          submitInProgress={false}
        />
      </Footer>
    </div>
  );
};

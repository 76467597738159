import { MenuItem } from '@material-ui/core';
import { NLocation } from 'app/dataStore';
import { FieldProps } from 'formik';
import React, { useCallback } from 'react';
import { useStyles } from '../device.jss';

export const useLocationSelectRenderValue = () => {
  const classes = useStyles();

  return useCallback(
    (value: any) => {
      return (
        <>
          <div className={classes.locationNumber}>{value.oracleNumber}</div>
          <div className={classes.locationName}>{value.name}</div>
        </>
      );
    },
    [classes.locationNumber, classes.locationName]
  );
};

export const useLocationSelectRowRenderer = (
  deviceSetLocation: (location: NLocation) => void,
  props: any
) => {
  const classes = useStyles();
  const locationSelectRenderValue = useLocationSelectRenderValue();

  return useCallback(
    ({ field, form }: FieldProps) => {
      return (items: NLocation[], callback?: (value: any) => void) => {
        return ({ index, style }: { index: number; style: any }) => {
          const location = items[index];

          return (
            <MenuItem
              style={style}
              key={location.id}
              value={location.id}
              disableGutters={true}
              classes={{ root: classes.locationMenuItem }}
              onClick={(e) => {
                if (!location) {
                  return;
                }
                form.setFieldValue(field.name, location.id);
                form.setFieldValue('locationName', location.name);
                form.setFieldValue('locationNumber', location.oracleNumber);

                // each time we change the location of the device we reset all (music) zones and playlists
                form.setFieldValue('stationId', 0);
                form.setFieldValue('channelId', 0);
                form.setFieldValue('leftZone', 0);
                form.setFieldValue('leftZonePlaylist', 0);
                form.setFieldValue('rightZone', 0);
                form.setFieldValue('rightZonePlaylist', 0);

                deviceSetLocation(location);
                if (callback) {
                  callback(location);
                }
              }}
            >
              <>
                <div className={classes.locationNumberWithPadding}>{location.oracleNumber}</div>
                <div className={classes.locationNameWithPadding}>{location.name}</div>
              </>
            </MenuItem>
          );
        };
      };
    },
    [props, classes.locationMenuItem, locationSelectRenderValue]
  );
};

import { Player } from 'app/dataStore';
import { ImportResult, NStation, Channel, NewnityEntityType, ImportSkippedRow } from 'app/dataStore/types';
import { DaysAvailability } from 'app/newnity/blades/zone/station/programSchedule/programSchedule';
import { HMS } from 'app/newnity/blades/zone/station/programSchedule/timeInput';
import { DeviceSettingModel, Entity, IdName } from 'react-tools';

export const areaName = 'newnity';

export interface NewnityState {
  enabled: boolean;
  loadingCompanyMode: boolean;
  inCompanyMode: boolean;
  selectedLocations: IdName[];
  selectedDevices: IdName[];
  selectedZones: IdName[];
  programAsset: ProgramAsset;
  currentCompany: CurrentCompanyState;
  currentLocation: CurrentLocationState;
  currentDevice: CurrentDeviceState;
  currentStation: CurrentStationState;
  currentChannel: CurrentChannelState;
  currentWorkgroupAuth: CurrentWorkgroupAuthState;
  search: SearchState;
  parentWorkgroup: ParentWorkgroupState;
  import: ImportState;
  deviceSettings: DeviceSettingsState;
}

export interface DeviceSettingsState {
  isFetching: boolean;
  entity: Entity | null;
  parentEntity: Entity | null;
  entitySettings: DeviceSettingModel[];
  parentEntitySettings: DeviceSettingModel[] | null;
  isSavingSettings: boolean;
  saved: boolean;
}

export interface ImportState {
  parseFileFetchState: FetchState<string>;
  parseFileResult: ImportFileParseResult;
  importResult: ImportFileResult;
  importFetchState: FetchState<string>;
}
export interface ParentWorkgroupState {
  defaultWorkgroupParent: number;
  currentWorkgroupParent: number;
  fetchingData: boolean;
  fetchError: string;
}

export interface CurrentChannelState {
  fetchingChannelData: FetchState;
  createSavingState: FetchState;
  editSavingState: FetchState;
  deletingChannel: boolean;

  channel: Channel;

  stations: NStation[];
  isEditing: boolean;
}

export interface CurrentLocationState {
  savingData: boolean;
  deletingLocation: boolean;
  isEditing: boolean;
}

export interface ProgramAsset {
  [programId: number]: number;
}

// station types used locally in Redux and our components
export interface StationDefaultSlot extends IdName {
  assetId?: number;
  programId?: number;
  localId: number;
}

export interface StationScheduleSlot extends StationDefaultSlot {
  isSilence: boolean;
  schedule: Schedule;
  type: number;
}

export interface CurrentStationState {
  fetchingData: FetchState;
  createSavingState: FetchState;
  editSavingState: FetchState;
  deletingStation: boolean;

  scheduleEdittingSlotId: number;
  editSchedule: StationState;
  createSchedule: StationState;

  station: NStation;
  isEditing: boolean;
}

export interface StationState {
  id: number;
  name: string;
  defaultSlot?: StationDefaultSlot;
  scheduleSlots: StationScheduleSlot[];
}

export interface Schedule extends IdName {
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  diffusionDays: number;
  channelMediaRestrictions: MediaDeny[];
}

// station DTO types used in Anthem
export interface StationDefaultSlotDto extends IdName {
  contentIdMedia?: number; // id of the instance program(not the asset)
  description: string;
  isSilence: boolean;
  type: number;
}

export interface StationScheduleSlotDto
  extends StationDefaultSlotDto,
    Pick<Schedule, Exclude<keyof Schedule, 'startTime' | 'endTime' | 'startDate' | 'endDate'>> {
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  channelMediaRestrictions: MediaDeny[];
}

export interface StationDto extends NStation {
  players: Player[];
  schedule: {
    id: number;
    name: string;
    slots: StationScheduleSlotDto[];
    blockRemoteScrolling: boolean | undefined;
    defaultSlot: StationDefaultSlotDto;
  };
}

// Devices
export interface CurrentDeviceState {
  saved: boolean;
  isSaving: boolean;
  locationId: number;
  deletingDevice: boolean;
  isEditing: boolean;
  stationId: number;
  stationName: string;
  leftZonePlaylists: NStation[];
  rightZonePlaylists: NStation[];
  zonePlaylists: NStation[];
}

export enum DeviceMode {
  SingleZone = 0,
  DualZone = 1,
}

export interface CurrentCompanyState {
  fetchingData: boolean;
  fetchError: string;

  savingState: FetchState;

  companyId: number;
}

export interface CurrentWorkgroupAuthState {
  fetchingData: boolean;
  fetchError: string;
  workgroupId: number;
}

export interface FetchState<T = number> {
  isFetching: boolean;
  fetchError: string;
  fetchCompleted: boolean;
  id: T;
}

export interface CurrentSchedule {
  entireDay: boolean;
  startTime: HMS;
  endTime: HMS;
  days: DaysAvailability;
}

export enum StreamAudioOutput {
  AllZones = 0,
  RightZone = 1,
  LeftZone = 2,
}

export enum AnthemMediaType {
  Audio = 10,
  EventPlaylist = 32,
  MusicPlaylist = 42,
  MusicMix = 43,
  MusicProgram = 44,
  FirstValidPlaylist = 45,
  Silence = 46,
  PlayerStream = 47,
}

export interface MediaDeny {
  idMediaChannel?: number;
  idMedia?: number;
  idEntity: number;
  idEntityType: NewnityEntityType;
  deny: boolean | null;
}

export enum ActionTypes {
  SET_COMPANY_REQUEST = 'SET_COMPANY_REQUEST',
  SET_COMPANY_SUCCESS = 'SET_COMPANY_SUCCESS',
  SET_COMPANY_ERROR = 'SET_COMPANY_ERROR',
  COMPANY_DATA_FETCH_REQUEST = 'COMPANY_DATA_FETCH_REQUEST',
  COMPANY_DATA_FETCH_SUCCESS = 'COMPANY_DATA_FETCH_SUCCESS',
  COMPANY_DATA_FETCH_ERROR = 'COMPANY_DATA_FETCH_ERROR',
  CLEANUP_COMPANY_EDIT = 'CLEANUP_COMPANY_EDIT',
  SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST',
  SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS',
  SAVE_COMPANY_ERROR = 'SAVE_COMPANY_ERROR',
  SAVE_LOCATION_REQUEST = 'SAVE_LOCATION_REQUEST',
  SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS',
  SAVE_LOCATION_ERROR = 'SAVE_LOCATION_ERROR',
  SEARCH_SET_FIELDS = 'SEARCH_UPDATE_FIELDS',
  SEARCH_SET_CURRENT_ENTITY = 'SEARCH_UPDATE_CURRENT_ENTITY',
  SEARCH_SET_SEARCHED = 'SEARCH_SET_SEARCHED',
  SEARCH_SET_TAB_INDEX = 'SEARCH_SET_TAB_INDEX',
  SET_IN_COMPANY_MODE = 'SET_IN_COMPANY_MODE',
  SET_SELECTED_PROGRAMS = 'SET_SELECTED_PROGRAMS',
  SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS',
  SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES',
  SET_SELECTED_ZONES = 'SET_SELECTED_ZONES',
  ADD_SELECTED_PROGRAMS = 'ADD_SELECTED_PROGRAMS',
  DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST',
  DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR',
  SAVE_DEVICE_REQUEST = 'SAVE_DEVICE_REQUEST',
  SAVE_DEVICE_SUCCESS = 'SAVE_DEVICE_SUCCESS',
  SAVE_DEVICE_ERROR = 'SAVE_DEVICE_ERROR',
  DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR',
  SET_DEVICE_SAVED = 'SET_DEVICE_SAVED',
  SET_DEVICE_LOCATION = 'SET_DEVICE_LOCATION',
  DEFAULT_PARENT_WORKGROUP_REQUEST = 'DEFAULT_PARENT_WORKGROUP_REQUEST',
  DEFAULT_PARENT_WORKGROUP_SUCCESS = 'DEFAULT_PARENT_WORKGROUP_SUCCESS',
  DEFAULT_PARENT_WORKGROUP_ERROR = 'DEFAULT_PARENT_WORKGROUP_ERROR',
  UPDATE_PARENT_WORKGROUP_SUCCESS = 'UPDATE_PARENT_WORKGROUP_SUCCESS',
  STARTUP_REQUEST = 'STARTUP_REQUEST',
  STARTUP_SUCCESS = 'STARTUP_SUCCESS',
  STATION_FETCH_REQUEST = 'STATION_FETCH_REQUEST',
  STATION_FETCH_SUCCESS = 'STATION_FETCH_SUCCESS',
  STATION_FETCH_ERROR = 'STATION_FETCH_ERROR',
  STATION_SAVE_REQUEST = 'STATION_CREATE_REQUEST',
  STATION_SAVE_SUCCESS = 'STATION_CREATE_SUCCESS',
  STATION_SAVE_ERROR = 'STATION_CREATE_ERROR',
  DELETE_STATION_REQUEST = 'DELETE_STATION_REQUEST',
  DELETE_STATION_SUCCESS = 'DELETE_STATION_SUCCESS',
  DELETE_STATION_ERROR = 'DELETE_STATION_ERROR',
  SET_SCHEDULE = 'SET_SCHEDULE',
  CHANGE_STATION_SLOT_POSITION = 'CHANGE_STATION_SLOT_POSITION',
  CLEANUP_STATION_EDIT = 'CLEANUP_STATION_EDIT',
  STATION_ADD_SILENCE = 'STATION_ADD_SILENCE',
  STATION_REMOVE_SLOT = 'STATION_REMOVE_SLOT',
  STATION_SET_EDITTING_SLOT = 'STATION_SET_EDITTING_SLOT',
  PARSE_IMPORT_FILE_REQUEST = 'PARSE_IMPORT_FILE_REQUEST',
  PARSE_IMPORT_FILE_SUCCESS = 'PARSE_IMPORT_FILE_SUCCESS',
  PARSE_IMPORT_FILE_ERROR = 'PARSE_IMPORT_FILE_ERROR',
  IMPORT_FILE_REQUEST = 'IMPORT_FILE_REQUEST',
  IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS',
  IMPORT_FILE_ERROR = 'IMPORT_FILE_ERROR',
  CLEANUP_IMPORT = 'CLEANUP_IMPORT',
  LOCATION_EDITING_STARTED = 'LOCATION_EDITING_STARTED',
  LOCATION_EDITING_COMPLETED = 'LOCATION_EDITING_COMPLETED',
  DEVICE_RESET = 'DEVICE_RESET',
  DEVICE_EDITING_STARTED = 'DEVICE_EDITING_STARTED',
  DEVICE_EDITING_COMPLETED = 'DEVICE_EDITING_COMPLETED',
  STATION_EDITING_STARTED = 'STATION_EDITING_STARTED',
  STATION_EDITING_COMPLETED = 'STATION_EDITING_COMPLETED',
  CHANNEL_FETCH_REQUEST = 'CHANNEL_FETCH_REQUEST',
  CHANNEL_FETCH_SUCCESS = 'CHANNEL_FETCH_SUCCESS',
  CHANNEL_FETCH_ERROR = 'CHANNEL_FETCH_ERROR',
  CHANNEL_SAVE_REQUEST = 'CHANNEL_SAVE_REQUEST',
  CHANNEL_SAVE_SUCCESS = 'CHANNEL_SAVE_SUCCESS',
  CHANNEL_SAVE_ERROR = 'CHANNEL_SAVE_ERROR',
  CHANNEL_DELETE_REQUEST = 'CHANNEL_DELETE_REQUEST',
  CHANNEL_DELETE_SUCCESS = 'CHANNEL_DELETE_SUCCESS',
  CHANNEL_DELETE_ERROR = 'CHANNEL_DELETE_ERROR',
  CHANNEL_EDITING_STARTED = 'CHANNEL_EDITING_STARTED',
  CHANNEL_EDITING_COMPLETED = 'CHANNEL_EDITING_COMPLETED',
  CHANNEL_FETCH_PLAYLISTS_REQUEST = 'CHANNEL_FETCH_PLAYLISTS_REQUEST',
  CHANNEL_FETCH_PLAYLISTS_SUCCESS = 'CHANNEL_FETCH_PLAYLISTS_SUCCESS',
  CHANNEL_FETCH_PLAYLISTS_ERROR = 'CHANNEL_FETCH_PLAYLISTS_ERROR',
  FETCH_PLAYLISTS_FOR_ZONE_SUCCESS = 'FETCH_PLAYLISTS_FOR_ZONE_SUCCESS',
  SET_WORKGROUP_AUTH_REQUEST = 'SET_WORKGROUP_AUTH_REQUEST',
  SET_WORKGROUP_AUTH_SUCCESS = 'SET_WORKGROUP_AUTH_SUCCESS',
  SET_WORKGROUP_AUTH_ERROR = 'SET_WORKGROUP_AUTH_ERROR',
}

export interface SearchState {
  currentEntity: NewnityEntityType;
  currentFields: SearchFields;
  searchedEntity?: NewnityEntityType;
  searchedFields?: SearchFields;
  tabIndex: number;
}

export enum SearchEntity {
  Company,
  Location,
  Device,
}

export interface SearchFields {
  company: CompanySearchFields;
  device: DeviceSearchFields;
  location: LocationSearchFields;
}

export interface CompanySearchFields {
  number: string;
  name: string;

  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  phone: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface DeviceSearchFields {
  deviceNumber: string;
  deviceDescription: string;
  serial: string;
  locationNumber: string;
  locationName: string;
  siteNumber: string;
  company: string;
  salesOrder: string;
}

export interface LocationSearchFields {
  locationNumber: string;
  locationName: string;
  deviceNumber: string;
  serial: string;
  siteNumber: string;
  company: string;

  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum NetworkTypes {
  ETHERNET = 0,
  WIFI = 1,
}

export enum NetworkMode {
  DHCP = 1,
  Static = 0,
}

export enum WifiSecurity {
  None = 0,
  WEP = 1,
  WPA = 2,
  WPA2 = 3,
}

export interface ImportFileParseResult {
  devices: ImportResult;
  locations: ImportResult;
  deviceSettings: ImportResult;
}

export interface ImportFileResult {
  devices: ImportResult;
  locations: ImportResult;
}

export enum WorkgroupProfileType {
  NewnityDefault = 0,
  NewnityLocationUser = 1,
  NewnityAudio = 2,
  NewnityVideo = 3,
  NewnityAudioVideo = 4
}

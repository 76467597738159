export interface ResponseWithVersion<T> {
  version: string;
  data: T;
}

export const apiFetch = (url: string, options: RequestInit = {}): Promise<Response> => {
  const workgroupHeader = { [CURRENT_WORKGROUP_HEADER_NAME]: getCookieValue(currentWorkgroupKey) };
  options.headers = options.headers ? { ...options.headers, ...workgroupHeader } : workgroupHeader;

  return fetch(url, options).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
};

export const fetchHelper = (
  url: string,
  verb: string,
  data = {},
  headers?: { [key: string]: string }
): Promise<Response> => {
  return fetch(url, {
    method: verb, // *GET, POST, PUT, DELETE, etc.
    // mode: "cors", // no-cors, cors, *same-origin
    credentials: 'include',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      [CURRENT_WORKGROUP_HEADER_NAME]: getCookieValue(currentWorkgroupKey),
      ...headers,
    },
    body: JSON.stringify(data),
  });
};

export const getWithVersion = async <T>(url: string): Promise<ResponseWithVersion<T>> => {
  const response = await apiFetch(url);
  const data = await response.json();
  const version = response.headers.get('ETag') || '';

  return {
    data,
    version,
  };
};

export const putWithVersion = async <T>(
  url: string,
  data: any,
  version: string,
  headers?: { [key: string]: string }
): Promise<{ data: T; version: string }> => {
  const computedHeaders = { ...headers, ETag: version };
  const response = await fetchHelper(url, 'PUT', data, computedHeaders);
  const responseData = await response.json();

  return {
    data: responseData,
    version: response.headers.get('ETag') || '',
  };
};

export const postWithReturnedVersion = async <T>(
  url: string,
  data: any,
  headers?: { [key: string]: string }
): Promise<{ data: T; version: string }> => {
  const response = await fetchHelper(url, 'POST', data, headers);
  const responseData = await response.json();

  return {
    data: responseData,
    version: response.headers.get('ETag') || '',
  };
};

export const getCookieValue = (key: string): string => {
  const b: string[] | null = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);

  if (b === null) {
    return '';
  }

  const value = b.pop();
  return value ? value : '';
};

export const currentWorkgroupKey: string = 'currentWorkgroup';
export const CURRENT_WORKGROUP_HEADER_NAME: string = process.env.REACT_APP_HEADER_01 as string;

import { DeleteConfirmDialog, useDeleteConfirm } from 'app/components/deleteConfirmDialog';
import { DataItemsFetchStatus, OnHold } from 'app/dataStore/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonItem,
  ColumnSettings,
  DataGrid,
  DataGridSelectionType,
  useBladeButtons,
} from 'react-tools';

import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import { IdName } from '../../../../dataStore';
import { useStyles } from './onHoldList.jss';

const columnSettings: ColumnSettings[] = [
  {
    name: 'name',
    width: 190,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
    filter: 'agTextColumnFilter',
  },
  { name: 'workgroupName', width: 190, filter: 'agTextColumnFilter' },
];

export interface OnHoldListProps {
  workgroupId: number;
  onHoldList: OnHold[];
  fetchStatus: DataItemsFetchStatus;
  isEditing: boolean;
  onHoldEditBladeId?: string;
}

export interface OnHoldListActions {
  fetchOnHoldList: (workgroupId: number) => void;
  createOnHold: () => void;
  editOnHold: (onHoldId: number, onHoldName: string, workgroupId: number) => void;
  deleteOnHold: (onHoldId: number, onHoldName: string, bladeId?: string) => void;
}

export const OnHoldList = (props: OnHoldListProps & OnHoldListActions) => {
  const classes = useStyles();
  const [deleteConfirmOnHold, setDeleteConfirmOnHold] = useDeleteConfirm();
  const [selected, setSelected] = useState<IdName[]>([]);
  const [t] = useTranslation();

  const editOnHold = useCallback(() => {
    if (selected.length) {
      props.editOnHold(selected[0].id, selected[0].name, (selected[0] as OnHold).workgroupId);
    }
  }, [selected, props.editOnHold]);

  const deleteOnHold = useCallback(() => {
    if (selected.length > 0) {
      const onHold = selected[0];
      setDeleteConfirmOnHold({
        id: onHold.id,
        name: onHold.name,
        confirmNeeded: true,
      });
    }
  }, [selected, setDeleteConfirmOnHold]);

  const onHoldDelete = useCallback(() => {
    props.deleteOnHold(
      deleteConfirmOnHold.id as number,
      deleteConfirmOnHold.name,
      props.onHoldEditBladeId
    );
    setDeleteConfirmOnHold({
      id: 0,
      name: '',
      confirmNeeded: false,
    });
  }, [props.deleteOnHold, props.onHoldEditBladeId, deleteConfirmOnHold]);

  const headerButtons: ButtonItem[] = useMemo(
    () => [
      {
        icon: () => <Add />,
        tooltip: 'onHoldList.tooltip.add',
        onClick: props.createOnHold,
      },
      {
        icon: () => <Edit />,
        tooltip: 'onHoldList.tooltip.edit',
        onClick: editOnHold,
      },
      // {
      //   icon: () => <Delete />,
      //   onClick: deleteOnHold,
      //   tooltip: 'onHoldList.tooltip.delete',
      // },
    ],
    [props.createOnHold, editOnHold]
  );
  useBladeButtons(headerButtons);

  useEffect(() => {
    if (!props.fetchStatus.isFetching && !props.fetchStatus.fetchComplete) {
      props.fetchOnHoldList(props.workgroupId);
    }
  }, [props.workgroupId, props.fetchOnHoldList, props.fetchStatus]);

  const selectOnHoldChannels = useCallback((items: IdName[]) => {
    setSelected(items as IdName[]);
  }, []);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'onHoldList'}
        idColumn={'id'}
        onSelect={selectOnHoldChannels}
        rowClass={classes.alignedRow}
        items={props.onHoldList}
        selectionType={DataGridSelectionType.SingleOrNone}
        columnSettings={columnSettings}
        showSelectionColumn
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />
      <DeleteConfirmDialog
        important={true}
        title={t<string>('newnity.onHold.delete.confirm.title')}
        message={
          (props.isEditing ? t('newnity.onHold.delete.currently.editing') : '') +
          t('newnity.onHold.delete.confirm.message', {
            onHoldName:
              selected.length > 0
                ? (selected[0] as OnHold).name || t('newnity.onHold.delete.confirm.generic')
                : '',
          })
        }
        open={deleteConfirmOnHold.confirmNeeded}
        onDelete={onHoldDelete}
        onCancel={() =>
          setDeleteConfirmOnHold({
            id: 0,
            name: '',
            confirmNeeded: false,
          })
        }
      />
    </div>
  );
};

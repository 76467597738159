import { debounce } from 'debounce';
import { Formik, FormikErrors, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  Entity,
  EntityType,
  Footer,
  FooterSubmit,
  useBladeButtons,
  useBladeClosing,
} from 'react-tools';

import { NCompany } from '../../../../dataStore';
import { FetchState } from '../../../duck/types';
import { Loading } from '../../search/results/loading';
import { useStyles } from './company.jss';
import { CompanyDetailsFields } from './companyDetailsFields';

import SettingIcon from '@material-ui/icons/Settings';

import FadeIn from 'react-fade-in';

export interface CompanyInfoProps {
  isFetching: boolean;
  company: NCompany;
  savingData: FetchState;
}

export interface CompanyInfoActions {
  saveCompany: (companyData: NCompany) => void;
  closeBlade: (bladeId: string) => void;
  upsertEntity: (company: NCompany) => void;
  openDeviceSettings: () => void;
  fetchDevicesSettings: (entity: Entity, parentEntity: Entity | null) => void;
  clearDeviceSettings: () => void;
}

type Props = CompanyInfoProps & CompanyInfoActions & BladeProps;

export const CompanyInfo: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const fetchDeviceSettings = useCallback(() => {
    if (props.company && props.company.id) {
      props.fetchDevicesSettings(
        { entityId: props.company.id, entityType: EntityType.Workgroup },
        props.company.parentId
          ? { entityId: props.company.parentId, entityType: EntityType.Workgroup }
          : null
      );
    }
  }, [props.company, props.fetchDevicesSettings]);

  const buttons = useMemo(
    () => [
      {
        onClick: () => {
          fetchDeviceSettings();
          props.openDeviceSettings();
        },
        icon: () => <SettingIcon />,
        disabled: !props.company || !props.company.id,
        tooltip: t('deviceSettings.edit').toString(),
      },
    ],
    [props.company, fetchDeviceSettings, props.company]
  );

  useBladeButtons(buttons, [buttons]);

  const { setDirty } = props;

  const conditionalDirty = () => {
    // TODO: Improve dirty logic
    if (props.isDirty) {
      return;
    }

    props.setDirty(true);
  };

  useEffect(() => {
    if (props.savingData.fetchCompleted && props.savingData.fetchError === '') {
      setDirty(false);
    }
  }, [props.savingData.fetchError, props.savingData.fetchCompleted, setDirty]);

  useBladeClosing(props.bladeId, () => !props.isDirty && !props.savingData.fetchError);

  const closeBlade = useCallback(() => props.closeBlade(props.bladeId), [props.bladeId]);

  useEffect(() => props.clearDeviceSettings, []);

  const footer = useCallback(
    () => (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t<string>('footer.cancel')}
        cancel={closeBlade}
        submitButtonLabel={t<string>(props.company.id ? 'footer.save' : 'footer.create')}
        submit={() => {}}
        submitInProgress={props.savingData.isFetching}
      />
    ),
    [props.company.id, props.savingData.isFetching, closeBlade, t]
  );

  return props.isFetching ? (
    <Loading />
  ) : (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(values: NCompany) => {
            const errors: FormikErrors<NCompany> = {};

            if (!values.name) {
              errors.name = t('newnity.company.field.error');
            }

            if (!values.number) {
              errors.number = t('newnity.company.field.error');
            }
            return errors;
          }}
          initialValues={props.company}
          onSubmit={(values: NCompany) => {
            props.saveCompany(values);
          }}
          render={(formikBag: FormikProps<NCompany>) => {
            return (
              <form
                className={classes.form}
                onChange={props.isDirty ? undefined : debounce(conditionalDirty, 250)}
                autoComplete="new-password"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className={classes.formContent}>
                  <CompanyDetailsFields {...props} formikBag={formikBag} />
                </div>

                <Footer>
                  <FooterSubmit
                    hasCancelButton={true}
                    cancelButtonLabel={t<string>('footer.cancel')}
                    cancel={closeBlade}
                    submitButtonLabel={t<string>(
                      props.company.id ? 'footer.save' : 'footer.create'
                    )}
                    submit={formikBag.submitForm}
                    submitInProgress={props.savingData.isFetching}
                  />
                </Footer>
              </form>
            );
          }}
        />
      </FadeIn>
    </div>
  );
};

import i18n from 'i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, BladeSelectors, withRegisterBlade } from 'react-tools';

import { DataStoreSelectors, EntityType, Visual } from '../../../../dataStore';
import { ChannelType } from '../../../../dataStore/types';
import {
  selectChannelFetchingData,
  selectCompanyId,
  selectCreatingSavingChannelData,
  selectEditingSavingChannelData
} from '../../../duck/selectors';
import { fetchLocations, fetchVisual, saveVisualData } from '../../../duck/thunks';
import { VisualsEdit, VisualsEditActions, VisualsEditProps } from './visualsEdit';

export interface VisualsEditContainerProps extends BladeProps {
  companyId: number;
  companyName: string;
  visualId: number;
  visualName: string;
}

const getEmptyVisual = (companyId: number, companyName: string): Visual => {
  return {
    id: 0,
    name: '',
    isExplicit: false,
    workgroupId: companyId,
    workgroupName: companyName,
    channelType: ChannelType.Video,
    rowVersion: '',
  };
};

const getVisual = (state: any, companyId: number, companyName: string, id: number | undefined) => {
  if (!id) {
    return getEmptyVisual(companyId, companyName);
  }

  let visual = DataStoreSelectors.ChannelSelectors.selectVisualById(state, id);

  if (!visual) {
    visual = getEmptyVisual(companyId, companyName);
    visual.id = id;
  }

  return visual;
};

const mapStateToProps: MapStateToProps<VisualsEditProps, VisualsEditContainerProps, any> = (
  state,
  ownProps
) => {
  const companyId = selectCompanyId(state);
  const visual = getVisual(state, companyId, ownProps.companyName, ownProps.visualId);

  const editBlade = BladeSelectors.selectActiveBladesDenormalized(state).find(
    (e) => e.type === VisualsEditBladeType
  );

  return {
    companyId,
    companyName: ownProps.companyName,
    visual,
    locations: visual.id ? [] : DataStoreSelectors.NLocation.selectLocationItems(state),
    savingData: ownProps.visualId ? selectEditingSavingChannelData(state) : selectCreatingSavingChannelData(state),
    fetchingData: selectChannelFetchingData(state),
    fetchingLocations: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocation),
    editBladeId: editBlade ? editBlade.id : undefined,
  };
};

const mapDispatchToProps: MapDispatchToProps<VisualsEditActions, VisualsEditContainerProps> = (
  dispatch,
  ownProps: VisualsEditContainerProps & BladeProps
) => {
  return {
    createVisual: (visual: Visual) => {
      dispatch<any>(saveVisualData(ownProps.companyId, visual));
    },
    fetchVisual: (visualId: number) => {
      dispatch<any>(fetchVisual(visualId));
    },
    fetchLocations: (workgroupId: number) => {
      dispatch<any>(fetchLocations(workgroupId));
    },
    closeBlade: () => {
      dispatch(BladeActions.closeBlade(ownProps.bladeId));
    },
  };
};

export const VisualsEditBladeType = 'NEWNITY_VISUALS_EDIT';

const bladeId = (props: VisualsEditContainerProps) =>
  props.visualId ? VisualsEditBladeType : 'NEWNITY_VISUALS_CREATE';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 300 },
  bladeType: VisualsEditBladeType,
  id: bladeId,
  title: (ownProps: VisualsEditContainerProps) =>
    ownProps.visualName ? ownProps.visualName : i18n.t('newnity.edit.visuals.blade.create.title'),
  allowMultipleInstances: false,
};

export const VisualsEditContainer = withRegisterBlade<VisualsEditContainerProps & BladeProps>(
  bladeConfig
)(connect(mapStateToProps, mapDispatchToProps)(VisualsEdit));

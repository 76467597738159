import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { BladeProps, Footer, FooterSubmit, useBladeClosing } from 'react-tools';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { GroupBox } from '../../../../components/groupBox/groupBox';
import { IdName, NLocation, Visual } from '../../../../dataStore';
import { ChannelType, DataItemsFetchStatus, NProgram } from '../../../../dataStore/types';
import { FetchState } from '../../../duck/types';
import { useStyles } from './visualsEdit.jss';

export interface SlotAssetMapping {
  [slotLocalId: number]: NProgram | undefined;
}

export interface VisualsEditProps {
  companyId: number;
  companyName: string;
  visual: Visual;
  locations: NLocation[];
  savingData: FetchState;
  fetchingData: FetchState;
  fetchingLocations: DataItemsFetchStatus;
  editBladeId?: string;
  stationEditBladeId?: string;
}

export interface VisualsEditActions {
  createVisual: (visual: Visual) => void;
  fetchVisual: (visualId: number) => void;
  closeBlade: () => void;
  fetchLocations: (workgroupId: number) => void;
}

type Props = VisualsEditProps & VisualsEditActions & BladeProps;

export const VisualsEdit: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [visualName, setVisualName] = useState(props.visual.name);
  const [visualWorkgroup, setVisualWorkgroup] = useState<IdName>({
    id: Number(props.visual.workgroupId),
    name: props.visual.workgroupName,
  });
  const [locationLevelEnabled, enableLocationLevel] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<NLocation | undefined>();
  const [selectedChannelType, setSelectedChannelType] = useState<
    ChannelType.Video | ChannelType.VOD
  >(props.visual.channelType);
  const createMode = props.visual.id === 0;
  const [submitPressed, setSubmitPressed] = useState(false);
  const { visual, fetchVisual, setDirty, savingData, locations } = props;

  // load visual data when blade opens
  useEffect(() => {
    if (props.visual.id) {
      fetchVisual(props.visual.id);

      if (selectedChannelType === props.visual.channelType) {
        setSelectedChannelType(props.visual.channelType);
      }
    }
  }, [props.visual.id, props.visual.channelType]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  const locationLevelToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      enableLocationLevel(checked);
      setSubmitPressed(false);
      if (!checked) {
        setSelectedLocation(undefined);
        setVisualWorkgroup({
          id: props.companyId,
          name: props.companyName,
        });
        return;
      }
      if (
        checked &&
        !(props.fetchingLocations.fetchComplete || props.fetchingLocations.isFetching)
      ) {
        props.fetchLocations(visual.workgroupId);
      }
    },
    [enableLocationLevel]
  );

  useEffect(() => {
    if (savingData.fetchCompleted && savingData.fetchError === '') {
      setDirty(false);
    }
  }, [savingData.fetchError, savingData.fetchCompleted]);

  //TODO: dirty
  useEffect(() => {
    if (
      props.fetchingData.isFetching ||
      props.savingData.isFetching ||
      props.savingData.fetchCompleted
    ) {
      return;
    }

    const nextDirty = !isEqual(
      {
        visualName,
      },
      {
        visualName: props.visual.name,
      }
    );

    if (nextDirty !== props.isDirty) {
      setDirty(nextDirty);
    }
  }, [
    visualName,
    selectedChannelType,
    props.visual.name,
    props.visual.channelType,
    props.isDirty,
    props.fetchingData.isFetching,
    props.savingData.isFetching,
    props.savingData.fetchCompleted,
    setDirty,
  ]);

  const isLocationValid = useCallback(() => {
    if (!locationLevelEnabled) {
      return true;
    }

    return !!selectedLocation;
  }, [locationLevelEnabled, selectedLocation]);

  const canSaveVisual = useCallback(() => visualName && isLocationValid(), [
    visualName,
    isLocationValid,
  ]);

  const onSubmit = useCallback(() => {
    if (canSaveVisual()) {
      props.createVisual({
        ...visual,
        name: visualName,
        workgroupId: visualWorkgroup.id,
        workgroupName: visualWorkgroup.name,
        channelType: selectedChannelType,
      });
    } else {
      setSubmitPressed(true);
    }
  },[
    visual,
    visualName,
    visualWorkgroup,
    selectedChannelType,
  ]);

  const onVisualNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitPressed(false);
    setVisualName(e.currentTarget.value);
  }, []);

  const onVisualLocationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSubmitPressed(false);
      const id = Number(e.target.value);
      const location = locations.find((l: NLocation) => l.workgroupId === id);
      setSelectedLocation(location);
      if (location) {
        setVisualWorkgroup({ id, name: location.name });
      }
    },
    [locations]
  );

  const onVisualTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const type = Number(e.target.value);
      setSelectedChannelType(type);
    },
    [locations]
  );

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <div className={classes.form}>
          <div className={classes.formContent}>
            <GroupBox title={t('newnity.edit.visuals.blade.create.header')} direction="column">
              <TextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                label={t('newnity.edit.visuals.blade.create.name')}
                value={visualName}
                onChange={onVisualNameChange}
                margin="normal"
                className={classes.field}
                error={submitPressed && !visualName}
                helperText={
                  submitPressed && !visualName && t('newnity.edit.visuals.blade.create.nameError')
                }
                inputProps={{ maxLength: 190 }}
              />
              {!visual.id ? (
                <>
                  <TextField
                    variant="filled"
                    label={t<string>('newnity.edit.visuals.blade.typeLabel')}
                    select
                    value={selectedChannelType}
                    onChange={onVisualTypeChange}
                  >
                    <MenuItem key={ChannelType.Video} value={ChannelType.Video}>
                      <span className={classes.locationName}>{t('newnity.device.edit.channelType.video')}</span>
                    </MenuItem>
                    <MenuItem key={ChannelType.VOD} value={ChannelType.VOD}>
                      <span className={classes.locationName}>{t('newnity.device.edit.channelType.vod')}</span>
                    </MenuItem>
                  </TextField>
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          onChange={locationLevelToggle}
                          checked={locationLevelEnabled}
                          inputProps={{
                            name: 'changeLogin',
                            id: 'changeLogin',
                          }}
                        />
                        {props.fetchingLocations.isFetching && (
                          <CircularProgress variant="indeterminate" />
                        )}
                      </>
                    }
                    label={t('newnity.edit.visuals.blade.saveAtLocationLevel')}
                  />
                  {locationLevelEnabled && props.fetchingLocations.fetchComplete && (
                    <TextField
                      variant="filled"
                      label={t<string>('newnity.edit.visuals.blade.locationLabel')}
                      select
                      value={visualWorkgroup.id}
                      error={submitPressed && !isLocationValid()}
                      helperText={
                        submitPressed &&
                        !isLocationValid() &&
                        t('newnity.edit.visuals.blade.create.locationError')
                      }
                      onChange={onVisualLocationChange}
                    >
                      {locations.map((l: NLocation) => (
                        <MenuItem key={l.id} value={l.workgroupId}>
                          <span className={classes.locationNumber}>{l.oracleNumber}</span>
                          <span className={classes.locationName}>{l.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </>
              ) : (
                <></>
              )}
            </GroupBox>
          </div>
          <Footer>
            <FooterSubmit
              hasCancelButton={true}
              cancelButtonLabel={t('footer.cancel')}
              cancel={props.closeBlade}
              submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
              submit={onSubmit}
              submitInProgress={props.savingData.isFetching}
            />
          </Footer>
        </div>
      </FadeIn>
    </div>
  );
};

import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { entityListClear } from 'app/dataStore/actions';
import {
  DeviceListBladeType,
  ImportBladeType,
  LocationListBladeType,
  NewnityActions,
  newnityStartup,
  NewnityThunks,
  selectLoadingCompanyMode,
} from 'app/newnity';
import { ZoneListBladeType } from 'app/newnity/blades/zone/list/zoneListContainer';
import { VisualsListBladeType } from 'app/newnity/blades/visuals/list/visualsListContainer';
import { NewnityState } from 'app/newnity/duck/types';
import i18next from 'i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, openBlade } from 'react-tools';

import { MAD } from '../../..';
import { BladeTypes } from '../../users';
import { FromLayoutToolbarProps } from '../layout';
import { NewnityToolbar, NewnityToolbarActions, NewnityToolbarProps } from './newnityToolbar';
import { OnHoldListBladeType } from 'app/newnity/blades/onhold/list/onHoldListContainer';

const mapStateToProps: MapStateToProps<NewnityToolbarProps, FromLayoutToolbarProps, any> = (
  state,
  ownProps
) => {
  const companies = DataStoreSelectors.NCompany.selectCompanyItemsOrdered(state);
  const currentCompany = DataStoreSelectors.NCompany.selectCompanyById(
    state,
    (state.newnity as NewnityState).currentCompany.companyId
  );
  const companiesFetchingState = DataStoreSelectors.getDataStoreItemsFetching(
    state,
    EntityType.NCompany
  );
  return {
    companyLoading: selectLoadingCompanyMode(state),
    companiesFetched: companiesFetchingState.fetchComplete,
    fetchingCompanies: companiesFetchingState.isFetching,
    inCompanyMode: (state.newnity as NewnityState).inCompanyMode,
    companies,
    currentCompanyName: currentCompany ? currentCompany.name : '',
    currentCompanyId: currentCompany ? currentCompany.id : 0,
    disableEntityButtons: (state.newnity as NewnityState).currentCompany.companyId <= 0,
    toolbarExpanded: ownProps.toolbarExpanded,
  };
};

const mapDispatchToProps: MapDispatchToProps<NewnityToolbarActions, any> = (dispatch) => {
  return {
    enterCompanyMode: (id: number = 0, name?: string) => {
      dispatch(
        BladeActions.closeBlade(undefined, [
          NewnityThunks.setCurrentCompany(id),
          NewnityActions.setInCompanyMode(true),
          NewnityThunks.openCompanyEditBlade(id, name),
        ])
      );
    },
    leaveCompanyMode: () => {
      dispatch(BladeActions.closeBlade(undefined, NewnityActions.setInCompanyMode(false)));
    },
    openCompanyEditBlade: (id: number, name: string) => {
      dispatch(BladeActions.closeBlade(undefined, NewnityThunks.openCompanyEditBlade(id, name)));
    },
    openLocationList: () => {
      dispatch(NewnityActions.setSelectedLocations([]));
      dispatch(BladeActions.closeBlade(undefined, openBlade('', LocationListBladeType, {})));
    },
    openDeviceList: () => {
      dispatch(NewnityActions.setSelectedDevices([]));
      dispatch(BladeActions.closeBlade(undefined, openBlade('', DeviceListBladeType, {})));
    },
    openZoneList: (workgroupId: number, workgroupName: string) => {
      dispatch(
        BladeActions.closeBlade(undefined, [
          entityListClear(EntityType.Channel),
          openBlade('', ZoneListBladeType, { workgroupId }),
        ])
      );
    },
    openVisualsList: (workgroupId: number, workgroupName: string) => {
      dispatch(
        BladeActions.closeBlade(undefined, [
          entityListClear(EntityType.Channel),
          openBlade('', VisualsListBladeType, { workgroupId }),
        ])
      );
    },
    openOnHoldList: (workgroupId: number, workgroupName: string) => {
      dispatch(
        BladeActions.closeBlade(undefined, [
          entityListClear(EntityType.Channel),
          openBlade('', OnHoldListBladeType, { workgroupId }),
        ])
      );
    },
    import: () => dispatch(BladeActions.closeBlade(undefined, openBlade('', ImportBladeType, {}))),
    openUsersList: (workgroupId: number, workgroupName?: string) =>
      dispatch(
        BladeActions.closeBlade(
          undefined,
          openBlade('', BladeTypes.WorkgroupUserListListBladeType, {
            workgroupId,
            title: i18next.t('users.company.blade.title'),
            workgroupName,
          })
        )
      ),
    openBlade: (parent, type, props) => dispatch(openBlade(parent, type, props)),
    newnityStartup: () => dispatch<any>(newnityStartup()),
    exitApp: () => MAD.homeFn(),
  };
};

export const NewnityToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(NewnityToolbar);

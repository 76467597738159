import i18n from 'i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, BladeSelectors, withRegisterBlade } from 'react-tools';

import { DataStoreSelectors, EntityType, OnHold } from '../../../../dataStore';
import { ChannelType } from '../../../../dataStore/types';
import {
  selectChannelFetchingData,
  selectCompanyId,
  selectCreatingSavingChannelData,
  selectEditingSavingChannelData
} from '../../../duck/selectors';
import { fetchOnHold, saveOnHoldData } from '../../../duck/thunks';
import { OnHoldEdit, OnHoldEditActions, OnHoldEditProps } from './onHoldEdit';

export interface OnHoldEditContainerProps extends BladeProps {
  companyId: number;
  companyName: string;
  onHoldId: number;
  onHoldName: string;
}

const getEmptyOnHold = (companyId: number, companyName: string): OnHold => {
  return {
    id: 0,
    name: '',
    isExplicit: false,
    workgroupId: companyId,
    workgroupName: companyName,
    channelType: ChannelType.OnHold,
    rowVersion: '',
  };
};

const getOnHold = (state: any, companyId: number, companyName: string, id: number | undefined) => {
  if (!id) {
    return getEmptyOnHold(companyId, companyName);
  }

  let onHold = DataStoreSelectors.ChannelSelectors.selectOnHoldById(state, id);

  if (!onHold) {
    onHold = getEmptyOnHold(companyId, companyName);
    onHold.id = id;
  }

  return onHold;
};

const mapStateToProps: MapStateToProps<OnHoldEditProps, OnHoldEditContainerProps, any> = (
  state,
  ownProps
) => {
  const companyId = selectCompanyId(state);
  const onHold = getOnHold(state, companyId, ownProps.companyName, ownProps.onHoldId);

  const editBlade = BladeSelectors.selectActiveBladesDenormalized(state).find(
    (e) => e.type === OnHoldEditBladeType
  );

  return {
    companyId,
    companyName: ownProps.companyName,
    onHold,
    savingData: ownProps.onHoldId ? selectEditingSavingChannelData(state) : selectCreatingSavingChannelData(state),
    fetchingData: selectChannelFetchingData(state),
    fetchingLocations: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NLocation),
    editBladeId: editBlade ? editBlade.id : undefined,
  };
};

const mapDispatchToProps: MapDispatchToProps<OnHoldEditActions, OnHoldEditContainerProps> = (
  dispatch,
  ownProps: OnHoldEditContainerProps & BladeProps
) => {
  return {
    createOnHold: (onHoldData: OnHold) => {
      dispatch<any>(saveOnHoldData(ownProps.companyId, onHoldData));
    },
    fetchOnHold: (onHoldId: number) => {
      dispatch<any>(fetchOnHold(onHoldId));
    },
    closeBlade: () => {
      dispatch(BladeActions.closeBlade(ownProps.bladeId));
    },
  };
};

export const OnHoldEditBladeType = 'NEWNITY_ON_HOLD_EDIT';

const bladeId = (props: OnHoldEditContainerProps) =>
  props.onHoldId ? OnHoldEditBladeType : 'NEWNITY_ON_HOLD_CREATE';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 300 },
  bladeType: OnHoldEditBladeType,
  id: bladeId,
  title: (ownProps: OnHoldEditContainerProps) =>
    ownProps.onHoldName ? ownProps.onHoldName : i18n.t('newnity.edit.onHold.blade.create.title'),
  allowMultipleInstances: false,
};

export const OnHoldEditContainer = withRegisterBlade<OnHoldEditContainerProps & BladeProps>(
  bladeConfig
)(connect(mapStateToProps, mapDispatchToProps)(OnHoldEdit));

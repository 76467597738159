import { NStation } from 'app/dataStore';
import {
  changeStationSlotPosition,
  cleanupStationEdit,
  stationAddSilence,
  stationFinishEdit,
  stationRemoveSlot,
  stationSetEdittingSlot,
} from 'app/newnity//duck/actions';
import {
  selectCurrentStation,
  selectCurrentChannel,
  selectSavingStationData,
  selectStationEdittingSlotId,
  selectStationFetchingData,
  selectStationSlotsAndAssets,
  selectZoneStations,
} from 'app/newnity/duck/selectors';
import { fetchStation, saveStationData } from 'app/newnity/duck/thunks';
import i18n from 'i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, openBlade, withRegisterBlade } from 'react-tools';

import { ProgramsListBladeType } from '../programs/programListContainer';
import { ProgramScheduleBladeType } from '../programSchedule/programScheduleContainer';
import { StationEdit, StationEditActions, StationEditProps } from './stationEdit';

export interface StationEditContainerProps extends BladeProps {
  stationId: number;
  stationName: string;
  zoneId: number;
}

const getEmptyStation = (): NStation => {
  return {
    id: 0,
    name: '',
    description: '',
    isDefault: false,
    isTemporary: false,
    isFirstPlaylist: false,
    channelId: 0,
    blockRemoteScrolling: false,
    slotsIds: [],
    defaultSlotId: 0,
    players: [],
  };
};

const getStation = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyStation();
  }

  let station = selectCurrentStation(state);

  if (!station || station.id !== id) {
    station = getEmptyStation();
    station.id = id;
  }

  return station;
};

const mapStateToProps: MapStateToProps<StationEditProps, StationEditContainerProps, any> = (
  state,
  ownProps
) => {
  const slotsAndAssets = selectStationSlotsAndAssets(state, ownProps.stationId);
  const station = getStation(state, ownProps.stationId);
  const noOtherStations = selectZoneStations(state).length < 1;

  if (noOtherStations) {
    station.isDefault = true;
  }

  return {
    stationId: ownProps.stationId,
    station,
    zoneId: ownProps.zoneId,
    canChangeDefault: !noOtherStations,
    isFirstPlaylist: noOtherStations,
    companyId: selectCurrentChannel(state).workgroupId,
    savingData: selectSavingStationData(state, ownProps.stationId),
    fetchingData: selectStationFetchingData(state),
    scheduleEditingSlotId: selectStationEdittingSlotId(state),
    ...slotsAndAssets,
  };
};

const mapDispatchToProps: MapDispatchToProps<StationEditActions, StationEditContainerProps> = (
  dispatch,
  ownProps
) => {
  return {
    configureWiFiSettings: () => dispatch(openBlade(ownProps.bladeId, '', {})),
    createStation: (station: NStation) => {
      dispatch<any>(saveStationData(station, ownProps.bladeId));
    },
    fetchStation: (stationId: number) => {
      dispatch<any>(fetchStation(stationId, ownProps.zoneId));
    },
    onBladeClose: (stationId: number) => {
      dispatch(
        BladeActions.closeBlade(ownProps.bladeId, [
          stationFinishEdit(),
          cleanupStationEdit(stationId),
        ])
      );
    },
    scheduleSlot: (stationId: number, slotProgramName: string, slotLocalId: number) => {
      dispatch(stationSetEdittingSlot(stationId, slotLocalId));
      dispatch(
        openBlade(ownProps.bladeId, ProgramScheduleBladeType, {
          stationId,
          localId: slotLocalId,
          slotProgramName,
        })
      );
    },
    addSilence: (stationId: number) => dispatch(stationAddSilence(stationId)),
    addSlots: (stationId: number, defaultSlot: boolean) =>
      dispatch(openBlade(ownProps.bladeId, ProgramsListBladeType, { stationId, defaultSlot })),
    deleteSlot: (stationId: number, slotLocalId: number) =>
      dispatch(stationRemoveSlot(stationId, slotLocalId)),
    changeSlotPosition: (stationId: number, oldIndex: number, newIndex: number) =>
      dispatch(changeStationSlotPosition(stationId, oldIndex, newIndex)),
  };
};

export const StationEditBladeType = 'NEWNITY_STATION_EDIT';

const bladeId = (props: StationEditContainerProps) =>
  props.stationId ? StationEditBladeType : 'NEWNITY_STATION_CREATE';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 600 },
  bladeType: StationEditBladeType,
  id: bladeId,
  title: (ownProps: StationEditContainerProps) =>
    ownProps.stationName ? ownProps.stationName : i18n.t('newnity.edit.station.blade.create.title'),
  allowMultipleInstances: false,
};

export const StationEditContainer = withRegisterBlade<StationEditContainerProps & BladeProps>(
  bladeConfig
)(connect(mapStateToProps, mapDispatchToProps)(StationEdit));

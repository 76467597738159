import { createSelector } from 'reselect';

import { Channel, ChannelType, NewnityEntityType, OnHold, Visual, Zone } from '../types';
import { getDataStore } from './genericSelectors';

export const selectChannels = (state: any) => getDataStore(state)[NewnityEntityType.Channel];

export const selectChannelItems = createSelector([selectChannels], (channels) => {
  return Object.keys(channels.items).map<Channel>((key) => channels.items[Number(key)]);
});

export const selectZoneItems = createSelector([selectChannelItems], (channels) => {
  return channels.filter((channel) => channel.channelType === ChannelType.Music) as Zone[];
});

export const selectVisualItems = createSelector([selectChannelItems], (channels) => {
  return channels.filter(
    (channel) =>
      channel.channelType === ChannelType.Video || channel.channelType === ChannelType.VOD
  ) as Visual[];
});

export const selectOnHoldItems = createSelector([selectChannelItems], (channels) => {
  return channels.filter((channel) => channel.channelType === ChannelType.OnHold) as OnHold[];
});

export const selectAudioChannelItems = createSelector([selectChannelItems], (channels) => {
  return channels.filter(
    (channel) =>
      channel.channelType === ChannelType.Music || channel.channelType === ChannelType.OnHold
  ) as Zone[];
});

export const selectZoneById = (state: any, zoneId: number) =>
  selectChannels(state).items[zoneId] as Zone;
export const selectVisualById = (state: any, visualId: number) =>
  selectChannels(state).items[visualId] as Visual;
export const selectOnHoldById = (state: any, onHoldId: number) =>
  selectChannels(state).items[onHoldId] as OnHold;

import { DeleteConfirmDialog, useDeleteConfirm } from 'app/components/deleteConfirmDialog';
import { IdName, NDevice } from 'app/dataStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnSettings, DataGrid, DataGridSelectionType, useBladeButtons } from 'react-tools';
import { useStyles } from './deviceList.jss';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

export interface DeviceListProps {
  companyId: number;
  openedFromLocation?: number;
  items: NDevice[];
  deletingDevice: boolean;
  isEditing: boolean;
}

export interface DeviceListActions {
  fetchDevices: (companyId: number) => void;
  createDevice: () => void;
  editDevice: (deviceId: number) => void;
  deleteDevice: (deviceId: number) => void;
}

type Props = DeviceListActions & DeviceListProps;

const allColumns: ColumnSettings[] = [
  { name: 'id', width: 160, filter: 'agTextColumnFilter' },
  { name: 'deviceTypeName', width: 140, filter: 'agSetColumnFilter' },
  { name: 'name', width: 190, sort: { order: 0, direction: 'asc' }, filter: 'agTextColumnFilter' },
  { name: 'serialNumber', width: 150, lockVisibility: true, filter: 'agTextColumnFilter' },
  { name: 'salesOrderNumber', width: 200, filter: 'agTextColumnFilter' },
  { name: 'description', width: 140, filter: 'agTextColumnFilter' },
  { name: 'locationName', width: 200, filter: 'agTextColumnFilter' },
  { name: 'locationNumber', width: 200, filter: 'agTextColumnFilter' },
];

const withoutLocationColumns: ColumnSettings[] = [
  { name: 'id', width: 160, filter: 'agTextColumnFilter' },
  { name: 'deviceTypeName', width: 140, filter: 'agSetColumnFilter' },
  { name: 'name', width: 190, sort: { order: 0, direction: 'asc' }, filter: 'agTextColumnFilter' },
  { name: 'serialNumber', width: 150, lockVisibility: true, filter: 'agTextColumnFilter' },
  { name: 'salesOrderNumber', width: 200, filter: 'agTextColumnFilter' },
  { name: 'description', width: 130, filter: 'agTextColumnFilter' },
];

export const DeviceList: React.FunctionComponent<Props> = (props) => {
  const [deleteConfirmDevice, setDeleteConfirmDevice] = useDeleteConfirm();
  const { fetchDevices } = props;

  const [selected, setSelected] = useState<IdName[]>([]);

  useEffect(() => {
    fetchDevices(props.companyId);
  }, [props.companyId]);

  const classes = useStyles();
  const [t] = useTranslation();

  const onDeviceDelete = useCallback(() => {
    props.deleteDevice(deleteConfirmDevice.id as number);
    setDeleteConfirmDevice({
      id: 0,
      name: '',
      confirmNeeded: false,
    });
  }, [props.deleteDevice, deleteConfirmDevice]);

  const editDevice = useCallback((): void => {
    if (selected.length) {
      props.editDevice(selected[0].id);
    }
  }, [selected, props.editDevice, setDeleteConfirmDevice]);

  const deleteDevice = useCallback(() => {
    if (selected.length > 0) {
      const device = selected[0];
      setDeleteConfirmDevice({
        id: device.id,
        name: device.name,
        confirmNeeded: true,
      });
    }
  }, [selected, setDeleteConfirmDevice]);

  const headerButtons = useMemo(
    () => [
      {
        icon: () => <Add />,
        onClick: props.createDevice,
        tooltip: 'devices.tooltip.add',
      },
      {
        icon: () => <Edit />,
        onClick: editDevice,
        tooltip: 'devices.tooltip.edit',
      },
      {
        icon: () => <Delete />,
        onClick: deleteDevice,
        tooltip: 'devices.tooltip.delete',
      },
    ],
    [props.createDevice, editDevice, deleteDevice]
  );

  useBladeButtons(headerButtons);

  const devices = useMemo(
    () =>
      props.items
        .filter((e) => !!e)
        .map((e) => ({
          ...e,
          stationName: e.stationName
            ? e.stationName
            : `${e.leftZone} - ${e.leftZonePlaylist} and ${e.rightZone} - ${e.rightZonePlaylist}`,
        })),
    [props.items]
  );

  useEffect(() => {
    setSelected([]);
  }, [devices]);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'deviceList'}
        onSelect={setSelected}
        items={devices}
        selectionType={DataGridSelectionType.Single}
        columnSettings={props.openedFromLocation ? withoutLocationColumns : allColumns}
        rowClass={classes.alignedRow}
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
        keepDefaultSelection={true}
      />
      <DeleteConfirmDialog
        important={true}
        title={t<string>('newnity.device.delete.confirm.title')}
        message={
          (props.isEditing ? t('newnity.device.delete.currently.editing') : '') +
          t('newnity.device.delete.confirm.message', {
            deviceSerial:
              selected.length > 0
                ? (selected[0] as NDevice).serialNumber || t('newnity.device.delete.confirm.serial')
                : '',
          })
        }
        open={deleteConfirmDevice.confirmNeeded}
        onDelete={onDeviceDelete}
        onCancel={() =>
          setDeleteConfirmDevice({
            id: 0,
            name: '',
            confirmNeeded: false,
          })
        }
      />
    </div>
  );
};

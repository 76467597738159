import { DataItemsFetchStatus, Zone } from 'app/dataStore/types';
import { DeleteConfirmDialog, useDeleteConfirm } from 'app/components/deleteConfirmDialog';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  ButtonItem,
  ColumnSettings,
  DataGrid,
  DataGridSelectionType,
  useBladeButtons,
  useBladeClosing,
} from 'react-tools';

import { IdName } from '../../../../dataStore';
import { useStyles } from './zoneList.jss';

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

const columnSettings: ColumnSettings[] = [
  {
    name: 'name',
    width: 190,
    sort: { order: 0, direction: 'asc' },
    lockVisibility: true,
    filter: 'agTextColumnFilter',
  },
  { name: 'workgroupName', width: 190, filter: 'agTextColumnFilter' },
];

export interface ZoneListProps {
  workgroupId: number;
  zones: Zone[];
  fetchStatus: DataItemsFetchStatus;
  isEditing: boolean;
  zoneEditBladeId?: string;
}

export interface ZoneListActions {
  fetchZones: (workgroupId: number) => void;
  createZone: () => void;
  editZone: (zoneId: number, zoneName: string, workgroupId: number) => void;
  deleteZone: (zoneId: number, zoneName: string, bladeId?: string) => void;
  onBladeClose: () => void;
}

export const ZoneList = (props: ZoneListProps & ZoneListActions & BladeProps) => {
  const classes = useStyles();
  const [deleteConfirmZone, setDeleteConfirmZone] = useDeleteConfirm();
  const [selected, setSelected] = useState<IdName[]>([]);
  const [t] = useTranslation();

  const editZone = useCallback(() => {
    if (selected.length) {
      props.editZone(selected[0].id, selected[0].name, (selected[0] as Zone).workgroupId);
    }
  }, [selected, props.editZone]);

  const deleteZone = useCallback(() => {
    if (selected.length > 0) {
      const zone = selected[0];
      setDeleteConfirmZone({
        id: zone.id,
        name: zone.name,
        confirmNeeded: true,
      });
    }
  }, [selected, setDeleteConfirmZone]);

  const onZoneDelete = useCallback(() => {
    props.deleteZone(deleteConfirmZone.id as number, deleteConfirmZone.name, props.zoneEditBladeId);
    setDeleteConfirmZone({
      id: 0,
      name: '',
      confirmNeeded: false,
    });
  }, [props.deleteZone, props.zoneEditBladeId, deleteConfirmZone]);

  const headerButtons: ButtonItem[] = useMemo(
    () => [
      {
        icon: () => <Add />,
        tooltip: 'zoneList.tooltip.add',
        onClick: props.createZone,
      },
      {
        icon: () => <Edit />,
        tooltip: 'zoneList.tooltip.edit',
        onClick: editZone,
      },
      // {
      //   icon: () => <Delete />,
      //   onClick: deleteZone,
      //   tooltip: 'zoneList.tooltip.delete',
      // },
    ],
    [props.createZone, editZone]
  );
  useBladeButtons(headerButtons);

  useBladeClosing(props.bladeId, () => !props.isDirty, props.onBladeClose);

  useEffect(() => {
    if (!props.fetchStatus.isFetching && !props.fetchStatus.fetchComplete) {
      props.fetchZones(props.workgroupId);
    }
  }, [props.workgroupId, props.fetchZones, props.fetchStatus]);

  const selectZones = useCallback((items: IdName[]) => {
    setSelected(items as IdName[]);
  }, []);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'zones'}
        idColumn={'id'}
        onSelect={selectZones}
        rowClass={classes.alignedRow}
        items={props.zones}
        selectionType={DataGridSelectionType.SingleOrNone}
        columnSettings={columnSettings}
        showSelectionColumn
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />
      <DeleteConfirmDialog
        important={true}
        title={t<string>('newnity.zone.delete.confirm.title')}
        message={
          (props.isEditing ? t('newnity.zone.delete.currently.editing') : '') +
          t('newnity.zone.delete.confirm.message', {
            zoneName:
              selected.length > 0
                ? (selected[0] as Zone).name || t('newnity.zone.delete.confirm.generic')
                : '',
          })
        }
        open={deleteConfirmZone.confirmNeeded}
        onDelete={onZoneDelete}
        onCancel={() =>
          setDeleteConfirmZone({
            id: 0,
            name: '',
            confirmNeeded: false,
          })
        }
      />
    </div>
  );
};

import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Business from '@material-ui/icons/Business';
import CloudUpload from '@material-ui/icons/CloudUpload';
import MusicNote from '@material-ui/icons/MusicNote';
import Videocam from '@material-ui/icons/Videocam';
import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import LocationOn from '@material-ui/icons/LocationOn';
import PhonePausedIcon from '@material-ui/icons/PhonePaused';
import Router from '@material-ui/icons/Router';
import Search from '@material-ui/icons/Search';
import TouchApp from '@material-ui/icons/TouchApp';

import { Autocomplete } from '../../components';
import { IdName } from '../../dataStore';
import { SearchBladeType } from '../../newnity';
import { FromLayoutToolbarProps } from '../layout';
import { useStyles } from './toolbar.jss';

export interface NewnityToolbarActions {
  enterCompanyMode: (id?: number, name?: string) => void;
  leaveCompanyMode: () => void;
  openLocationList: () => void;
  openDeviceList: () => void;
  openZoneList: (workgroupId: number, workgroupName: string) => void;
  openVisualsList: (workgroupId: number, workgroupName: string) => void;
  openOnHoldList: (workgroupId: number, workgroupName: string) => void;
  openCompanyEditBlade: (id: number, name: string) => void;
  openBlade: (parentId: string, bladeType: string, props: object) => void;
  openUsersList: (workgroupId: number, workgroupName: string) => void;
  import: () => void;
  newnityStartup: () => void;
  exitApp: () => void;
}

export interface NewnityToolbarProps {
  companyLoading: boolean;
  inCompanyMode: boolean;
  fetchingCompanies: boolean;
  companiesFetched: boolean;
  currentCompanyName: string;
  currentCompanyId: number;
  companies: IdName[];
  disableEntityButtons: boolean;
  toolbarExpanded: boolean;
}

type Props = NewnityToolbarActions & NewnityToolbarProps & FromLayoutToolbarProps;

const CompanyMode = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(true);
  const { exitApp } = props;

  const onExitApp = useCallback(() => {
    setShowTooltip(false);
    exitApp();
  }, [exitApp]);

  return (
    <React.Fragment>
      <List className={classes.list}>
        <ListItem button key={'home'} onClick={onExitApp}>
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded || !showTooltip ? (
              <Home />
            ) : (
              <Tooltip title={t<string>('home').toString()} placement="right">
                <Home />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classes.list}>
        <ListItem
          button
          key={'back'}
          onClick={() => {
            props.leaveCompanyMode();
          }}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <ArrowBack />
            ) : (
              <Tooltip title={t<string>('newnity.toolbar.close').toString()} placement="right">
                <ArrowBack />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.toolbar.close')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <Paper color="secondary" className={classes.companyName}>
          {props.toolbarExpanded ? (
            <Typography variant="button" noWrap={true}>
              {props.currentCompanyName ? props.currentCompanyName : 'New company'}
            </Typography>
          ) : (
            <Business color="action" />
          )}
        </Paper>
        <ListItem
          button
          key={'info'}
          onClick={() =>
            props.openCompanyEditBlade(props.currentCompanyId, props.currentCompanyName)
          }
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <Info />
            ) : (
              <Tooltip title={t<string>('newnity.toolbar.info').toString()} placement="right">
                <Info />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.toolbar.info')} />
        </ListItem>
        <ListItem
          button
          key={'location'}
          onClick={() => props.openLocationList()}
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <LocationOn />
            ) : (
              <Tooltip title={t<string>('newnity.locations').toString()} placement="right">
                <LocationOn />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.locations')} />
        </ListItem>
        <ListItem
          button
          key={'music'}
          disabled={props.disableEntityButtons || props.companyLoading}
          onClick={() => props.openZoneList(props.currentCompanyId, props.currentCompanyName)}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <MusicNote />
            ) : (
              <Tooltip title={t<string>('newnity.music').toString()} placement="right">
                <MusicNote />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.music')} />
        </ListItem>
        <ListItem
          button
          key={'visuals'}
          disabled={props.disableEntityButtons || props.companyLoading}
          onClick={() => props.openVisualsList(props.currentCompanyId, props.currentCompanyName)}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <Videocam />
            ) : (
              <Tooltip title={t<string>('newnity.visuals').toString()} placement="right">
                <Videocam />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.visuals')} />
        </ListItem>
        <ListItem
          button
          key={'onhold'}
          disabled={props.disableEntityButtons || props.companyLoading}
          onClick={() => props.openOnHoldList(props.currentCompanyId, props.currentCompanyName)}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <PhonePausedIcon />
            ) : (
              <Tooltip title={t<string>('newnity.onHold').toString()} placement="right">
                <PhonePausedIcon />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.onHold')} />
        </ListItem>
        <ListItem
          button
          key={'devices'}
          onClick={() => props.openDeviceList()}
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <Router />
            ) : (
              <Tooltip title={t<string>('newnity.devices').toString()} placement="right">
                <Router />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.devices')} />
        </ListItem>
        <ListItem
          button
          key={'users'}
          onClick={() => props.openUsersList(props.currentCompanyId, props.currentCompanyName)}
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <AccountBox />
            ) : (
              <Tooltip title={t<string>('workgroup.users').toString()} placement="right">
                <AccountBox />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('workgroup.users')} />
        </ListItem>
        <ListItem
          button
          key={'import'}
          onClick={() => props.import()}
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <CloudUpload />
            ) : (
              <Tooltip title={t<string>('newnity.import').toString()} placement="right">
                <CloudUpload />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.import')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const SearchMode = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(true);

  const { exitApp } = props;

  // we use this wrapper for exiting because otherwise the
  // 'Home' button tooltip would persist after exiting to v6
  const handleExitApp = useCallback(() => {
    setShowTooltip(false);
    exitApp();
  }, [exitApp]);

  return (
    <React.Fragment>
      <List>
        <ListItem className={classes.list} button key={'home'} onClick={handleExitApp}>
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded || !showTooltip ? (
              <Home />
            ) : (
              <Tooltip title={t<string>('home').toString()} placement="right">
                <Home />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <ListItem button={!props.toolbarExpanded as any} key={'jump'} onClick={props.expandToolbar}>
          <ListItemIcon className={classes.icon}>
            {props.fetchingCompanies ? (
              <CircularProgress color="inherit" size={24} />
            ) : props.toolbarExpanded ? (
              <TouchApp />
            ) : (
              <Tooltip
                title={t<string>('newnity.toolbar.jump.tooltip').toString()}
                placement="right"
              >
                <TouchApp />
              </Tooltip>
            )}
          </ListItemIcon>
          <Autocomplete
            variant="standard"
            items={props.companies}
            minCharInput={3}
            onChange={(selectedCompany: IdName) => {
              if (selectedCompany && selectedCompany.id) {
                props.enterCompanyMode(selectedCompany.id, selectedCompany.name);
              }
            }}
            textFieldProps={{
              placeholder: t('newnity.toolbar.jump'),
              disabled: props.fetchingCompanies || !props.companiesFetched,
              className: classes.companyJump,
            }}
          />
        </ListItem>
        <ListItem button key={'search'} onClick={() => props.openBlade('', SearchBladeType, {})}>
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <Search />
            ) : (
              <Tooltip
                title={t<string>('newnity.toolbar.search.advanced').toString()}
                placement="right"
              >
                <Search />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.toolbar.search')} />
        </ListItem>
        <ListItem
          button
          key={'add'}
          onClick={() => {
            props.enterCompanyMode();
          }}
        >
          <ListItemIcon className={classes.icon}>
            {props.toolbarExpanded ? (
              <Add />
            ) : (
              <Tooltip title={t<string>('newnity.toolbar.add').toString()} placement="right">
                <Add />
              </Tooltip>
            )}
          </ListItemIcon>
          <ListItemText primary={t<string>('newnity.toolbar.add')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export const NewnityToolbar = (props: Props) => {
  const { newnityStartup } = props;

  useEffect(() => {
    newnityStartup();
  }, [newnityStartup]);

  return props.inCompanyMode ? <CompanyMode {...props} /> : <SearchMode {...props} />;
};

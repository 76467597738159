import { EntityType } from 'app/dataStore';
import { ColumnSettings } from 'react-tools';

export const columnSettings: { [key: string]: ColumnSettings[] } = {
  [EntityType.NCompanySearchResult]: [
    { name: 'number', width: 200, filter: 'agTextColumnFilter' },
    {
      name: 'name',
      width: 190,
      sort: { order: 0, direction: 'asc' },
      lockVisibility: true,
      filter: 'agTextColumnFilter',
    },
    { name: 'city', width: 150, filter: 'agTextColumnFilter' },
    { name: 'country', width: 150, filter: 'agTextColumnFilter' },
  ],
  [EntityType.NLocationSearchResult]: [
    { name: 'locationNumber', width: 220, filter: 'agTextColumnFilter' },
    {
      name: 'locationName',
      width: 190,
      lockVisibility: true,
      sort: { order: 0, direction: 'asc' },
      filter: 'agTextColumnFilter',
    },
    { name: 'siteNumber', width: 150, filter: 'agTextColumnFilter' },
    { name: 'companyName', width: 150, filter: 'agTextColumnFilter' },
  ],
  [EntityType.NDeviceSearchResult]: [
    { name: 'id', width: 160, filter: 'agTextColumnFilter' },
    {
      name: 'serial',
      width: 180,
      lockVisibility: true,
      sort: { order: 0, direction: 'asc' },
      filter: 'agTextColumnFilter',
    },
    { name: 'salesOrder', width: 190, filter: 'agTextColumnFilter' },
    { name: 'deviceDescription', width: 180, filter: 'agTextColumnFilter' },
    { name: 'locationName', width: 180, filter: 'agTextColumnFilter' },
    { name: 'locationNumber', width: 180, filter: 'agTextColumnFilter' },
    { name: 'siteNumber', width: 140, filter: 'agTextColumnFilter' },
    { name: 'companyName', width: 150, filter: 'agTextColumnFilter' },
  ],
};

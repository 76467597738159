import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './results.jss';

export const Placeholder = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.centerContainer}>
      <div>{t<string>('newnity.search.resultsPlaceholder')}</div>
    </div>
  );
};

import React from 'react';
import { DataGrid, DataGridSelectionType, GridCard } from 'react-tools';

import { LocationListBladeType } from '../../..';
import { EntityType, IdName } from '../../../../dataStore';
import { NDeviceSearchResult, NLocationSearchResult } from '../../../../dataStore/types';
import { DeviceBladeType } from '../../device/edit/deviceContainer';
import { DeviceListBladeType } from '../../device/list/deviceListContainer';
import { LocationEditBladeType } from '../../location/edit/locationEditContainer';
import { columnSettings } from './columnSettings';
import { Loading } from './loading';
import { NoResults } from './noresults';
import { Placeholder } from './placeholder';
import { useStyles } from './results.jss';

export interface SearchResultsActions {
  enterCompanyMode: (id: number, searchBladeId?: string) => void;
  openCompanyEditBlade: (id: number, name: string) => void;
  openBlade: (parentId: string, bladeType: string, props: object) => void;
  setSelectedLocations: (items: IdName[]) => void;
  setSelectedDevices: (items: IdName[]) => void;
}

export interface SearchResultsProps {
  searchedEntity?: EntityType;
  isFetching: boolean;
  items: IdName[];
  searchBladeId?: string;
}

export const SearchResults = (props: SearchResultsProps & SearchResultsActions) => {
  const classes = useStyles();

  const onSelect = (item: IdName) => {
    switch (props.searchedEntity) {
      case EntityType.NCompanySearchResult:
        onCompanySelected(item);
        break;
      case EntityType.NLocationSearchResult:
        onLocationSelected(item);
        break;
      case EntityType.NDeviceSearchResult:
        onDeviceSelected(item);
        break;
    }
  };

  const onCompanySelected = (item: IdName) => {
    props.enterCompanyMode(item.id, props.searchBladeId);
    props.openCompanyEditBlade(item.id, item.name);
  };

  const onLocationSelected = (item: IdName) => {
    const locationItem = item as NLocationSearchResult;
    props.enterCompanyMode(locationItem.companyId, props.searchBladeId);
    props.openBlade('', LocationListBladeType, {});
    props.setSelectedLocations([{ id: locationItem.id, name: locationItem.locationName }]);
    props.openBlade(LocationListBladeType, LocationEditBladeType, {
      locationSiteId: locationItem.id,
      locationName: locationItem.locationName,
    });
  };

  const onDeviceSelected = (item: IdName) => {
    const deviceItem = item as NDeviceSearchResult;
    props.enterCompanyMode(deviceItem.companyId, props.searchBladeId);
    props.openBlade('', DeviceListBladeType, {});
    props.setSelectedDevices([{ id: deviceItem.id, name: deviceItem.id.toString() }]);
    props.openBlade(DeviceListBladeType, DeviceBladeType, {
      deviceId: deviceItem.id,
    });
  };

  return props.searchedEntity === undefined ? (
    <Placeholder />
  ) : props.isFetching ? (
    <Loading />
  ) : props.items.length === 0 ? (
    <NoResults />
  ) : (
    <div className={classes.container}>
      <DataGrid
        identifier={'search'}
        rowClass={classes.alignedRow}
        items={props.items}
        onSelect={(items) => onSelect(items[0])}
        onRowClicked={(item) => onSelect(item)}
        selectionType={DataGridSelectionType.None}
        showSelectionColumn={false}
        columnSettings={columnSettings[props.searchedEntity]}
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />
    </div>
  );
};

import React, { useCallback, useMemo } from 'react';

import { WorkgroupProfileType } from 'app/newnity/duck/types';
import {
  batch,
  connect,
  MapDispatchToProps,
  MapStateToProps,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  BladeActions,
  BladeProps,
  BladeSelectors,
  editBtn,
  openBlade,
  withRegisterBlade,
} from 'react-tools';

import { UserSelectors } from '../../..';
import {
  fetchWelcomeEmailTemplates,
  fetchWorkgroupUsers,
  removeUsersFromWorkgroup,
  userEditingStarting,
} from '../../../duck/actions';
import { AddWorkgroupUsersBladeType } from '../../addWorkgroupUsers/addWorkgroupUsersContainer';
import { EditUserBladeType } from '../../createUser';
import { WorkgroupUserList, WorkgroupUsersListProps } from './workgroupUsersList';

interface WorkgroupUserListContainerProps extends BladeProps {
  workgroupId: number;
  title: string;
  workgroupName: string;
  profileType?: WorkgroupProfileType;
  hideCreateButton?: boolean;
}

const mapStateToProps: MapStateToProps<
  WorkgroupUsersListProps,
  WorkgroupUserListContainerProps,
  any
> = (state, ownProps) => {
  const removingUser = UserSelectors.selectWorkgroupUserRemove(state);
  const isFetching = UserSelectors.selectWorkgroupUsersFetching(state);
  const isEditing = UserSelectors.userIsEditing(state);
  return {
    workgroupId: ownProps.workgroupId,
    profileType: ownProps.profileType ? ownProps.profileType : WorkgroupProfileType.NewnityDefault,
    users: UserSelectors.selectWorkgroupUsers(state),
    removingUser: removingUser.isRemoving && !removingUser.removingComplete,
    isFetching,
    isEditing,
    hideCreateButton: ownProps.hideCreateButton,
  };
};

export const WorkgroupUserListListBladeType = 'WORKGROUP_USER_LIST';

const bladeConfig = {
  size: { defaultWidth: 840, minWidth: 840 },
  bladeType: WorkgroupUserListListBladeType,
  id: (_: any) => WorkgroupUserListListBladeType,
  title: (ownProps: WorkgroupUserListContainerProps) => ownProps.title,
  allowMultipleInstances: false,
};

const WorkgroupUserListContainer = (props: WorkgroupUserListContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const removingUser = useSelector(UserSelectors.selectWorkgroupUserRemove);
  const isFetching = useSelector(UserSelectors.selectWorkgroupUsersFetching);
  const isEditing = useSelector(UserSelectors.userIsEditing);
  const users = useSelector(UserSelectors.selectWorkgroupUsers);
  const blades = useSelector(BladeSelectors.selectActiveBladesDenormalized);

  const addUserListBlade = useMemo(
    () => blades.find((e) => e.type === AddWorkgroupUsersBladeType),
    [blades]
  );

  const fetchUsers = useCallback(() => dispatch<any>(fetchWorkgroupUsers(props.workgroupId)), [
    props.workgroupId,
    dispatch,
  ]);

  const removeUsers = useCallback(
    (userIds: number[]) =>
      dispatch<any>(
        removeUsersFromWorkgroup(props.workgroupId, userIds, WorkgroupUserListListBladeType)
      ),
    [props.workgroupId]
  );

  const openCreateOrEditBlade = useCallback(
    (props: any) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          props.bladeId,
          openBlade(props.bladeId, EditUserBladeType, props)
        )
      ),
    [dispatch, addUserListBlade]
  );

  const openEditUser = useCallback(
    (userId: number, firstName: string, lastName: string) => {
      openCreateOrEditBlade({
        userId,
        firstName,
        lastName,
        workgroupId: props.workgroupId,
        profileType: props.profileType ? props.profileType : WorkgroupProfileType.NewnityDefault,
      });

      dispatch<any>(userEditingStarting());
      dispatch<any>(fetchWelcomeEmailTemplates());
    },
    [dispatch, props.workgroupId, props.profileType, addUserListBlade]
  );

  const openCreateUser = useCallback(() => {
    openCreateOrEditBlade({
      userId: 0,
      workgroupId: props.workgroupId,
      workgroupName: props.workgroupName,
      automaticallyAdd: true,
      profileType: props.profileType ? props.profileType : WorkgroupProfileType.NewnityDefault,
      hideCreateButton: props.hideCreateButton,
    });
  }, [
    props.workgroupId,
    props.workgroupName,
    props.hideCreateButton,
    props.profileType,
    addUserListBlade,
  ]);

  const openUserList = useCallback(
    () =>
      dispatch(
        BladeActions.closeChildrenBlades(
          props.bladeId,
          openBlade(props.bladeId, AddWorkgroupUsersBladeType, {
            workgroupId: props.workgroupId,
            workgroupName: props.workgroupName,
            profileType: props.profileType
              ? props.profileType
              : WorkgroupProfileType.NewnityDefault,
            hideCreateButton: props.hideCreateButton,
          })
        )
      ),
    [props.workgroupName, props.workgroupId, props.profileType, props.hideCreateButton, dispatch]
  );

  return (
    <WorkgroupUserList
      workgroupId={props.workgroupId}
      profileType={props.profileType ? props.profileType : WorkgroupProfileType.NewnityDefault}
      users={users}
      removingUser={removingUser.isRemoving && !removingUser.removingComplete}
      isFetching={isFetching}
      isEditing={isEditing}
      hideCreateButton={props.hideCreateButton}
      fetchUsers={fetchUsers}
      openEditUser={openEditUser}
      openNewUserBlade={openCreateUser}
      openUsersList={openUserList}
      removeUsers={removeUsers}
    />
  );
};

export const DevicesContainer = withRegisterBlade(bladeConfig)(WorkgroupUserListContainer);

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {},
    progress: {
      marginRight: theme.spacing(1),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    lowercaseText: { textTransform: 'lowercase' },
    warningMessage: {
      marginTop: theme.spacing(3),
    },
    errorMessage: {
      marginTop: theme.spacing(1),
      color: "red",
    },
    bottomSpacing: {
      marginBottom: theme.spacing(3)
    }
  });
};

export const useStyles = makeStyles(styles);

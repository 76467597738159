import 'react-datepicker/dist/react-datepicker.css';

import { GroupBox } from 'app/components/groupBox/groupBox';
import { Schedule } from 'app/newnity/duck/types';
import { debounce } from 'debounce';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { BladeProps, Footer, FooterSubmit, useBladeClosing } from 'react-tools';

import { TextField, Typography } from '@material-ui/core';

import { DaysForm } from './daysForm';
import { EntireDaySwitch } from './entireDaySwitch';
import { useStyles } from './programSchedule.jss';
import { HMS, TimeInput } from './timeInput';

export interface DaysAvailability {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface ProgramScheduleProps {
  originalSchedule: Schedule;
  startDate: Date;
  endDate: Date;
  startTime: HMS;
  endTime: HMS;
  days: DaysAvailability;
}

export interface ProgramScheduleActions {
  setSchedule: (values: ProgramScheduleProps) => void;
  closeBlade: () => void;
  onClose: () => void;
}

const isEntireDay = (startTime: HMS, endTime: HMS) =>
  startTime.hour === 0 &&
  startTime.minute === 0 &&
  startTime.second === 0 &&
  endTime.hour === 23 &&
  endTime.minute === 59 &&
  endTime.second === 59;

export const ProgramSchedule = (
  props: ProgramScheduleProps & ProgramScheduleActions & { slotProgramName: string } & BladeProps
) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [entireDay, setEntireDay] = useState(isEntireDay(props.startTime, props.endTime));
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [startTime, setStartTime] = useState(props.startTime);
  const [endTime, setEndTime] = useState(props.endTime);
  const [days, setDays] = useState(props.days);
  const [isDirty, setIsDirty] = useState(false);
  const [savePressed, setSavePressed] = useState(false);
  const [startDateError, setStartDateError] = useState(false);

  const { setDirty } = props;

  useBladeClosing(
    props.bladeId,
    () => (savePressed ? true : !props.isDirty),
    () => {
      props.onClose();
    }
  );

  useEffect(() => {
    const nextDirty = !isEqual(
      {
        startDate,
        endDate,
        startTime,
        endTime,
        days,
      },
      {
        startDate: props.startDate,
        endDate: props.endDate,
        startTime: props.startTime,
        endTime: props.endTime,
        days: props.days,
      }
    );

    if (isDirty !== nextDirty) {
      setIsDirty(nextDirty);
      setDirty(nextDirty);
    }
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    days,
    setDirty,
    isDirty,
    props.startDate,
    props.endDate,
    props.startTime,
    props.endTime,
    props.days,
  ]);

  const handleStartTimeChange = useCallback(debounce(setStartTime, 250), []);
  const handleEndTimeChange = useCallback(debounce(setEndTime, 250), []);

  const handleDaysChange = useCallback(
    (changes: Array<{ day: keyof DaysAvailability; checked: boolean }>) => {
      const newDays = changes.reduce<DaysAvailability>(
        (daysObject, item) => {
          daysObject[item.day] = item.checked;
          return daysObject;
        },
        { ...days }
      );
      setDays(newDays);
    },
    [days]
  );

  const handleEntireDayChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEntireDay(e.target.checked);
    setStartTime({ hour: 0, minute: 0, second: 0 });
    setEndTime({ hour: 23, minute: 59, second: 59 });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (startDate > endDate) {
      return;
    }

    const atLeastOneDaySelected =
      days.friday ||
      days.monday ||
      days.saturday ||
      days.sunday ||
      days.thursday ||
      days.tuesday ||
      days.wednesday;

    if (!atLeastOneDaySelected) {
      return;
    }

    props.setSchedule({
      startDate,
      endDate,
      startTime,
      endTime,
      days,
      originalSchedule: props.originalSchedule,
    });

    if (savePressed) {
      props.setDirty(false);
      props.closeBlade();
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.container}>
        <GroupBox title={t<string>('newnity.time')}>
          <DatePicker
            calendarClassName={classes.calendar}
            customInput={
              <TextField
                error={startDateError}
                margin="normal"
                label={t<string>('newnity.schedule.startDate')}
                helperText={startDateError ? t('newnity.schedule.startDateEndDate') : ''}
              />
            }
            maxDate={new Date(2099, 11, 31)}
            selected={startDate}
            onChange={(d) => {
              if (d) {
                setStartDate(d as any);

                if (d > endDate) {
                  setStartDateError(true);
                } else {
                  setStartDateError(false);
                }
              }
            }}
          />
          <DatePicker
            calendarClassName={classes.calendar}
            customInput={
              <TextField margin="normal" label={t<string>('newnity.schedule.endDate')} />
            }
            maxDate={new Date(2099, 11, 31)}
            selected={endDate}
            onChange={(d) => {
              if (d) {
                setEndDate(d as any);

                if (startDate > d) {
                  setStartDateError(true);
                } else {
                  setStartDateError(false);
                }
              }
            }}
          />
          <EntireDaySwitch entireDay={entireDay} onChange={handleEntireDayChange} />
          <TimeInput
            label={t<string>('newnity.schedule.startTime')}
            defaultValue={startTime}
            disabled={entireDay}
            onChange={handleStartTimeChange}
          />
          <TimeInput
            label={t<string>('newnity.schedule.endTime')}
            defaultValue={endTime}
            disabled={entireDay}
            onChange={handleEndTimeChange}
          />
        </GroupBox>
        <DaysForm days={days} onChange={handleDaysChange} />
      </div>
      <Footer>
        <FooterSubmit
          hasCancelButton={true}
          cancelButtonLabel={t<string>('footer.cancel')}
          cancel={props.closeBlade}
          submitButtonLabel={t<string>('footer.save')}
          submit={() => {}}
          onSubmitPress={() => setSavePressed(true)}
          submitInProgress={false}
        />
      </Footer>
    </form>
  );
};

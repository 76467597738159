import { Schedule, StationScheduleSlot } from 'app/newnity/duck/types';
import { selectedDaysFromDiffusion, timeFormat } from 'app/utils/dateTime';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Measure, { ContentRect } from 'react-measure';
import {
    SortableContainer, SortableContainerProps, SortableElement, SortableHandle
} from 'react-sortable-hoc';
import { FixedSizeList, ListChildComponentProps, ListItemKeySelector } from 'react-window';

import { IconButton, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import DragIndicator from '@material-ui/icons/DragIndicator';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VolumeOff from '@material-ui/icons/VolumeOff';

import { SlotAssetMapping } from './stationEdit';
import { useStyles } from './stationProgramList.jss';

export interface SlotsAndAssets {
  slots: StationScheduleSlot[];
  assets: SlotAssetMapping;
}

interface StationProgramListItemProps extends SlotsAndAssets {
  onScheduleClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  schedulingSlotId: number;
}

export interface StationProgramListProps extends SlotsAndAssets {
  onScheduleEdit: (slotLocalId: number, slotName: string) => void;
  onDelete: (slotLocalId: number, slotName: string) => void;
  schedulingSlotId: number;
}

const DragHandle = SortableHandle(() => (
  <DragIndicator style={{ cursor: 'pointer', color: '#bdbdbd' }} />
));

const DiffusionDays: React.FunctionComponent<{ schedule: Schedule }> = React.memo((props) => {
  const classes = useStyles();
  const diffusionDays = props.schedule ? props.schedule.diffusionDays : 0;
  const days = selectedDaysFromDiffusion(diffusionDays);
  return (
    <>
      {days.map((d) => (
        // <div className={classNames(classes.itemDayContainer, { [classes.itemSelectedDayContainer]: d.selected })}>
        <Typography
          className={classNames(classes.itemDay, { [classes.itemSelectedDay]: d.selected })}
          key={d.day}
          variant="caption"
        >
          {d.label}
        </Typography>
        // </div>
      ))}
    </>
  );
});

const Availability: React.FunctionComponent<{ schedule: Schedule }> = React.memo((props) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  return (
    <>
      <Typography variant="caption" component="p">
        {`${format(props.schedule.startTime, timeFormat)} - ${format(
          props.schedule.endTime,
          timeFormat
        )}`}
      </Typography>
      <Typography variant="caption" component="p">
        {`${props.schedule.startDate.toLocaleDateString(
          undefined,
          options
        )} - ${props.schedule.endDate.toLocaleDateString(undefined, options)}`}
      </Typography>
    </>
  );
});

const StationProgramListItem = SortableElement<ListChildComponentProps>(
  React.memo((props: ListChildComponentProps) => {
    const [t] = useTranslation();
    const classes = useStyles();
    let index = props.index;
    if (index === undefined) {
      index = Number(props.style.top) / 64;
    }
    const data = props.data as StationProgramListItemProps;
    const slot = data.slots[index];
    const asset = data.assets[slot.localId];
    const label = slot.isSilence
      ? t('newnity.edit.station.blade.slots.silence')
      : asset
      ? asset.name
      : t('newnity.edit.station.blade.slots.empty');
    return (
      <div style={props.style} className={classes.item}>
        <DragHandle />
        <div className={classes.itemLabel}>
          <Typography
            className={classNames({ [classes.emptySlot]: asset === undefined })}
            variant="body1"
            component="p"
            noWrap
          >
            {label}
          </Typography>
          <DiffusionDays schedule={slot.schedule} />
        </div>
        <div
          className={classNames(classes.itemLabel, {
            [classes.itemLabelSilenceIconCompensation]: slot.isSilence,
          })}
        >
          <Availability schedule={slot.schedule} />
        </div>
        {slot.isSilence && <VolumeOff className={classes.silenceIcon} />}
        <IconButton
          // size="small"
          className={classes.hiddenButton}
          id={`schedule_${slot.localId}`}
          name={label}
          onClick={data.onScheduleClick}
        >
          <ScheduleIcon />
        </IconButton>
        <IconButton
          // size="small"
          disabled={slot.localId === data.schedulingSlotId}
          className={classes.hiddenButton}
          id={`delete_${slot.localId}`}
          name={label}
          onClick={data.onDeleteClick}
        >
          <Delete />
        </IconButton>
      </div>
    );
  })
);

interface SortableListProps {
  size: { height: number; width: number };
  onScheduleClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  itemKey: ListItemKeySelector;
}

export const SortableList = SortableContainer(
  (props: SortableContainerProps & StationProgramListProps & SortableListProps) => {
    return (
      <FixedSizeList
        height={props.size.height}
        width={props.size.width}
        itemCount={props.slots.length}
        itemData={{
          assets: props.assets,
          slots: props.slots,
          onScheduleClick: props.onScheduleClick,
          onDeleteClick: props.onDeleteClick,
          schedulingSlotId: props.schedulingSlotId,
        }}
        itemSize={64}
        itemKey={props.itemKey}
        overscanCount={5}
      >
        {StationProgramListItem}
      </FixedSizeList>
    );
  }
);

export const StationProgramList = (props: SortableContainerProps & StationProgramListProps) => {
  const classes = useStyles({});
  const [size, setSize] = useState({ width: 530, height: 300 });

  const onScheduleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const slotLocalId = Number(e.currentTarget.id.substring(9));
    const slotName = e.currentTarget.name;
    if (props.schedulingSlotId >= 0) {
      return;
    }
    props.onScheduleEdit(slotLocalId, slotName);
  };
  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const slotLocalId = Number(e.currentTarget.id.substring(7));
    const slotName = e.currentTarget.name;
    props.onDelete(slotLocalId, slotName);
  };

  const sizeChanged = (contentRect: ContentRect) => {
    if (contentRect && contentRect.bounds) {
      setSize({
        width: contentRect.bounds.width,
        height: contentRect.bounds.height,
      });
    }
  };

  const itemKey = (index: number, data: SlotsAndAssets) => {
    return `${data.slots[index].id}-${data.slots[index].localId}`;
  };

  return (
    <Measure bounds onResize={sizeChanged}>
      {({ measureRef }) => (
        <div ref={measureRef} className={classes.listMeasure}>
          <SortableList
            {...props}
            onScheduleClick={onScheduleClick}
            onDeleteClick={onDeleteClick}
            itemKey={itemKey}
            size={size}
          />
        </div>
      )}
    </Measure>
  );
};

import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { deleteOnHold, fetchOnHoldChannels } from 'app/newnity/duck/thunks';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  openBlade,
  withRegisterBlade,
} from 'react-tools';

import { selectIsEditingChannel } from '../../../duck/selectors';
import { OnHoldEditBladeType } from '../edit/onHoldEditContainer';
import { OnHoldList } from './onHoldList';

interface OnHoldListContainerProps {
  workgroupId: number;
  workgroupName: string;
}

export const OnHoldListBladeType = 'NEWNITY_ON_HOLD_LIST';

const bladeConfig = {
  size: { defaultWidth: 520, minWidth: 320 },
  bladeType: OnHoldListBladeType,
  id: (_: any) => OnHoldListBladeType,
  title: (_: any) => i18next.t('newnity.onHold.list.blade.title'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroupId,
  workgroupName,
  ...bladeProps
}: OnHoldListContainerProps & BladeProps) => {
  const dispatch = useDispatch();
  const onHoldList = useSelector(DataStoreSelectors.ChannelSelectors.selectOnHoldItems);
  const fetchStatus = useSelector((state) =>
    DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.Channel)
  );

  const isEditing = useSelector(selectIsEditingChannel);

  const fetchOnHoldChannelsForCompany: (workgroupId: number) => any = useCallback(
    (workgroupId: number) => dispatch<any>(fetchOnHoldChannels(workgroupId)),
    [workgroupId, dispatch]
  );

  const createOnHoldChannel = useCallback(
    () =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, OnHoldEditBladeType, {
            onHoldId: 0,
            onHoldName: '',
            companyId: workgroupId,
            companyName: workgroupName,
          })
        )
      ),
    [dispatch, workgroupId, workgroupName]
  );

  const editOnHold = useCallback(
    (onHoldId: number, onHoldName: string, workgroupId: number) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          bladeProps.bladeId,
          openBlade(bladeProps.bladeId, OnHoldEditBladeType, {
            onHoldId: onHoldId,
            onHoldName: onHoldName,
            companyId: workgroupId,
          })
        )
      ),
    [dispatch, workgroupId]
  );

  const deleteOnHoldById = useCallback(
    (onHoldId: number, onHoldName: string) => dispatch<any>(deleteOnHold(onHoldId, onHoldName)),
    [dispatch]
  );

  return (
    <OnHoldList
      workgroupId={workgroupId}
      onHoldList={onHoldList}
      fetchStatus={fetchStatus}
      createOnHold={createOnHoldChannel}
      editOnHold={editOnHold}
      fetchOnHoldList={fetchOnHoldChannelsForCompany}
      isEditing={isEditing}
      deleteOnHold={deleteOnHoldById}
    />
  );
};

export const OnHoldListContainer = withRegisterBlade<OnHoldListContainerProps & BladeProps>(
  bladeConfig
)(ContainerComponent);

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
  container: {
    flex: "1",
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  content: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(2),
  },
  footer: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  stationFieldLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(1)
  },
  stationFieldRight: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
});

export const useStyles = makeStyles(styles);


import { ImportSkippedRow } from 'app/dataStore';
import { connect, MapDispatchToProps } from 'react-redux';
import {
  ColumnSettings,
  DataGridSelectionType,
  openBlade,
  SelectionBladeContainerProps,
  SelectionListBladeType,
} from 'react-tools';

import { ImportSummary, ImportSummaryActions, ImportSummaryProps } from './importSummary';

export interface ImportSummaryContainerProps extends ImportSummaryProps {
  parentBladeId: string;
  excludeColumnNameFromDetails?: boolean;
}
const mapDispatchToProps: MapDispatchToProps<ImportSummaryActions, ImportSummaryContainerProps> = (
  dispatch,
  ownProps
) => { 
  const genericErrorColumnSettigns: ColumnSettings[] = [
    {
      name: 'row',
      width: 50,
      sort: {
        direction: 'asc',
        order: 0,
      },
    },
    {
      name: 'name',
      width: 200,
    },
  ];

  if (!ownProps.excludeColumnNameFromDetails) {
    genericErrorColumnSettigns.push({ name: 'columnName', width: 100 });
  }
  
  return {
    showDetails: (title: string, data: ImportSkippedRow[]) =>
      dispatch(
        openBlade<SelectionBladeContainerProps>(ownProps.parentBladeId, SelectionListBladeType, {
          title,
          items: data,
          selectionType: DataGridSelectionType.None,
          columns: genericErrorColumnSettigns,
          identifier: 'importSummary',
          onClose: () => {},
        })
      ),
  };
};

export const ImportSummaryContainer = connect(null, mapDispatchToProps)(ImportSummary);

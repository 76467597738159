import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, withRegisterBlade } from 'react-tools';

import { addSelectedPrograms } from '../../../../duck/actions';
import { ProgramsList, ProgramsListActions, ProgramsListProps } from './programList';

interface ProgramsListContainerProps {
  stationId: number;
  defaultSlot: boolean;
}

const mapStateToProps: MapStateToProps<
  ProgramsListProps,
  ProgramsListContainerProps & BladeProps,
  any
> = (state, props) => {
  const tempPrograms = DataStoreSelectors.NProgram.selecProgramItems(state);
  const _ = {
    ...props,
    items: tempPrograms,
    itemsFetching: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.NProgram)
      .isFetching,
  };

  return _;
};

const mapDispatchToProps: MapDispatchToProps<
  ProgramsListActions,
  ProgramsListContainerProps & BladeProps
> = (dispatch, ownProps) => {
  return {
    forceCloseBlade: () => {
      dispatch(BladeActions.forceCloseBlade(ownProps.bladeId));
    },
    addPrograms: (items, stationId: number, defaultSlot: boolean) =>
      dispatch(addSelectedPrograms(items, stationId, defaultSlot)),
  };
};

export const ProgramsListBladeType = 'NEWNITY_PROGRAM_LIST';

const bladeConfig = {
  size: { defaultWidth: 400, minWidth: 200 },
  bladeType: ProgramsListBladeType,
  id: (_: any) => ProgramsListBladeType,
  title: (_: any) => 'Programs',
  allowMultipleInstances: false,
};

export const ProgramsListContainer = withRegisterBlade<ProgramsListContainerProps & BladeProps>(
  bladeConfig
)(connect(mapStateToProps, mapDispatchToProps)(ProgramsList));

import React, { useMemo } from 'react';

import { WorkgroupProfileType } from 'app/newnity/duck/types';
import { getWelcomeEmailTemplates, userSaving } from 'app/users/duck/selectors';
import {
  connect,
  MapDispatchToProps,
  MapStateToProps,
  useDispatch,
  useSelector,
} from 'react-redux';
import { BladeActions, BladeProps, withRegisterBlade } from 'react-tools';

import { DataStoreSelectors, User } from '../../../../dataStore';
import {
  fetchUser,
  fetchUserNewnityApps,
  fetchWelcomeEmailTemplates,
  saveUser,
  userEditingFinished,
} from '../../../duck/actions';
import { getEmptyUser, getUserForm, UserDto } from '../../../duck/types';
import { EditUser, EditUserActions, EditUserProps } from './editUser';

interface EditUserContainerProps extends BladeProps {
  userId: number;
  firstName: string;
  lastName: string;
  workgroupId: number;
  workgroupName: string;
  automaticallyAdd: boolean;
  profileType?: WorkgroupProfileType;
}

const getUser = (state: any, id?: number): User => {
  if (!id) {
    return getEmptyUser();
  }

  let user = DataStoreSelectors.User.selectUserById(state, id);

  if (!user) {
    user = getEmptyUser();
    user.id = id;
  }

  return user;
};

const Container = ({
  userId,
  workgroupName,
  automaticallyAdd,
  workgroupId,
  profileType,
  ...bladeProps
}: EditUserContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => getUser(state, userId));
  const userForm = useMemo(() => getUserForm(user), [user]);
  const isFetching = useMemo(() => !!userId && !user.login, [user]);
  const isSaving = useSelector(userSaving);
  const welcomeEmailTemplates = useSelector(getWelcomeEmailTemplates);

  const fetchUserAction: any = (userId: number) => dispatch<any>(fetchUser(userId));
  const fetchUserExtended: any = (userId: number) => dispatch<any>(fetchUserNewnityApps(userId, workgroupId));

  const saveUserAction = (user: UserDto, _: any, addToWorkgroup?: boolean) => {
    dispatch<any>(saveUser(user, workgroupId, bladeProps.bladeId, addToWorkgroup, profileType));
    dispatch<any>(userEditingFinished());
  };

  const fetchEmailTemplates = () => dispatch<any>(fetchWelcomeEmailTemplates());
  const closeBlade = () => {
    dispatch<any>(BladeActions.forceCloseBlade(bladeProps.bladeId));
    dispatch<any>(userEditingFinished());
  };

  return (
    <EditUser
      workgroupId={workgroupId}
      user={userForm}
      isFetching={isFetching}
      isSaving={isSaving}
      welcomeEmailTemplates={welcomeEmailTemplates}
      workgroupName={workgroupName}
      automaticallyAdd={automaticallyAdd}
      fetchEmailTemplates={fetchEmailTemplates}
      closeBlade={closeBlade}
      fetchUser={fetchUserExtended}
      saveUser={saveUserAction}
      {...bladeProps}
    />
  );
};

export const EditUserBladeType = 'EDIT_USER_BLADE';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 500 },
  bladeType: EditUserBladeType,
  id: (_: any) => EditUserBladeType,
  title: (ownProps: EditUserContainerProps) =>
    ownProps.userId > 0 ? `${ownProps.firstName} ${ownProps.lastName}` : 'Create User',
  allowMultipleInstances: false,
};

export const EditUserContainer = withRegisterBlade<EditUserContainerProps & BladeProps>(
  bladeConfig
)(Container);

import { createSelector } from 'reselect';

import { NewnityEntityType, NProgram } from '../types';
import { getDataStore } from './genericSelectors';

export const selectPrograms = (state: any) => getDataStore(state)[NewnityEntityType.NProgram];

export const selectProgramsNormalized = (state: any) =>
  getDataStore(state)[NewnityEntityType.NProgram].items;

export const selecProgramItems = createSelector(
  [selectPrograms],
  programs => {
    return Object.keys(programs.items).map<NProgram>(key => programs.items[Number(key)]);
  }
);

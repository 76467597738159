import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { NStation } from 'app/dataStore';
import { Channel, ChannelType } from 'app/dataStore/types';
import { FieldProps } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../device.jss';

export const useZonePlaylistSelect = (
  playlists: NStation[],
  presetStation: boolean,
  setPresetStation: (newValue: boolean) => void,
  titleTranslationKey: string,
  channel: Channel
) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return useCallback(
    ({ field, form }: FieldProps) => {
      const defaultStation = playlists.filter((x) => x.isDefault);

      if (presetStation && defaultStation.length && field.value !== defaultStation[0].id) {
        form.setFieldValue(field.name, defaultStation[0].id);
      }

      const shouldClearSelect =
        !playlists.find((e) => e.id === field.value) &&
        field.value &&
        field.value !== form.initialValues[field.name];
      if (shouldClearSelect) {
        form.setFieldValue(field.name, 0);
      }

      return (
        <FormControl fullWidth style={{ width: '100%' }} className={classes.selectMargin}>
          <InputLabel>{t<string>(titleTranslationKey)}</InputLabel>
          <Select
            {...field}
            disabled={(channel.channelType === ChannelType.OnHold) || (channel.id === 0)}
            value={field.value}
            renderValue={(value: any) => {
              const station = playlists.find((e) => e.id === value);
              if (station) {
                return <span>{station.name}</span>;
              }
              return <span>None</span>;
            }}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
              form.setFieldTouched(field.name, true);
              setPresetStation(false);
            }}
          >
            <MenuItem value={0} key={0}>
              {' '}
              None
            </MenuItem>
            {playlists.map((playlist) => (
              <MenuItem key={playlist.id} value={playlist.id}>
                {playlist.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    },
    [playlists, presetStation, channel]
  );
};

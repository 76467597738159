import classNames from 'classnames';
import {
  FieldArray,
  FieldArrayRenderProps,
  FastField,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  DataGridSelectionType,
  ColumnSettings,
  openBlade,
  SelectionListBladeType,
  SelectionBladeContainerProps,
} from 'react-tools';

import { Button, InputAdornment, Typography } from '@material-ui/core';
import { fetchTemplatesAsync } from '../../../duck/thunks';
import {
  Autocomplete,
  FieldButton,
  GroupBox,
  MaxLengthTextField as TextField,
} from '../../../../components';

import { CountryInfo, EntityType, NCompany, TemplateLibrary } from '../../../../dataStore';
import {
  getDataStoreCollection,
  getDataStoreCollectionStatus,
} from '../../../../dataStore/selectors/genericSelectors';
import { countries } from '../../../../dataStore/countries';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { CompanyInfoActions, CompanyInfoProps } from './company';
import { useStyles } from './company.jss';

interface CompanyDetailsFieldsProps {
  formikBag: FormikProps<NCompany>;
}

type Props = CompanyInfoProps & CompanyInfoActions & BladeProps & CompanyDetailsFieldsProps;

export const CompanyDetailsFields: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [templateSelectionBladeOpen, setTemplateSelectionBladeOpen] = useState(false);
  const workgroupTemplates = useSelector(getDataStoreCollection(EntityType.TemplateLibrary));

  const templateLibraryColumns: ColumnSettings[] = [
    {
      name: 'id',
      width: 120,
      filter: 'agTextColumnFilter',
    },
    {
      name: 'name',
      width: 320,
      lockVisibility: true,
      filter: 'agTextColumnFilter',
    },
  ];

  const openTemplateLibrarySelection = useCallback(() => {
    if (!templateSelectionBladeOpen) {
      dispatch(fetchTemplatesAsync());

      dispatch(
        openBlade<SelectionBladeContainerProps>(props.bladeId, SelectionListBladeType, {
          columns: templateLibraryColumns,
          identifier: 'templateLibraries',
          onClose: (items: TemplateLibrary[] | null) => {
            if (items !== null) {
              props.formikBag.setFieldValue('templateLibraries', items);
            }
            setTemplateSelectionBladeOpen(false);
          },
          selectionType: DataGridSelectionType.Multiple,
          title: t('templateLibraries.listTitle'),
          itemsSelector: getDataStoreCollection(EntityType.TemplateLibrary),
          selectedItems: props.formikBag.values.templateLibraries,
          isFetchingSelector: getDataStoreCollectionStatus(EntityType.TemplateLibrary),
          submitButtonLabel: t('templateLibraries.submitButtonLabel'),
        })
      );
      setTemplateSelectionBladeOpen(true);
    }
  }, [props.company.templateLibraries, workgroupTemplates]);

  return (
    <div className={classes.fieldsContainer}>
      <GroupBox title="Company">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="name"
              render={({ field, form }: FieldProps<NCompany>) => {
                return (
                  <TextField
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    autoFocus={true}
                    label={t<string>('newnity.company.name')}
                    helperText={helperText(form, field)}
                    error={validateEmptyField(form, field)}
                    {...field}
                    value={field.value || ''}
                    className={classes.formField}
                    inputProps={{ maxLength: 150 }}
                  />
                );
              }}
            />
            <FastField
              name="number"
              render={({ field, form }: FieldProps<NCompany>) => {
                return (
                  <TextField
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    label={t<string>('newnity.company.number')}
                    helperText={helperText(form, field)}
                    {...field}
                    error={validateEmptyField(form, field)}
                    value={field.value || ''}
                    className={classes.formField}
                    inputProps={{ maxLength: 50 }}
                  />
                );
              }}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <Field
              name="parentId"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  label={t<string>('newnity.company.parentWorkgroup')}
                  {...field}
                  error={validateEmptyField(form, field)}
                  value={field.value || 1}
                  className={classes.formField}
                  helperText={helperText(form, field)}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <FieldButton
                          title={t<string>('create.workgroup.select_btn_label')}
                          onClick={() => {}}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
      <GroupBox title="Template Libraries">
        <div className={classNames([classes.templateContainer, classes.marginRight])}>
          <FieldArray
            name="templateLibraries"
            render={(fieldProps: FieldArrayRenderProps) => {
              const templates = fieldProps.form.values.templateLibraries;
              return (
                <Typography variant="caption">
                  {templates && templates.length
                    ? `${templates.map((e: any) => e.name).join(', ')}`
                    : t('templateLibraries.empty')}
                </Typography>
              );
            }}
          />
          <Button variant="contained" color="secondary" onClick={openTemplateLibrarySelection}>
            {t('select')}
          </Button>
        </div>
      </GroupBox>
      <GroupBox title="Address">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="country"
              render={({ field, form }: FieldProps<NCompany>) => (
                <Autocomplete
                  items={countries}
                  selectedItem={form.values.country}
                  minCharInput={2}
                  textFieldProps={{
                    name: 'country',
                    label: t('newnity.company.address.country'),
                  }}
                  onChange={(selectedItem: CountryInfo | undefined) => {
                    form.setFieldValue(field.name, selectedItem ? selectedItem.name : '');
                  }}
                />
              )}
            />
            <FastField
              name="city"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.address.city')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
            <FastField
              name="state"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.address.state')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <FastField
              name="zipCode"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.address.zipCode')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="streetAddress"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  multiline
                  label={t<string>('newnity.company.address.streetAddress')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
      <GroupBox title="Contact">
        <div className={classes.horizontalContainer}>
          <div className={classNames([classes.vertical, classes.marginRight])}>
            <FastField
              name="firstName"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.contact.firstName')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="lastName"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.contact.lastName')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
          </div>
          <div className={classNames([classes.vertical, classes.marginLeft])}>
            <FastField
              name="phone"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.contact.phone')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <FastField
              name="email"
              render={({ field, form }: FieldProps<NCompany>) => (
                <TextField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label={t<string>('newnity.company.contact.email')}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
          </div>
        </div>
      </GroupBox>
    </div>
  );
};

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css';

import { SnackbarProvider } from 'notistack';
import React from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { createGenerateClassName, StylesProvider } from '@material-ui/styles';
import { bladeTheme, moodTheme } from './app.jss';
import { Layout } from './layout';

import { BladeRowContainer, NotifierContainer } from 'react-tools';
import { useSetEditableDeviceSettings } from './hooks';

const generateClassName = createGenerateClassName({
  productionPrefix: 'musicsetup',
  disableGlobal: true,
});

export const App = () => {
  useSetEditableDeviceSettings();

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodTheme}>
        <SnackbarProvider maxSnack={3}>
          <Layout>
            <NotifierContainer />
            <BladeRowContainer theme={bladeTheme} />
          </Layout>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

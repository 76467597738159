import { createSelector } from 'reselect';

import { NewnityEntityType, NCompany } from '../types';
import { getDataStore } from './genericSelectors';

export const selectCompanies = (state: any) =>
  getDataStore(state)[NewnityEntityType.NCompany];

export const selectCompanyItems = createSelector(
  [selectCompanies],
  companies => {
    return Object.keys(companies.items).map<NCompany>(
      key => companies.items[Number(key)]
    );
  }
);

export const selectCompanyItemsOrdered = createSelector(
  [selectCompanyItems],
  companies => {
    return companies.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const selectCompanyById = (state: any, locationId: number) => selectCompanies(state).items[locationId];

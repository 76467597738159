import { createSelector } from 'reselect';

import { MAD } from '../../../index';
import { DataStoreSelectors } from '../../dataStore';
import { NProgram } from '../../dataStore/types';
import {
  areaName,
  CurrentCompanyState,
  FetchState,
  NewnityState,
  ParentWorkgroupState,
} from './types';

export const selectNewnityState = (state: any): NewnityState => state[areaName] as NewnityState;
export const selectLoadingCompanyMode = (state: any): boolean =>
  selectNewnityState(state).loadingCompanyMode;
export const selectCurrentCompanyInfo = createSelector(
  [selectNewnityState],
  (state: NewnityState) => state.currentCompany
);

export const selectCompanyDataIsFetching = createSelector(
  [selectCurrentCompanyInfo],
  (state: CurrentCompanyState) => state.fetchingData
);

export const selectCompanySavingState = createSelector(
  [selectCurrentCompanyInfo],
  (state: CurrentCompanyState): FetchState => state.savingState
);

export const selectCompanyId = createSelector(
  [selectNewnityState],
  (state) => state.currentCompany.companyId
);
export const selectSavingLocationData = createSelector(
  [selectNewnityState],
  (state) => state.currentLocation.savingData
);
export const selectDeletingLocation = createSelector(
  [selectNewnityState],
  (state) => state.currentLocation.deletingLocation
);
export const selectIsEditingLocation = createSelector(
  [selectNewnityState],
  (state) => state.currentLocation.isEditing
);

// zones
export const selectLeftZonePlaylists = createSelector(
  [selectNewnityState],
  (state) => state.currentDevice.leftZonePlaylists
);

export const selectRightZonePlaylists = createSelector(
  [selectNewnityState],
  (state) => state.currentDevice.rightZonePlaylists
);

export const selectZonePlaylists = createSelector(
  [selectNewnityState],
  (state) => state.currentDevice.zonePlaylists
);

export const selectChannelFetchingData = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.fetchingChannelData
);
export const selectCurrentChannel = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.channel
);
export const selectCurrentChannelId = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.channel.id
);
export const selectZoneStations = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.stations
);
export const selectCurrentStation = createSelector(
  [selectNewnityState],
  (state) => state.currentStation.station
);
export const selectEditingSavingChannelData = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.editSavingState
);
export const selectCreatingSavingChannelData = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.createSavingState
);
export const selectIsEditingChannel = createSelector(
  [selectNewnityState],
  (state) => state.currentChannel.isEditing
);

export const selectSavingStationData = (state: any, stationId?: number) => {
  const currentStation = selectNewnityState(state).currentStation;
  return stationId ? currentStation.editSavingState : currentStation.createSavingState;
};
export const selectDeletingStation = createSelector(
  [selectNewnityState],
  (state) => state.currentStation.deletingStation
);
export const selectParentWorkgroup = createSelector(
  [selectNewnityState],
  (state: NewnityState) => state.parentWorkgroup
);

export const selectDefaultParentWorkgroupId = () => MAD.workgroupId;

export const selectParentWorkgroupIsFetching = createSelector(
  [selectParentWorkgroup],
  (state: ParentWorkgroupState) => state.fetchingData
);

export const selectCurrentDeviceLocationId = createSelector(
  [selectNewnityState],
  (state: NewnityState) => state.currentDevice.locationId
);
export const selectIsEditingDevice = createSelector(
  [selectNewnityState],
  (state: NewnityState) => state.currentDevice.isEditing
);
export const selectCurrentStationState = (state: any, stationId: number) => {
  const currentStation = selectNewnityState(state).currentStation;
  return stationId ? currentStation.editSchedule : currentStation.createSchedule;
};

export const selectCurrentStationSlots = createSelector(
  [selectCurrentStationState],
  (stationState) => stationState.scheduleSlots
);
export const selectCurrentStationDefaultSlot = createSelector(
  [selectCurrentStationState],
  (stationState) => stationState.defaultSlot
);

export const selectStationFetchingData = (state: any) =>
  selectNewnityState(state).currentStation.fetchingData;

export const selectIsEditingStation = createSelector(
  [selectNewnityState],
  (state) => state.currentStation.isEditing
);
export const selectStationSlotsAndAssets = createSelector(
  [
    selectCurrentStationSlots,
    selectCurrentStationDefaultSlot,
    DataStoreSelectors.NProgram.selectProgramsNormalized,
  ],
  (scheduleSlots, defaultSlot, programs) => {
    const assets = scheduleSlots.reduce<{ [slotLocalId: number]: NProgram | undefined }>(
      (obj, slot) => {
        obj[slot.localId] = slot.assetId ? (programs[slot.assetId] as NProgram) : undefined;
        return obj;
      },
      {}
    );
    if (defaultSlot) {
      assets[defaultSlot.localId] = defaultSlot.assetId
        ? (programs[defaultSlot.assetId] as NProgram)
        : undefined;
    }
    return { scheduleSlots, defaultSlot, assets };
  }
);
export const selectStationEdittingSlotId = (state: any) =>
  selectNewnityState(state).currentStation.scheduleEdittingSlotId;
export const selectImportCheckFetchState = (state: any) =>
  selectNewnityState(state).import.parseFileFetchState;
export const selectImportFetchState = (state: any) =>
  selectNewnityState(state).import.importFetchState;
export const selectImportParseResult = (state: any) =>
  selectNewnityState(state).import.parseFileResult;
export const selectImportResult = (state: any) => selectNewnityState(state).import.importResult;

import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@material-ui/core';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

import { FileUploadResult } from './types';
import { useStyles } from './uploader.jss';
import { UploadFileList } from './uploadFileList';

export interface UploaderProps {
  filesAreContent: boolean;
  showButtons: boolean;
  startUpload?: boolean;
  multiple?: boolean;
  accept?: string | string[];
  chunkSize?: number;
  onUploadComplete?: (files: FileUploadResult[]) => void;
}

export const Uploader = React.memo((props: UploaderProps) => {
  const [t] = useTranslation();
  const classes = useStyles({});
  const [fileList, setFileList] = useState<File[]>([]);
  const [startUpload, setStartUpload] = useState(props.startUpload ? true : false);
  const completedFiles = useRef<FileUploadResult[]>([]);

  const { onUploadComplete } = props;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFileList(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: props.multiple,
    accept: props.accept,
  });

  const onUpload = useCallback(() => {
    setStartUpload(true);
  }, []);

  const onFileComplete = useCallback(
    (result: FileUploadResult) => {
      completedFiles.current.push(result);
      if (completedFiles.current.length === fileList.length) {
        if (onUploadComplete) {
          onUploadComplete(completedFiles.current);
        }
      }
    },
    [completedFiles, fileList, onUploadComplete]
  );
  return (
    <div>
      <div {...getRootProps({ className: classes.dropZone })}>
        <input {...getInputProps()} />
        <CloudUploadOutlined className={classes.icon} />
        {isDragActive ? (
          <Typography align="center" variant="body1">
            Drop the files here ...
          </Typography>
        ) : (
          <Typography align="center" variant="body1">
            Drag 'n' drop some files here, or click to select files
          </Typography>
        )}
      </div>
      <UploadFileList
        files={fileList}
        filesAreContent={props.filesAreContent}
        chunkSize={props.chunkSize ? props.chunkSize : 64 * 1024}
        canUpload={startUpload}
        onFileComplete={onFileComplete}
      />
      {props.showButtons && (
        <div>
          <Button onClick={onUpload} color="primary">
            {t<string>('upload')}
          </Button>
        </div>
      )}
    </div>
  );
});

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '1px',
      overflowY: 'auto',
      height: 0,
      flex: '1 1 auto',

      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        cursor: 'pointer',
        background: '#D4CCC7',
      },

      '&::webkit-scrollbar-track': {
        background: '#9A9791',
        cursor: 'pointer',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#9A9791',
        borderRadius: '5px',
        cursor: 'pointer',

        '&:hover': {
          background: '#9A9791',
        },
      },
    },
    formContent: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    tabContent: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    selectMargin: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    detailsMargin: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    locationMenuItem: {
      padding: 0,
      lineHeight: 3,
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    locationSelect: {
      '& > div:first-child': {
        paddingLeft: '0 !important',
      },
    },
    locationNumber: {
      display: 'inline-block',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      paddingLeft: 0,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      width: '22%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      verticalAlign: 'bottom',
    },
    locationNumberWithPadding: {
      display: 'inline-block',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      width: '22%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      verticalAlign: 'bottom',
    },
    locationName: {
      display: 'inline-block',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      paddingRight: 0,
    },
    locationNameWithPadding: {
      display: 'inline-block',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    zonesContainer: {
      display: 'flex',

      '&>div': {
        flexGrow: 1,
        maxWidth: '50%',

        '&:first-child': {
          marginRight: theme.spacing(0.5),
        },
        '&:last-child': {
          marginLeft: theme.spacing(0.5),
        },
      },
    },
    channelName: {
      paddingRight: `${theme.spacing(2)}px`,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      width: '450px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      verticalAlign: 'bottom',
    },
    channelType: {
      maxWidth: '200px',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  });
};

export const useStyles = makeStyles(styles);

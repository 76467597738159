import { countryNameToCode, isStateFieldMandatory } from 'app/utils/address';
import { debounce } from 'debounce';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  Footer,
  FooterSubmit,
  useBladeButtons,
  useBladeClosing,
  useFetchDeviceSettings,
  EntityType,
  Entity,
} from 'react-tools';

import { NLocation, Workgroup } from '../../../../dataStore';
import { countries } from '../../../../dataStore/countries';
import { LocationDetails } from './locationDetails';
import { useStyles } from './locationEdit.jss';
import SettingIcon from '@material-ui/icons/Settings';

import FadeIn from 'react-fade-in';

export interface LocationEditProps {
  location: NLocation;
  companyId: number;
  isSaving: boolean;
  workgroup: Workgroup;
}

export interface LocationEditActions {
  configureWiFiSettings: () => void;
  createLocation: (companyId: number, location: NLocation) => void;
  fetchLocation: (id: number) => void;
  closeBlade: () => void;
  openDeviceList: (props: any) => void;
  openUserList: (props: any) => void;
  openDeviceSettings: () => void;
  fetchDevicesSettings: (entity: Entity, parentEntity: Entity | null) => void;
  clearDeviceSettings: () => void;
}

type Props = LocationEditProps & LocationEditActions & BladeProps;

export const LocationEdit: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const createMode = props.location.id === 0;

  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => props.closeBlade()
  );

  const fetchDevicesSettings = useCallback(() => {
    if (props.location && props.location.id) {
      props.fetchDevicesSettings(
        { entityId: props.location.id, entityType: EntityType.Site },
        { entityId: props.workgroup.id, entityType: EntityType.Workgroup }
      );
    }
  }, [props.location, props.fetchDevicesSettings]);

  useEffect(() => props.clearDeviceSettings, []);

  const buttons = useMemo(
    () => [
      {
        onClick: () => {
          props.openDeviceSettings();
          fetchDevicesSettings();
        },
        icon: () => <SettingIcon />,
        disabled: !props.location || !props.location.id,
        tooltip: t('deviceSettings.edit').toString(),
      },
    ],
    [props.openDeviceSettings, fetchDevicesSettings]
  );

  useBladeButtons(buttons, [buttons]);

  const openUserList = useCallback(
    (bladeProps: any) =>
      props.openUserList({
        ...bladeProps,
        title: t('newnity.edit.location.users.title'),
        workgroupName: props.location.name,
        hideCreateButton: true,
      }),
    [props.location]
  );

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(values: NLocation) => {
            let errors: FormikErrors<NLocation> = {};
            errors = checkRequiredField(errors, 'name', values.name, t);
            errors = checkRequiredField(errors, 'oracleNumber', values.oracleNumber, t);
            errors = checkRequiredField(errors, 'country', values.country, t);
            errors = checkRequiredField(errors, 'timezone', values.timezone, t);
            errors = checkRequiredField(errors, 'city', values.city, t);

            if (isStateFieldMandatory(countryNameToCode(countries, values.country))) {
              errors = checkRequiredField(errors, 'state', values.state, t);
            }

            return errors;
          }}
          initialValues={{ ...props.location }}
          onSubmit={(values: NLocation) => {
            props.setDirty(false);
            props.createLocation(props.companyId, values);
          }}
          render={({ submitForm }: FormikProps<NLocation>) => (
            <form
              onSubmit={(e) => e.preventDefault()}
              className={classes.form}
              onChange={props.isDirty ? undefined : debounce(() => props.setDirty(true), 250)}
            >
              <div className={classes.formContent}>
                <LocationDetails
                  openDeviceList={props.openDeviceList}
                  workgroupId={props.location.workgroupId}
                  locationId={props.location.id}
                  openUserList={openUserList}
                />
              </div>

              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t<string>('footer.cancel')}
                  cancel={props.closeBlade}
                  submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
                  submitInProgress={props.isSaving}
                  onSubmitPress={submitForm}
                />
              </Footer>
            </form>
          )}
        />
      </FadeIn>
    </div>
  );
};

const checkRequiredField = (
  errors: FormikErrors<NLocation>,
  name: keyof FormikErrors<NLocation>,
  value: string | undefined,
  t: TFunction
) => {
  if (!value) {
    errors[name] = t('newnity.company.field.error');
  }
  return errors;
};

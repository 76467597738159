import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IdName, User } from 'app/dataStore/types';
import { useTranslation } from 'react-i18next';
import { useStyles } from './addWorkgroupUsers.jss';
import { columnSettings } from './columnSettings';

import {
  BladeProps,
  createBtn,
  DataGrid,
  DataGridSelectionType,
  Footer,
  FooterSubmit,
  useBladeButtons,
  useBladeClosing,
} from 'react-tools';

export interface AddWorkgroupUsersProps {
  workgroupId: number;
  users: User[];
  workgroupUsers: User[];
  isLoading: boolean;
  isSaving: boolean;
  saved: boolean;
  hideCreateButton?: boolean;
}

export interface AddWorkgroupUsersActions {
  fetchUsers: () => void;
  save: (workgroupId: number, userIds: number[]) => void;
  setSaved: (value: boolean) => void;
  openNewUser: () => void;
  forceCloseBlade: () => void;
  openCreateUserBlade: () => void;
}

type Props = AddWorkgroupUsersProps & AddWorkgroupUsersActions & BladeProps;

export const AddWorkgroupUsers: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IdName[]>([]);
  const { fetchUsers, setDirty, setSaved } = props;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (props.saved) {
      setDirty(false);
      setSaved(false);
    }
  }, [props.saved, setDirty, setSaved]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  const handleSelection = useCallback(
    (newSelectedUsers: IdName[]) => {
      const users: User[] = (newSelectedUsers as User[]).filter(
        (user: User) => !(user as any)._default
      );

      setSelectedUsers(users);
      setBladeDirtyStatus(users);
    },
    [props.workgroupUsers, props.isDirty, setDirty]
  );

  const setBladeDirtyStatus = useCallback(
    (users: User[]) => {
      const isDirty =
        users.length !== props.workgroupUsers.length ||
        users.filter((u) => props.workgroupUsers.find((e) => e.id === u.id)).length !==
          props.workgroupUsers.length;

      if (!props.isDirty && isDirty) {
        setDirty(true);
      }

      if (props.isDirty && !isDirty) {
        setDirty(false);
      }
    },
    [setDirty, props.isDirty]
  );

  const buttons = useMemo(
    () => (props.hideCreateButton ? [] : [createBtn(props.openCreateUserBlade)]),
    [props.openCreateUserBlade, props.hideCreateButton]
  );

  const formSubmit = useCallback(
    () =>
      props.save(
        props.workgroupId,
        selectedUsers
          .filter((u) => !props.workgroupUsers.find((e) => e.id === u.id))
          .map((u) => u.id)
      ),
    [props.workgroupUsers, props.workgroupId, props.save, selectedUsers]
  );

  useBladeButtons(buttons);

  return (
    <div className={classes.container}>
      <DataGrid
        identifier={'workgroupUsers'}
        items={props.users}
        highlightNewlyAdded
        keepDefaultSelection={true}
        defaultSelection={props.workgroupUsers}
        onSelect={handleSelection}
        selectionType={DataGridSelectionType.Multiple}
        columnSettings={columnSettings}
        supressUnselectDefault
        rowClass={classes.alignedRow}
        externalFilterFn={undefined}
        isExternalFilterEnabled={undefined}
      />
      <Footer>
        <FooterSubmit
          hasCancelButton={true}
          cancelButtonLabel={t<string>('footer.cancel')}
          cancel={props.forceCloseBlade}
          submitButtonLabel={t<string>('footer.save')}
          submit={formSubmit}
          submitInProgress={props.isSaving}
        />
      </Footer>
    </div>
  );
};

import { WorkgroupProfileType } from 'app/newnity/duck/types';

import { User } from '../../dataStore';
import { apiFetch, currentWorkgroupKey, fetchHelper, getCookieValue } from '../../v6/ajax';
import { UserDto } from './types';

export const UserApi = {
  saveUser: async (userSaveObj: UserDto): Promise<User> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN +
      `v6/admin/users` +
      (userSaveObj.id > 0 ? `/${userSaveObj.id}` : ``),
      userSaveObj.id > 0 ? 'PUT' : 'POST',
      userSaveObj
    );

    if (!response.ok) {
      throw await response.text();
    }

    return response.json();
  },

  fetchUserWithApps: async (userId: number, workgroupId: number): Promise<User> => {
    
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/users/${userId}/${workgroupId}`);

    if (!response.ok) {
      throw await response.text();
    }

    return response.json();
  },

  fetchUser: async (userId: number): Promise<User> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/users/${userId}`);

    if (!response.ok) {
      throw await response.text();
    }

    return response.json();
  },

  getUsers: async (): Promise<User[]> => {
    const response = await apiFetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/users`);
    return response.json();
  },

  getWelcomeEmailTemplates: async () => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
      `v6/admin/users/templates`);
    if (!response.ok) {
      throw await response.text();
    }
    return response.json();
  },

  getUsersForWorkgroup: async (
    workgroupId: number = Number(getCookieValue(currentWorkgroupKey))
  ): Promise<User[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`
    );

    return response.json();
  },

  removeUsersFromWorkgroup: async (workgroupId: number, userIds: number[]): Promise<void> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`,
      'DELETE',
      { userIds }
    );
    return response.json();
  },

  addUsersToWorkgroup: async (workgroupId: number, userIds: number[], profileType?: WorkgroupProfileType): Promise<void> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/workgroup/${workgroupId}/users`,
      'PUT',
      { userIds, profileType: profileType ? profileType : WorkgroupProfileType.NewnityDefault }
    );
    return response.json();
  },
};

import { GroupBox } from 'app/components/groupBox/groupBox';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Checkbox, Divider, FormControlLabel, FormGroup, Typography
} from '@material-ui/core';

import { useStyles } from './daysForm.jss';
import { DaysAvailability } from './programSchedule';

interface DaysFormProps {
  days: DaysAvailability;
  onChange: (changes: { day: keyof DaysAvailability; checked: boolean }[]) => void;
}

interface DayFormItem {
  day: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DayFormItem = React.memo((props: DayFormItem) => {
  const [t] = useTranslation();
  return (
    <FormControlLabel
      control={<Checkbox checked={props.checked} name={props.day} onChange={props.onChange} />}
      label={t<string>(`newnity.${props.day}`)}
    />
  );
});

export const DaysForm = React.memo((props: DaysFormProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const atLeastOneDaySelected =
    props.days.friday ||
    props.days.monday ||
    props.days.saturday ||
    props.days.sunday ||
    props.days.thursday ||
    props.days.tuesday ||
    props.days.wednesday;

  const { onChange } = props;

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const day = e.target.name as keyof DaysAvailability;
      const checked = e.target.checked;
      onChange([{ day, checked }]);
    },
    [onChange]
  );
  const onWeekdays = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onChange([
        {
          day: 'monday',
          checked: true,
        },
        {
          day: 'tuesday',
          checked: true,
        },
        {
          day: 'wednesday',
          checked: true,
        },
        {
          day: 'thursday',
          checked: true,
        },
        {
          day: 'friday',
          checked: true,
        },
        {
          day: 'saturday',
          checked: false,
        },
        {
          day: 'sunday',
          checked: false,
        },
      ]);
    },
    [onChange]
  );
  const onWeekend = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onChange([
        {
          day: 'monday',
          checked: false,
        },
        {
          day: 'tuesday',
          checked: false,
        },
        {
          day: 'wednesday',
          checked: false,
        },
        {
          day: 'thursday',
          checked: false,
        },
        {
          day: 'friday',
          checked: false,
        },
        {
          day: 'saturday',
          checked: true,
        },
        {
          day: 'sunday',
          checked: true,
        },
      ]);
    },
    [onChange]
  );
  const atLeastOne = Object.keys(props.days).reduce<boolean>(
    (x, day) => x || props.days[day as keyof DaysAvailability],
    false
  );
  const all = Object.keys(props.days).reduce<boolean>(
    (x, day) => x && props.days[day as keyof DaysAvailability],
    true
  );
  const onAllChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const days = Object.keys(props.days).map(day => ({
      day: day as keyof DaysAvailability,
      checked,
    }));
    props.onChange(days);
  };
  return (
    <GroupBox title={t<string>('newnity.days')} noMargin>
      {!atLeastOneDaySelected && (
        <Typography variant="body2" color="error">
          {t<string>('newnity.schedule.noDaysError')}
        </Typography>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox indeterminate={atLeastOne && !all} onChange={onAllChange} checked={all} />
          }
          label={<Typography variant="button">All</Typography>}
        />
        <Button className={classes.weekdaysButton} variant="text" size="small" onClick={onWeekdays}>
          Weekdays
        </Button>
        <Button className={classes.weekendButton} variant="text" size="small" onClick={onWeekend}>
          Weekend
        </Button>
      </div>
      <Divider />
      <FormGroup>
        {Object.keys(props.days).map(day => (
          <DayFormItem
            key={day}
            checked={props.days[day as keyof DaysAvailability]}
            day={day}
            onChange={handleOnChange}
          />
        ))}
      </FormGroup>
    </GroupBox>
  );
});

import { setSchedule, stationSetEdittingSlot } from 'app/newnity/duck/actions';
import { NewnityState, Schedule, StationScheduleSlot } from 'app/newnity/duck/types';
import i18n from 'localization/i18n';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, withRegisterBlade } from 'react-tools';

import { ProgramSchedule, ProgramScheduleActions, ProgramScheduleProps } from './programSchedule';
import { mapPropsToSchedule, mapScheduleToProps } from './scheduleMapper';

interface ProgramScheduleContainerProps {
  stationId?: number;
  slotProgramName: string;
  localId: number;
}

const getDefaultSchedule = () => {
  const startDate = new Date();
  const endDate = new Date(startDate);
  endDate.setFullYear(endDate.getFullYear() + 1);

  const startTime = new Date(2019, 1, 1, 0, 0, 0);
  const endTime = new Date(2019, 1, 1, 23, 59, 59);
  return {
    id: 0,
    name: '',
    startDate,
    endDate,
    startTime,
    endTime,
    diffusionDays: 127,
    channelMediaRestrictions: [],
  };
};

const mapStateToProps: MapStateToProps<
  ProgramScheduleProps,
  ProgramScheduleContainerProps & BladeProps,
  any
> = (state, ownProps) => {
  let schedule: Schedule;
  let slot: StationScheduleSlot;

  if (ownProps.stationId) {
    slot = (state.newnity as NewnityState).currentStation.editSchedule.scheduleSlots.find(
      (s) => s.localId === ownProps.localId
    ) as StationScheduleSlot;
  } else {
    slot = (state.newnity as NewnityState).currentStation.createSchedule.scheduleSlots.find(
      (s) => s.localId === ownProps.localId
    ) as StationScheduleSlot;
  }

  if (slot && slot.schedule) {
    schedule = slot.schedule;
  } else {
    schedule = getDefaultSchedule();
  }

  const props = mapScheduleToProps(schedule);

  return props;
};

const mapDispatchToProps: MapDispatchToProps<
  ProgramScheduleActions,
  ProgramScheduleContainerProps & BladeProps
> = (dispatch: any, ownProps: ProgramScheduleContainerProps & BladeProps) => {
  return {
    setSchedule: (props) => {
      const newSchedule = mapPropsToSchedule(props);
      dispatch(stationSetEdittingSlot(ownProps.stationId ? ownProps.stationId : 0, -1));
      dispatch(
        setSchedule({
          stationId: ownProps.stationId,
          localId: ownProps.localId,
          schedule: newSchedule,
        })
      );
    },
    onClose: () => {
      dispatch(stationSetEdittingSlot(ownProps.stationId ? ownProps.stationId : 0, -1));
    },
    closeBlade: () => {
      dispatch(BladeActions.closeBlade(ownProps.bladeId));
    },
  };
};

export const ProgramScheduleBladeType = 'PROGRAM_SCHEDULE';

const bladeConfig = {
  size: { defaultWidth: 340, minWidth: 340 },
  bladeType: ProgramScheduleBladeType,
  id: (_: any) => ProgramScheduleBladeType,
  title: (_: any) => i18n.t('newnity.schedule.title'),
  allowMultipleInstances: false,
};

export const ProgramScheduleContainer = withRegisterBlade<
  ProgramScheduleContainerProps & BladeProps
>(bladeConfig)(connect(mapStateToProps, mapDispatchToProps)(ProgramSchedule));

import React, { useCallback, useMemo } from 'react';

import { selectCompanyById } from 'app/dataStore/selectors/newnityCompanies';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeActions,
  BladeProps,
  deviceSettingsActions,
  EntityType,
  openBlade,
  useDefaultDeviceSettingFns,
  withRegisterBlade,
} from 'react-tools';

import { DataStoreSelectors, NLocation } from '../../../../dataStore';
import { BladeTypes } from '../../../../users';
import { setSelectedDevices } from '../../../duck/actions';
import { selectCompanyId, selectSavingLocationData } from '../../../duck/selectors';
import { saveLocationData } from '../../../duck/thunks';
import { DeviceListBladeType } from '../../device/list/deviceListContainer';
import { LocationEdit } from './locationEdit';

export interface LocationEditContainerProps extends BladeProps {
  locationSiteId?: number;
  locationName?: string;
}

const getEmptyLocation = (): NLocation => ({
  address: '',
  city: '',
  clientSiteId: '',
  country: '',
  id: 0,
  name: '',
  phoneNumber: '',
  oracleNumber: '',
  state: '',
  timezone: '',
  workgroupId: 0,
  zipCode: '',
});

const getLocation = (state: any, id: number | undefined) => {
  if (!id) {
    return getEmptyLocation();
  }

  let location = DataStoreSelectors.NLocation.selectLocationById(state, id);

  if (location === undefined) {
    location = getEmptyLocation();
    location.id = id;
  }

  return location;
};

const Container = ({ locationName, locationSiteId, ...bladeProps }: LocationEditContainerProps) => {
  const dispatch = useDispatch();
  console.log(bladeProps.bladeId);

  const location = useSelector((state) => getLocation(state, locationSiteId));
  const companyId = useSelector(selectCompanyId);
  const isSaving = useSelector(selectSavingLocationData);
  const workgroup = useSelector((state) => selectCompanyById(state, companyId));

  const configureWiFiSettings = () => dispatch(openBlade(bladeProps.bladeId, '', {}));

  const createLocation = (companyId: number, location: NLocation) =>
    dispatch<any>(saveLocationData(companyId, location, bladeProps.bladeId));

  const fetchLocation: any = (id: string) => dispatch<any>(fetchLocation(id));

  const closeBlade = () => {
    dispatch(BladeActions.forceCloseBlade(bladeProps.bladeId));
    dispatch(deviceSettingsActions.clearDeviceSettings());
  };

  const clearDeviceSettings = () => {
    dispatch(deviceSettingsActions.clearDeviceSettings());
  };

  const openDeviceList = (props: any) =>
    dispatch(
      BladeActions.closeChildrenBlades(bladeProps.bladeId, [
        setSelectedDevices([]),
        openBlade(bladeProps.bladeId, DeviceListBladeType, props),
      ])
    );

  const openUserList = (props: any) =>
    dispatch(
      BladeActions.closeChildrenBlades(
        bladeProps.bladeId,
        openBlade(bladeProps.bladeId, BladeTypes.WorkgroupUserListListBladeType, props)
      )
    );

  const { fetchDevicesSettings, openDeviceSettings } = useDefaultDeviceSettingFns(
    bladeProps.bladeId,
    {
      entityId: workgroup ? workgroup.id : 0,
      entityType: EntityType.Workgroup,
    }
  );

  const openDeviceSettingsBlade = useCallback(
    () => dispatch(BladeActions.closeChildrenBlades(bladeProps.bladeId, openDeviceSettings)),
    [openDeviceSettings, bladeProps.bladeId]
  );

  return (
    <LocationEdit
      location={location}
      companyId={companyId}
      isSaving={isSaving}
      closeBlade={closeBlade}
      openDeviceList={openDeviceList}
      createLocation={createLocation}
      openUserList={openUserList}
      configureWiFiSettings={configureWiFiSettings}
      fetchLocation={fetchLocation}
      openDeviceSettings={openDeviceSettingsBlade}
      fetchDevicesSettings={fetchDevicesSettings}
      workgroup={workgroup}
      clearDeviceSettings={clearDeviceSettings}
      {...bladeProps}
    />
  );
};

export const LocationEditBladeType = 'NEWNITY_LOCATION_EDIT';

const bladeId = (ownProps: LocationEditContainerProps) =>
  ownProps.locationSiteId
    ? `${LocationEditBladeType}_${ownProps.locationSiteId}`
    : LocationEditBladeType;

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 800 },
  bladeType: LocationEditBladeType,
  id: bladeId,
  title: (ownProps: LocationEditContainerProps) =>
    ownProps.locationName
      ? ownProps.locationName
      : i18n.t('newnity.edit.location.blade.create.title'),
  allowMultipleInstances: false,
};

export const LocationEditContainer = withRegisterBlade<LocationEditContainerProps>(bladeConfig)(
  Container
);

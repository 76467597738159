import { DataStoreSelectors, EntityType } from 'app/dataStore';
import { WorkgroupProfileType } from 'app/newnity/duck/types';
import { UserSelectors } from 'app/users';
import { addUsersToWorkgroup, fetchUsers, workgroupAddUsersSetSaved } from 'app/users/duck/actions';
import i18n from 'localization/i18n';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, openBlade, withRegisterBlade } from 'react-tools';

import { EditUserBladeType } from '../createUser';
import {
  AddWorkgroupUsers,
  AddWorkgroupUsersActions,
  AddWorkgroupUsersProps,
} from './addWorkgroupUsers';

interface AddWorkgroupUsersContainerProps extends BladeProps {
  workgroupId: number;
  workgroupName: string;
  profileType?: WorkgroupProfileType;
  hideCreateButton?: boolean;
}

const mapStateToProps: MapStateToProps<
  AddWorkgroupUsersProps,
  AddWorkgroupUsersContainerProps & BladeProps,
  any
> = (state, ownProps) => {
  return {
    workgroupId: ownProps.workgroupId,
    users: DataStoreSelectors.User.selectUserItems(state),
    workgroupUsers: UserSelectors.selectWorkgroupUsers(state),
    isLoading: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.User).isFetching,
    isSaving: UserSelectors.addUserSaving(state),
    saved: UserSelectors.addUserSaved(state),
    hideCreateButton: ownProps.hideCreateButton,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  AddWorkgroupUsersActions,
  AddWorkgroupUsersContainerProps & BladeProps
> = (dispatch, ownProps) => {
  return {
    fetchUsers: () => dispatch<any>(fetchUsers()),
    save: (workgroupId, userIds) =>
      dispatch<any>(addUsersToWorkgroup(workgroupId, userIds, ownProps.profileType)),
    setSaved: (value) => dispatch(workgroupAddUsersSetSaved(value)),
    openNewUser: () => null,
    forceCloseBlade: () => dispatch(BladeActions.forceCloseBlade(ownProps.bladeId)),
    openCreateUserBlade: () =>
      dispatch<any>(
        openBlade(ownProps.bladeId, EditUserBladeType, {
          title: 'Create User',
          userId: 0,
          workgroupId: ownProps.workgroupId,
          workgroupName: ownProps.workgroupName,
        })
      ),
  };
};

export const AddWorkgroupUsersBladeType = 'ADD_WORKGROUP_USERS';

const bladeConfig = {
  size: { defaultWidth: 840, minWidth: 840 },
  bladeType: AddWorkgroupUsersBladeType,
  id: (_: any) => AddWorkgroupUsersBladeType,
  title: (_: any) => i18n.t('newnity.add.users.title'),
  allowMultipleInstances: false,
};

export const AddWorkgroupUsersContainer = withRegisterBlade<
  AddWorkgroupUsersContainerProps & BladeProps
>(bladeConfig)(connect(mapStateToProps, mapDispatchToProps)(AddWorkgroupUsers));

import Downshift, { ControllerStateAndHelpers } from 'downshift';
import React, { useCallback } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { useStyles } from './autocomplete.jss';

export interface AutocompleteTextFieldProps<T extends IHaveName> {
  items: T[];
  variant?: 'filled' | 'standard';
  minCharInput?: number;
  selectedItem?: string;
  // freeTextAllowed?: boolean;
  textFieldProps?: TextFieldProps;
  onChange?: (selectedItem: T) => void;
  compareMethod?: 'startsWith' | 'contains';
}

function renderInput(inputProps: any) {
  const { InputProps, classes, ref, variant, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      variant={variant}
      margin="normal"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const renderSuggestion = <T extends IHaveName>(
  suggestion: T,
  index: number,
  itemProps: any,
  highlightedIndex: number | null,
  selectedItem: T | null
) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem ? selectedItem.name : '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
};

export interface IHaveName {
  name: string;
  [key: string]: any;
}

export const Autocomplete = <T extends IHaveName>(props: AutocompleteTextFieldProps<T>) => {
  const classes = useStyles();
  const filterStrategy = props.compareMethod ? props.compareMethod : 'startsWith';
  const filterContains = useCallback(
    (suggestion: T, value: string) =>
      suggestion && suggestion.name.toLowerCase().indexOf(value) !== -1,
    []
  );
  const filterStartsWith = useCallback(
    (suggestion: T, value: string) => suggestion && suggestion.name.toLowerCase().startsWith(value),
    []
  );
  const filter = filterStrategy === 'contains' ? filterContains : filterStartsWith;
  const getSuggestions = useCallback(
    (inputValue: string | null, items: T[]) => {
      if (!inputValue) {
        return [];
      }
      const validInput = inputValue.length >= (props.minCharInput ? props.minCharInput : 0);
      return validInput ? items.filter((item) => filter(item, inputValue.toLowerCase())) : [];
    },
    [props.minCharInput, filter]
  );

  return (
    <Downshift
      selectedItem={
        props.items.find((item: T) => item && item.name === props.selectedItem) || {
          id: -1,
          name: '',
        }
      }
      itemToString={(item: T) => (item ? item.name : '')}
      onInputValueChange={(inputValue: string, stateAndHelpers: ControllerStateAndHelpers<T>) => {
        if (
          inputValue === '' &&
          props.onChange &&
          stateAndHelpers.selectedItem &&
          stateAndHelpers.selectedItem.name !== ''
        ) {
          stateAndHelpers.clearSelection();
          props.onChange(stateAndHelpers.selectedItem);
        }
      }}
      onChange={(selectedItem, state) => {
        if (props.onChange) {
          props.onChange(selectedItem);
        }
      }}
    >
      {(dprops: ControllerStateAndHelpers<T>) => (
        <div className={classes.container}>
          {renderInput({
            variant: props.variant ? props.variant : 'filled',
            classes,
            InputProps: dprops.getInputProps({
              autoComplete: 'new-password',
            }),
            ...props.textFieldProps,
          })}
          <div {...dprops.getMenuProps()}>
            {dprops.isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(dprops.inputValue, props.items).map((suggestion, index) =>
                  renderSuggestion(
                    suggestion,
                    index,
                    dprops.getItemProps({ item: suggestion }),
                    dprops.highlightedIndex,
                    dprops.selectedItem
                  )
                )}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
};

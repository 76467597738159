import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '1px',
      overflowY: 'auto',
      height: 0,
      flex: '1 1 auto',

      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        cursor: 'pointer',
        background: '#D4CCC7',
      },

      '&::webkit-scrollbar-track': {
        background: '#9A9791',
        cursor: 'pointer',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#9A9791',
        borderRadius: '5px',
        cursor: 'pointer',

        '&:hover': {
          background: '#9A9791',
        },
      },
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    formContent: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    field: {
      marginBottom: theme.spacing(2),
    },
    locationNumber: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      width: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      verticalAlign: 'bottom',
    },
    locationName: {
      maxWidth: '800px',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  });

export const useStyles = makeStyles(styles);

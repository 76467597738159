import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    loadingContainer: {
      flexGrow: 1,
      display: 'flex',
    },
    loading: {
      margin: 'auto',
    },
    alignedRow: {
      '& > div[role="gridcell"]': {
        lineHeight: '36px',
      },
    },
    centerContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        alignSelf: 'center',
      },
    },
  });

export const useStyles = makeStyles(styles);

import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { BladeProps, Footer, FooterSubmit, useBladeClosing } from 'react-tools';

import { TextField } from '@material-ui/core';

import { GroupBox } from '../../../../components/groupBox/groupBox';
import { OnHold } from '../../../../dataStore';
import { ChannelType, DataItemsFetchStatus } from '../../../../dataStore/types';
import { FetchState } from '../../../duck/types';
import { useStyles } from './onHoldEdit.jss';

export interface OnHoldEditProps {
  companyId: number;
  companyName: string;
  onHold: OnHold;
  savingData: FetchState;
  fetchingData: FetchState;
  fetchingLocations: DataItemsFetchStatus;
  editBladeId?: string;
  stationEditBladeId?: string;
}

export interface OnHoldEditActions {
  createOnHold: (onHold: OnHold) => void;
  fetchOnHold: (onHoldId: number) => void;
  closeBlade: () => void;
}

type Props = OnHoldEditProps & OnHoldEditActions & BladeProps;

export const OnHoldEdit: React.FunctionComponent<Props> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [onHoldName, setOnHoldName] = useState(props.onHold.name);
  const createMode = props.onHold.id === 0;
  const [submitPressed, setSubmitPressed] = useState(false);
  const { onHold, fetchOnHold, setDirty, savingData } = props;

  // load onHold data when blade opens
  useEffect(() => {
    if (props.onHold.id) {
      fetchOnHold(props.onHold.id);
    }
  }, [props.onHold.id]);

  useBladeClosing(props.bladeId, () => !props.isDirty);

  useEffect(() => {
    if (savingData.fetchCompleted && savingData.fetchError === '') {
      setDirty(false);
    }
  }, [savingData.fetchError, savingData.fetchCompleted]);

  useEffect(() => {
    if (
      props.fetchingData.isFetching ||
      props.savingData.isFetching ||
      props.savingData.fetchCompleted
    ) {
      return;
    }

    const nextDirty = !isEqual(
      {
        onHoldName: onHoldName,
      },
      {
        onHoldName: props.onHold.name,
      }
    );

    if (nextDirty !== props.isDirty) {
      setDirty(nextDirty);
    }
  }, [
    onHoldName,
    props.onHold.name,
    props.onHold.channelType,
    props.isDirty,
    props.fetchingData.isFetching,
    props.savingData.isFetching,
    props.savingData.fetchCompleted,
    setDirty,
  ]);

  const canSaveOnHold = useCallback(() => onHoldName, [
    onHoldName,
  ]);

  const onSubmit = useCallback(() => {
    if (canSaveOnHold()) {
      props.createOnHold({
        ...onHold,
        name: onHoldName,
        workgroupId: props.companyId,
        workgroupName: props.companyName,
        channelType: ChannelType.OnHold,
      });
    } else {
      setSubmitPressed(true);
    }
  },[
    onHold,
    onHoldName,
  ]);

  const onOnHoldNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitPressed(false);
    setOnHoldName(e.currentTarget.value);
  }, []);

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <div className={classes.form}>
          <div className={classes.formContent}>
            <GroupBox title={t('newnity.edit.onHold.blade.create.header')} direction="column">
              <TextField
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                }}
                label={t('newnity.edit.onHold.blade.create.name')}
                value={onHoldName}
                onChange={onOnHoldNameChange}
                margin="normal"
                className={classes.field}
                error={submitPressed && !onHoldName}
                helperText={
                  submitPressed && !onHoldName && t('newnity.edit.onHold.blade.create.nameError')
                }
                inputProps={{ maxLength: 190 }}
              />
            </GroupBox>
          </div>
          <Footer>
            <FooterSubmit
              hasCancelButton={true}
              cancelButtonLabel={t('footer.cancel')}
              cancel={props.closeBlade}
              submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
              submit={onSubmit}
              submitInProgress={props.savingData.isFetching}
            />
          </Footer>
        </div>
      </FadeIn>
    </div>
  );
};

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useDeviceOutputConfirm = (
  messageTranslationKey: string,
  titleTranslationKey: string,
  open: boolean,
  onCancel: () => void,
) => {
  const [t] = useTranslation();

  return useCallback(
    ({ onContinue }) => {
      return (
        <Dialog open={open}>
          <DialogTitle>{t(titleTranslationKey)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t(messageTranslationKey)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary" autoFocus>
              {t('cancel')}
            </Button>
            <Button onClick={onContinue} color="primary">
              {t('continue')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    [open]
  );
};

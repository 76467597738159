import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { EntityType } from '../../../dataStore';
import { useStyles } from './search.jss';

export interface EntitySwitchProps {
  value: EntityType;
  onChange(value: EntityType): void;
}
export const EntitySwitch = React.memo((props: EntitySwitchProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const handleChangeEvent = useCallback((_, newValue) => props.onChange(newValue as EntityType), [
    props.onChange,
  ]);

  return (
    <RadioGroup
      value={props.value.toString()}
      onChange={handleChangeEvent}
      row={true}
      classes={{ root: classes.entitySwitch }}
    >
      <FormControlLabel
        value={EntityType.NCompanySearchResult}
        control={<Radio />}
        label={t<string>('newnity.company')}
      />
      <FormControlLabel
        value={EntityType.NLocationSearchResult}
        control={<Radio />}
        label={t<string>('newnity.location')}
      />
      <FormControlLabel
        value={EntityType.NDeviceSearchResult}
        control={<Radio />}
        label={t<string>('newnity.device')}
      />
    </RadioGroup>
  );
});

import { User } from '../../dataStore';
import { EntityState } from '../../dataStore/types';

export const areaName = 'users';

export interface UsersState {
  currentWorkgroupUsers: EntityState<User>;
  removeUser: {
    isRemoving: boolean;
    removingError: string;
    removingComplete: boolean;
  };
  addUser: {
    isSaving: boolean;
    savingError: string;
    savingComplete: boolean;
  };
  saveUser: {
    isSaving: boolean;
    savingError: string;
    savingComplete: boolean;
  };
  fetchUser: {
    isFetching: boolean;
    fetchError: string;
    fetchComplete: boolean;
  };
  editUser: {
    isEditing: boolean;
  };  
  fetchWelcomeEmailTemplates: {
    isFetching: boolean;
    fetchError: string;
    fetchComplete: boolean;
  }
  welcomeEmailTemplates: {
    welcomeEmailTemplatesList: string[]
  };
}

export const getEmptyUser = (): User => {
  return {
    id: 0,
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    login: '',
    password: '',
    accountExpDate: '',
    passwordExpDate: '',
    lastWelcomeEmailDate: '',
    accessRights: '0',
    extendedApps: 0
  };
};

export const getUserForm = (user: User = getEmptyUser()): UserForm => {
  return {
    ...user,
    passwordVerify: user.password,
    changePassword: false,
    changeLogin: false,
    sendWelcomeEmail: user.id ? false : true,
    welcomeEmailTemplateName: ''
  };
};

export interface UserDto extends User {
  changePassword: boolean;
  changeLogin: boolean;
  sendWelcomeEmail: boolean;
  welcomeEmailTemplateName : string;
  isLocked?: number;
}

export interface UserForm extends UserDto {
  passwordVerify: string;
}


export enum ActionTypes {
  USER_CREATE_REQUEST = 'USER_CREATE_REQUEST',
  USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS',
  USER_CREATE_ERROR = 'USER_CREATE_ERROR',
  USER_FETCH_REQUEST = 'USER_FETCH_REQUEST',
  USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS',
  USER_FETCH_ERROR = 'USER_FETCH_ERROR',
  WORKGROUP_USER_LIST_REQUEST = 'WORKGROUP_USER_LIST_REQUEST',
  WORKGROUP_USER_LIST_ERROR = 'WORKGROUP_USER_LIST_ERROR',
  WORKGROUP_USER_LIST_SUCCESS = 'WORKGROUP_USER_LIST_SUCCESS',
  WORKGROUP_REMOVE_USERS_REQUEST = 'WORKGROUP_REMOVE_USERS_REQUEST',
  WORKGROUP_REMOVE_USERS_ERROR = 'WORKGROUP_REMOVE_USERS_ERROR',
  WORKGROUP_REMOVE_USERS_SUCCESS = 'WORKGROUP_REMOVE_USERS_SUCCESS',
  USER_SAVE_REQUEST = 'USER_SAVE_REQUEST',
  USER_SAVE_ERROR = 'USER_SAVE_ERROR',
  USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS',
  USER_LOGIN_UNIQUE_CHECK = 'USER_LOGIN_UNIQUE_CHECK',
  WORKGROUP_ADD_USERS_REQUEST = 'WORKGROUP_ADD_USERS_REQUEST',
  WORKGROUP_ADD_USERS_ERROR = 'WORKGROUP_ADD_USERS_ERROR',
  WORKGROUP_ADD_USERS_SUCCESS = 'WORKGROUP_ADD_USERS_SUCCESS',
  WORKGROUP_ADD_USERS_SET_SAVED = 'WORKGROUP_ADD_USERS_SET_SAVED',
  USER_EDIT_REQUEST = 'USER_EDIT_REQUEST',
  USER_EDIT_COMPLETED = 'USER_EDIT_COMPLETED',  
  WELCOME_EMAIL_TEMPLATES_FETCH_REQUESTED = 'WELCOME_EMAIL_TEMPLATES_FETCH_REQUESTED',
  WELCOME_EMAIL_TEMPLATES_FETCH_SUCCESS = 'WELCOME_EMAIL_TEMPLATES_FETCH_SUCCESS',
  WELCOME_EMAIL_TEMPLATES_FETCH_FAILED = 'WELCOME_EMAIL_TEMPLATES_FETCH_FAILED'
}

import { connect, MapDispatchToProps } from 'react-redux';
import { openBlade } from 'react-tools';
import { Dispatch } from 'redux';

import { MAD } from '../../..';
import { AdminAppToolbar, AdminAppToolbarActions } from './adminAppToolbar';

const mapDispatchToProps: MapDispatchToProps<AdminAppToolbarActions, any> = (
  dispatch: Dispatch
) => ({
  openBlade: (type: string, props: object) => dispatch(openBlade('', type, props)),
  exitApp: () => MAD.homeFn(),
});

export const AdminAppToolbarContainer = connect(null, mapDispatchToProps)(AdminAppToolbar);

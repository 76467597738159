import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const styles = (theme: Theme) => {
  return createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '1px',
      overflowY: 'auto',
      height: 0,
      flex: '1 1 auto',

      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        cursor: 'pointer',
        background: '#D4CCC7',
      },

      '&::webkit-scrollbar-track': {
        background: '#9A9791',
        cursor: 'pointer',
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#9A9791',
        borderRadius: '5px',
        cursor: 'pointer',

        '&:hover': {
          background: '#9A9791',
        },
      },
    },
    formContent: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
    },
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center',
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '50%',
    },
    fadeIn: {
      height: '100%',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    verticalContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    horizontalContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    },
    templateContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& > span": {
        fontStyle: "italic"
      },
      "& > button": {
        alignSelf: "flex-end"
      }
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formButton: {
      width: 250,
      justifyContent: 'left',
    },
    iconLeft: {
      marginRight: theme.spacing(2),
    },
  });
};

export const useStyles = makeStyles(styles);

import { ImportResult, ImportSkippedRow } from 'app/dataStore/types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { ColumnSettings } from 'react-tools';

export interface ImportSummaryRow {
  entityName: string;
  detailsBladeTitle: string;
  columnSettings?: ColumnSettings[];
  data: ImportResult;
}

export interface ImportSummaryProps {
  rows: ImportSummaryRow[];
}

export interface ImportSummaryActions {
  showDetails: (title: string, data: ImportSkippedRow[]) => void;
}

export const ImportSummary: React.FunctionComponent<ImportSummaryProps &
  ImportSummaryActions> = React.memo(props => {
  const [t] = useTranslation();

  const { showDetails } = props;

  const openSkippedDetailsBlade = useCallback(
    (data: ImportSkippedRow[]) => (e: React.MouseEvent<HTMLButtonElement>) => {
      const entityName = e.currentTarget.name;
      const row = props.rows.find(r => r.entityName === entityName);
      if (row) {
        showDetails(row.detailsBladeTitle, data);
      }
    },
    [showDetails, props.rows]
  );
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t<string>('newnity.import.summary.entity')}</TableCell>
          <TableCell align="left">{t<string>('newnity.import.summary.total')}</TableCell>
          <TableCell align="left">{t<string>('newnity.import.summary.warnings')}</TableCell>
          <TableCell align="left">{t<string>('newnity.import.summary.errors')}</TableCell>
          <TableCell align="left">{t<string>('newnity.import.summary.skipped')}</TableCell>
          <TableCell align="left">{t<string>('newnity.import.summary.completed')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map(r => (
          <TableRow key={r.entityName}>
            <TableCell component="th" scope="row">
              {r.entityName}
            </TableCell>
            <TableCell align="left">{r.data.total}</TableCell>
            <TableCell align="left">{r.data.warnings.length}
              {r.data.warnings.length > 0 && (
                <IconButton name={r.entityName} size="small" onClick={openSkippedDetailsBlade(r.data.warnings)}>
                  <KeyboardArrowRight />
                </IconButton>
              )}
            </TableCell>
            <TableCell align="left">{r.data.errors.length}
              {r.data.errors.length > 0 && (
                <IconButton name={r.entityName} size="small" onClick={openSkippedDetailsBlade(r.data.errors)}>
                  <KeyboardArrowRight />
                </IconButton>
              )}
            </TableCell>
            <TableCell align="left">{r.data.skippedRowCount}</TableCell>
            <TableCell align="left">{r.data.completed}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Home from '@material-ui/icons/Home';

import { useStyles } from './toolbar.jss';

export interface AdminAppToolbarActions {
  openBlade: (bladeType: string, props: object) => void;
  exitApp: () => void;
}

export const AdminAppToolbar = (props: AdminAppToolbarActions) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        <ListItem className={classes.list} button key={'home'} onClick={() => props.exitApp()}>
          <ListItemIcon>
            <Home className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={t<string>('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}></List>
    </React.Fragment>
  );
};

import { NDevice, NStation } from 'app/dataStore';
import { DeviceMode, StreamAudioOutput } from 'app/newnity/duck/types';
import { useCallback } from 'react';

export const useSubmitForm = (
  setAudioOutputClashing: (value: React.SetStateAction<boolean>) => void,
  zonePlaylists: NStation[],
  leftZonePlaylists: NStation[],
  rightZonePlaylists: NStation[]
) => {
  return useCallback(
    (values: NDevice, submitForm: () => void) => {
      return () => {
        if (
          values.deviceMode === DeviceMode.SingleZone &&
          values.stationId &&
          zonePlaylists.length === 0
        ) {
          values.stationId = 0;
        }

        if (
          values.deviceMode === DeviceMode.DualZone &&
          values.leftZone &&
          leftZonePlaylists.length === 0
        ) {
          values.leftZonePlaylist = 0;
        }

        if (
          values.deviceMode === DeviceMode.DualZone &&
          values.rightZone &&
          rightZonePlaylists.length === 0
        ) {
          values.rightZonePlaylist = 0;
        }

        if (
          values.visualChannelId &&
          (values.leftZone || values.rightZone || values.channelId) &&
          values.audioOutputForVisuals === StreamAudioOutput.AllZones
        ) {
          setAudioOutputClashing(true);
          return;
        }

        if (
          values.visualChannelId &&
          (values.leftZone || values.channelId) &&
          values.audioOutputForVisuals === StreamAudioOutput.LeftZone
        ) {
          setAudioOutputClashing(true);
          return;
        }

        if (
          values.visualChannelId &&
          (values.rightZone || values.channelId) &&
          values.audioOutputForVisuals === StreamAudioOutput.RightZone
        ) {
          setAudioOutputClashing(true);
          return;
        }

        return submitForm();
      };
    },
    [leftZonePlaylists, rightZonePlaylists, zonePlaylists]
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEventHandler } from 'react-draggable';
import { useTranslation } from 'react-i18next';

import { Formik, FormikProps } from 'formik';
import { EntityType, IdName } from '../../../dataStore';
import { emptySearchFields } from '../../duck/reducer';
import { SearchFields } from '../../duck/types';
import { EntitySwitch } from './entitySwitch';
import { SearchResultsContainer } from './results';
import { useStyles } from './search.jss';
import { SearchTabs } from './tabs';

import { BladeProps, Footer, FooterSubmit } from 'react-tools';

export interface SearchActions {
  setFields: (fields: SearchFields) => void;
  setCurrentEntity: (entity: EntityType) => void;
  setTabIndex: (index: number) => void;
  search: (fields: SearchFields, entity: EntityType) => void;
}

export interface SearchProps {
  currentEntity: EntityType;
  currentFields: SearchFields;
  searchedEntity?: EntityType;
  searchedFields?: SearchFields;
  tabIndex: number;
  companies: IdName[];
}

const MIN_WIDTH = 100;

export const Search = (props: SearchProps & SearchActions & BladeProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [fieldsWidth, setFieldsWidth] = useState<string | number>('auto');
  const [initialFields, setInitialFields] = useState<SearchFields>(
    props.searchedFields || props.currentFields
  );

  const fieldsRef = useRef<HTMLDivElement>(null);

  const { search, setCurrentEntity, setTabIndex } = props;

  useEffect(() => {
    if (fieldsRef && fieldsRef.current) {
      setFieldsWidth(fieldsRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => setInitialFields(props.currentFields), [props.currentFields]);

  useEffect(() => {
    if (props.searchedEntity) {
      search(props.searchedFields as SearchFields, props.searchedEntity);
    }
  }, [search, props.searchedEntity, props.searchedFields]);

  useEffect(() => {
    const resultsWidth = props.width - Number(fieldsWidth);

    if (resultsWidth < MIN_WIDTH) {
      setFieldsWidth(props.width - MIN_WIDTH);
    }
  }, [props.width, fieldsWidth]);

  const onEntityChange = useCallback(
    (entity: EntityType) => {
      setCurrentEntity(entity);

      if (entity !== EntityType.NCompanySearchResult && props.tabIndex === 2) {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    },
    [props.tabIndex, setTabIndex, setCurrentEntity]
  );

  const handleFormSubmit = useCallback(
    (values: SearchFields) => props.search(values, props.currentEntity),
    [props.currentEntity]
  );

  const calculateNewFieldsWidth = useCallback(
    (dragEndXPosition: number) => {
      const newWidth = Number(fieldsWidth) + dragEndXPosition;

      if (newWidth < MIN_WIDTH) {
        return MIN_WIDTH;
      }

      if (newWidth > props.width - MIN_WIDTH) {
        return props.width - MIN_WIDTH;
      }

      return newWidth;
    },
    [props.width, fieldsWidth]
  );

  const resizeDragEndedEvent: DraggableEventHandler = useCallback(
    (e: any, data: DraggableData) => setFieldsWidth(calculateNewFieldsWidth(data.x)),
    [calculateNewFieldsWidth]
  );

  const handleCancelEvent = useCallback(
    (resetForm: any) => () => {
      props.setFields(emptySearchFields);
      props.search(emptySearchFields, props.currentEntity);
      resetForm();
    },
    [props.currentEntity, props.setFields, props.search]
  );

  return (
    <div className={classes.container}>
      <div className={classes.resizableSearch} style={{ flexBasis: fieldsWidth }} ref={fieldsRef}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialFields}
          enableReinitialize
          validateOnChange={false}
          render={({ values, handleChange, submitForm, resetForm }: FormikProps<SearchFields>) => (
            <form onSubmit={(e) => e.preventDefault()} className={classes.searchContainer}>
              <EntitySwitch value={props.currentEntity} onChange={onEntityChange} />

              <SearchTabs
                currentEntityType={props.currentEntity}
                fields={values}
                handleChange={handleChange}
                tabIndex={props.tabIndex}
                onTabChange={props.setTabIndex}
                companies={props.companies}
              />

              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t<string>('footer.clear')}
                  cancel={handleCancelEvent(resetForm)}
                  submitButtonLabel={t<string>('newnity.search')}
                  submit={() => submitForm()}
                  submitInProgress={false}
                />
              </Footer>
            </form>
          )}
        />
      </div>
      <Draggable
        position={{ x: 0, y: 0 }}
        axis="x"
        onStop={resizeDragEndedEvent}
        bounds={'parent'}
        defaultClassName={classes.dragHandle}
        defaultClassNameDragging={classes.dragHandleActive}
      >
        <div className={classes.noSelect}></div>
      </Draggable>
      <div className={classes.resizableResults}>
        <SearchResultsContainer />
      </div>
    </div>
  );
};
